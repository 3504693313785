import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'contexts/authContext';

import {
  getDelegatesAvailable,
  getDelegatesCurrent,
  putDelegatesCurrent,
} from 'utils/api/delegates';

const DelegatedAccessContext = React.createContext(null);

export const useDelegatedAccess = () => useContext(DelegatedAccessContext);

export const DelegatedAccessProvider = ({ children }) => {
  const [currentDelegates, setCurrentDelegates] = useState(null);
  const [availableDelegates, setAvailableDelegates] = useState(null);
  const [allDelegates, setAllDelegates] = useState(null);

  const [listIsLoading, setListIsLoading] = useState(true);
  const [availIsLoading, setAvailIsLoading] = useState(true);

  const [reloadList, setReloadList] = useState(false);

  const { accessToken, permissions, organizationId } = useContext(AuthContext);

  const store = {
    currentDelegates,
    allDelegates,
    setAllDelegates,
    availableDelegates,
    setAvailableDelegates,
    listIsLoading,
    availIsLoading,
    reloadList,
    setReloadList,
    putDelegatesCurrent,
    accessToken,
    permissions,
    organizationId,
  };

  // Add extra fields to the user object for later use and ease of usage.
  const populateFields = user => {
    return {
      ...user,
      id: user.userId,
      name: `${user.firstName} ${user.lastName}`,
      fullName: `${user.firstName} ${user.lastName}`,
      value: `${user.firstName} ${user.lastName}`,
    };
  };

  // Store the delegates that are available for delegating
  useEffect(() => {
    const selectUnchosenUsers = async (currentUsers, allUsers) => {
      const currentUserIds = currentUsers.map(user => {
        return user.id;
      });

      const availableUsers = allUsers.filter(user => {
        return !currentUserIds.includes(user.id);
      });

      setAvailableDelegates(availableUsers);
    };

    if (allDelegates && currentDelegates) {
      selectUnchosenUsers(currentDelegates, allDelegates);
    }
  }, [allDelegates, currentDelegates]);

  // Retrieve currently delegated users
  useEffect(() => {
    async function getCurrentDelegates() {
      setListIsLoading(true);

      try {
        const { response, data, error } = await getDelegatesCurrent(
          accessToken,
        );
        if (response.ok) {
          setCurrentDelegates(
            data
              .map(delegate => {
                return populateFields(delegate);
              })
              .sort((a, b) => a.lastName.localeCompare(b.lastName)),
          );
          setListIsLoading(false);
          setReloadList(false);
        } else {
          setListIsLoading(false);
          setReloadList(false);
          throw new Error(`${response.status} - ${error.message}`);
        }
      } catch (error) {
        // TODO Render error to user
        // console.error(`Unable to get current delegates:`);
        // console.error(error);
        setListIsLoading(false);
        setReloadList(false);
      }
    }

    if (accessToken) {
      getCurrentDelegates();
    }
  }, [accessToken, reloadList]);

  // Retrieve all available delegates
  useEffect(() => {
    async function getAllDelegates() {
      setAvailIsLoading(true);
      try {
        const { response, data, error } = await getDelegatesAvailable(
          accessToken,
        );
        if (response.ok) {
          setAllDelegates(
            data
              .map(user => {
                return populateFields(user);
              })
              .sort((a, b) => a.lastName.localeCompare(b.lastName)),
          );
          setAvailIsLoading(false);
        } else {
          setAvailIsLoading(false);
          throw new Error(`${response.status} - ${error.message}`);
        }
      } catch (error) {
        // TODO Render error to user
        // console.error(`Unable to get available delegates:`);
        // console.error(error);
        setAvailIsLoading(false);
      }
    }

    if (accessToken) {
      getAllDelegates();
    }
  }, [accessToken]);

  return (
    <DelegatedAccessContext.Provider value={store}>
      {children}
    </DelegatedAccessContext.Provider>
  );
};

export const DelegatedAccessConsumer = DelegatedAccessContext.Consumer;
