/* BANNER and SIDEBAR */
export const HEALTHWISE = 'My Healthwise';

export const HEALTHWISE_ORG = 'Healthwise';

/* ACCOUNT OVERVIEW TEXT */
export const ACCOUNT_PROFILE_INFORMATION_TITLE =
  'Account and Profile Information';
export const ACCOUNT_PROFILE_INFORMATION_BUTTON_TEXT = 'Edit Profile';
export const ADMIN_SETTINGS = 'Administration & Settings';
export const USER_MANAGEMENT_SECTION_TITLE = 'Manage Users';
export const ACCOUNT_MANAGEMENT_SECTION_TITLE = 'Account Management';
export const ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_TITLE =
  'Organization Dashboard';
export const ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_DESCRIPTION =
  'Edit organizations, subscriptions, and administrators.';
export const ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_BUTTON_TEXT =
  'Manage Organizations';

export const APPLICATION_MANAGEMENT_SECTION_TITLE = 'Applications';
export const APPLICATION_MANAGEMENT_SECTION_DESCRIPTION =
  'Create, edit, and remove applications.';

export const ORGANIZATION_OVERVIEW_CARD_TITLE = 'Organization Overview';
export const ORGANIZATION_OVERVIEW_CARD_DESCRIPTION =
  'View your organization’s administrators and subscriptions.';
export const DELEGATED_ACCESS_CARD_TITLE = 'Delegate Access';
export const DELEGATED_ACCESS_CARD_DESCRIPTION =
  "Provide a Healthwise Employee access to your organization's application(s).";
export const USER_MANAGEMENT_SECTION_USERS_TITLE = 'Users';
export const USER_MANAGEMENT_SECTION_USERS_DESCRIPTION =
  'Create, edit, and remove users. Assign application roles.';
export const USER_MANAGEMENT_SECTION_USERS_BUTTON_TEXT = 'Manage Users';
export const USER_MENU_MYHW_BUTTON_TEXT = 'MyHealthwise';
export const USER_MENU_MY_PROFILE_BUTTON_TEXT = 'My Profile';
export const USER_MENU_GET_HELP_TEXT = 'Get Help';
export const USER_MENU_RESET_PASSWORD_TEXT = 'Reset Password';
export const USER_MENU_SIGN_OUT_TEXT = 'Sign Out';
export const USER_MENU_LOG_SERVICE_REQUEST_TEXT = 'Log Service Request';
export const USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT = 'Submit Content Feedback';
export const INCLUDED_APPLICATIONS_SERVICES_SECTION_TITLE =
  'Included applications and services';
export const INCLUDED_APPLICATIONS_SERVICES_SECTION_DESCRIPTION =
  'Here are all of the applications and services that you and your organization have access to.';
export const NO_APPLICATIONS_TEXT =
  'You have not been granted sufficient access to any administrative applications';
export const ACCOUNT_PROFILE_INFORMATION_PROFILE_IMAGE_ALT = 'edit profile';
export const ACCOUNT_PROFILE_INFORMATION_CLIENT_IMAGE_ALT = 'your organization';
// IDS
export const HEALTHWISE_ORGANIZATION_ID =
  '8524d03b-0c92-49f2-8db7-1a4310e3eb6f';

// ROLES
export const PROVISIONING_MANAGER_ROLE = 'ProvisioningManager';
export const ORGANIZATION_ADMINISTRATOR_ROLE = 'OrganizationAdministrator';
export const APPLICATION_ADMINISTRATOR_ROLE = 'ApplicationAdministrator';
// ROLE NAMES
export const PROVISIONING_MANAGER_ROLE_NAME = 'Provisioning Manager';
export const ORGANIZATION_ADMINISTRATOR_ROLE_NAME =
  'Organization Administrator';
export const APPLICATION_ADMINISTRATOR_ROLE_NAME = 'Application Administrator';
// EXTERNAL RELOAD LOGIN PATH
export const EXTERNAL_RELOAD_LOGIN_PATH = '/login/reload';

// CONFIRMATION MODAL BUTTON COLOR
export const DEFAULT_BTN_COLOR_CONFIRM_GREEN = 'green';
export const DEFAULT_BTN_COLOR_CONFIRM_RED = 'red';

// REVOKE ACCESS
export const REVOKE_ACCESS_BTN_TEXT = 'Revoke Access';
export const REVOKE_ACCESS_MODAL_TITLE =
  'Revoke access for this Healthwise employee?';
export const REVOKE_ACCESS_MODAL_TEXT =
  " will be removed as a delegated user and will no longer have access to any of your organization's applications.";

//RESET PASSWORD
export const RESET_PASSWORD_BTN_TEXT = 'Reset Password';
export const RESET_PASSWORD_MODAL_TITLE = 'Reset password?';

//DOCUMENT TITLE STRINGS
export const DOC_TITLE_HOME_PAGE = 'HomePage';
export const DOC_TITLE_MANAGE_USERS = 'ManageUsers';
export const DOC_TITLE_ORG_OVERVIEW = 'OrgOverview';
export const DOC_TITLE_ORG_DASHBOARD = 'OrgDashboard';
export const DOC_TITLE_DELEGATED_ACCESS = 'DelegatedAccess';
export const DOC_TITLE_APPLICATIONS = 'Applications';

//DOCUMENT TITLES
export const HOME_PAGE_TITLE = 'User Management: Homepage';
export const MANAGE_USERS_TITLE = 'User Management: Manage Users';
export const ORG_OVERVIEW_TITLE = 'User Management: Organization Overview';
export const ORG_DASHBOARD_TITLE = 'User Management: Organization Dashboard';
export const DELEGATED_ACCESS_TITLE = 'User Management: Delegate Access';
export const APPLICATIONS_TITLE = 'User Management: Applications Management';

// TABLE ID
export const ORG_ADMIN_TABLE_ID = 'orgAdminTable';
export const MANAGE_USERS_TABLE_ID = 'manageUsersTable';
export const SUBSCRIPTIONS_TABLE_ID = 'subscriptionsTable';
export const APPLICATION_MGMT_TABLE_ID = 'applicationMgmtTable';

//TABLE CAPTIONS
export const ORG_ADMIN_TABLE_CAPTION = 'Organization Administrators';
export const ORG_ADMIN_FOOTER_TABLE_CAPTION =
  'Organization Administrators Footer';
export const ORG_SUBSCRIPTIONS_TABLE_CAPTION = 'Organization Subscriptions';
export const ORG_SUBSCRIPTIONS_FOOTER_TABLE_CAPTION =
  'Organization Subscriptions Footer';
export const MANAGE_USERS_TABLE_CAPTION = 'Manage Users';
export const MANAGE_USERS_FOOTER_TABLE_CAPTION = 'Manage Users Footer';
export const APPLICATION_MANAGEMENT_TABLE_CAPTION = 'Application Management';
export const APPLICATION_MANAGEMENT_FOOTER_TABLE_CAPTION =
  'Application Management Footer';

/* USER-FACING STRINGS (TODO: SUPPORT LOCALIZATION) */
export const PRODUCT_NAME = 'Healthwise User Management';
export const ERROR_MESSAGE_PREAMBLE = `We're sorry, ${PRODUCT_NAME} has encountered an error`;
export const ERROR_MESSAGE_SUPPORT =
  'Please try again in a moment, or contact your administrator.';
