import React, { useState, useEffect, Fragment, useContext } from 'react';
import {
  Checkbox,
  Typography,
  Card,
  CardActions,
  CardContent,
  Collapse,
  FormControlLabel,
} from '@mui/material';
import { ADVISE, COMPASS } from 'utils/dictionary';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import { getRedoxIdForSelectedModulesV2 } from 'containers/organizationManagement/editSubscriptionConfigureProducts/utils';
import style from './moduleSelection.module.scss';
import FeatureSelection from './featureSelection';
import ContentSelection from './contentSelection';
import { MONTH_DATE_YEAR } from 'utils/dictionary';
import { FormatISO } from 'utils/timeAndDate';
import {
  ExpandButtonLeft,
  GreenOutlinedButtonLink,
  RedOutlinedButtonLink,
} from 'components/buttons/button';
import { updateCredentialStatus } from 'utils/api/credential';
import { Divider } from 'components/dialog/dialog';
import { validateCredential } from 'utils/api/provisioning';
import { AuthContext } from 'contexts/authContext';
import {
  FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG,
  FEATURE_TOGGLE_ADDITIONAL_SETTING,
} from 'utils/dictionary/featureToggles';
import { NotificationContext } from 'contexts/notificationContext';
import WarningIcon from '@mui/icons-material/Warning';
import Link from '@mui/material/Link';

const ModuleSelection = props => {
  const {
    onChange,
    onBlur,
    module = {},
    name,
    selectedModules = {},
    availableAssets,
    setFieldTouched,
    setFieldValue,
    errors,
    subscription,
    setRedoxError,
    setFieldError,
    compassCredential = {},
  } = props;

  const { id: moduleId, applications = [], name: moduleName } = module;
  const [redoxId, setRedoxId] = useState('');
  const [redoxFound, setRedoxFound] = useState(false);
  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [reset, setReset] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [adviseModule, setAdviseModule] = useState();
  const [compassModule, setCompassModule] = useState();
  const { accessToken } = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);
  const [enabled, setEnabled] = useState(true);

  const displayfilteredSettings =
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_ADDITIONAL_SETTING) === true &&
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG) === true;

  const moduleApplications = applications.map(appObj => {
    const { id } = appObj;
    const appVal = selectedApps?.find(x => x.sourceId === id);

    appObj = {
      sourceId: id,
      features: appVal?.features ?? [],
      content: appVal?.content ?? [],
      module: moduleId,
      ...(id === ADVISE && {
        applicationCredentialId: redoxId ?? '',
      }),
    };
    return appObj;
  }, {});

  useEffect(() => {
    if (errors.applicationCredentialId) {
      setFieldTouched('applicationCredentialId');
      if (adviseModule) {
        handleExpand(adviseModule, true);
      }
    }

    if (errors.compassKeyName) {
      setFieldTouched('compassKeyName');
      if (compassModule) {
        handleExpand(compassModule, true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setFieldTouched]);

  useEffect(() => {
    const adviseModule = module?.applications?.find(x => x.id === ADVISE);
    if (adviseModule) {
      setAdviseModule(module.id);
    }

    const compassModule = module?.applications?.find(x => x.id === COMPASS);
    if (compassModule) {
      setCompassModule(module.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  useEffect(() => {
    const apps = selectedModules[moduleId];
    setSelectedApps(apps);
    if (apps?.length > 0) {
      setSelectedModule(currentState => {
        return {
          ...currentState,
          [moduleId]: { checked: true },
        };
      });
    }

    const foundRedox = getRedoxIdForSelectedModulesV2(selectedModules);
    setRedoxFound(foundRedox.found);
    setRedoxId(foundRedox?.redoxId ?? '');

    if (Object.keys(compassCredential).length !== 0) {
      setFieldValue('compassKeyName', compassCredential.name);
      setFieldValue('compassKeyDescription', compassCredential?.description);
      setEnabled(compassCredential.enabled);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpand = (id, expand) => {
    setExpanded(expanded => ({
      ...expanded,
      [id]: expand,
    }));
  };

  const handleChange = e => {
    const checkedValue = e.target.checked;
    setSelectedModule(currentState => {
      return {
        ...currentState,
        [moduleId]: { checked: checkedValue },
      };
    });
    if (onBlur) {
      onBlur(`${name}.${moduleId}`, true, false);
    }

    let modules = Object.assign({}, props.values.modules);

    if (!checkedValue) {
      delete modules[moduleId];
      setRedoxId('');
      setRedoxFound(false);
      setReset(false);
    } else {
      modules[moduleId] = moduleApplications;
      setRedoxFound(true);
      setReset(true);
      handleExpand(moduleId, true);
    }
    onChange(`modules`, modules);
  };

  useEffect(() => {
    if (redoxFound && props.values.modules[moduleId]) {
      let copy = JSON.parse(JSON.stringify(props.values.modules[moduleId]));
      const appIndex = copy.findIndex(x => x.sourceId === ADVISE);
      if (appIndex > -1) {
        copy[appIndex].applicationCredentialId = redoxId;
        let modules = Object.assign({}, props.values.modules);
        modules[moduleId] = copy;
        onChange(`modules`, modules);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redoxId, redoxFound, moduleId]);

  const handleRedoxChange = e => {
    setFieldTouched('applicationCredentialId');
    setFieldValue('applicationCredentialId', redoxId);
    setRedoxId(e.target.value);
  };

  const handleKeyChange = e => {
    setFieldTouched('compassKeyName');
    setFieldValue('compassKeyName', e.target.value);
  };

  const handleDescriptionChange = e => {
    setFieldTouched('compassKeyDescription');
    setFieldValue('compassKeyDescription', e.target.value);
  };

  const verifyCredential = async e => {
    if (!e.target.value) {
      return;
    }

    const { error } = await validateCredential(
      subscription.subscriptionId,
      e.target.value,
      accessToken,
    );

    if (error) {
      setFieldError('applicationCredentialId', error.details.error.message);
      if (setRedoxError) {
        setRedoxError({ applicationCredentialId: error.details.error.message });
      }
    } else {
      delete errors['applicationCredentialId'];
      if (setRedoxError) {
        setRedoxError({});
      }
    }
    setFieldTouched('applicationCredentialId');
  };

  function getLicensedAppContent(id) {
    return selectedApps?.find(x => x.sourceId === id)?.content;
  }

  function getLicensedAppFeatures(id) {
    return selectedApps?.find(x => x.sourceId === id)?.features;
  }
  function getExpandLabel(expand, moduleName) {
    return expand ? `Collapse ${moduleName}` : `Expand ${moduleName}`;
  }

  const setEnabledStatus = enable => {
    const credential = { ...compassCredential };
    credential.enabled = enable;
    const updateStatus = updateCredential(
      credential,
      accessToken,
      setEnabled,
      showNotification,
      true,
    );

    updateStatus();
  };

  const setDisplayStatus = () => {
    const credential = { ...compassCredential };
    credential.display = true;
    const updateStatus = updateCredential(
      credential,
      accessToken,
      setEnabled,
      showNotification,
      false,
      true,
    );

    updateStatus();
  };

  return (
    <Card className={style.card}>
      <div
        className={`${style.cardModule} ${
          +expanded[moduleId] ? style.cardModuleExpand : ''
        }`}
      >
        <FormControlLabel
          style={{ pointerEvents: 'none' }}
          className={style.moduleCheckboxLabel}
          control={
            <Checkbox
              onChange={handleChange}
              inputProps={{ 'aria-label': moduleName }}
              checked={selectedModule[moduleId]?.checked ?? false}
              className={style.moduleCheckBox}
              style={{ pointerEvents: 'auto' }}
            />
          }
          label={module.name}
        />
        <CardActions className={style.cardActions}>
          <ExpandButtonLeft
            expand={expanded[moduleId] ? +expanded[moduleId] : undefined}
            onClick={() => handleExpand(moduleId, !expanded[moduleId])}
            aria-label={getExpandLabel(expanded[moduleId], moduleName)}
          />
        </CardActions>
      </div>
      <Collapse in={expanded[moduleId]}>
        <CardContent className={style.cardContent}>
          {module.applications.map((app, index) => {
            return (
              <Fragment key={index}>
                {index > 0 ? (
                  <div className={style.gridItemDividr}>
                    <Divider />
                  </div>
                ) : null}
                <Typography className={style.appName}>{app?.name}</Typography>
                {!displayfilteredSettings &&
                availableAssets[app.id]?.length > 0 ? (
                  <div className={style.selectionContainer}>
                    <Typography className={style.selectionName}>
                      Content
                    </Typography>
                    <div className={style.selectionOptions}>
                      <ContentSelection
                        availableAppAssets={availableAssets[app.id]}
                        application={app}
                        licenseAssets={getLicensedAppContent(app.id)}
                        module={module}
                        reset={reset}
                        {...props}
                      />
                    </div>
                  </div>
                ) : null}

                {!displayfilteredSettings &&
                app?.features?.optional?.length > 0 ? (
                  <div className={style.selectionContainer}>
                    <Typography className={style.selectionName}>
                      Optional Features
                    </Typography>
                    <div className={style.selectionOptions}>
                      {app?.features?.optional?.map((optFeature, index2) => {
                        return (
                          <Fragment key={index2}>
                            <FeatureSelection
                              {...props}
                              selectedFeatures={getLicensedAppFeatures(app.id)}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              module={module}
                              feature={optFeature}
                              application={app}
                              name={optFeature.name}
                              reset={reset}
                            />
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                ) : null}

                {app.id === ADVISE && (
                  <>
                    <div className={style.redoxInput}>
                      <BaselineTextInput
                        externalLabel={'Redox Id'}
                        isRequiredErrorMessage={false}
                        id="applicationCredentialId"
                        name="applicationCredentialId"
                        margin="none"
                        size="small"
                        value={redoxId}
                        onChange={handleRedoxChange}
                        onBlur={verifyCredential}
                        className={
                          errors.applicationCredentialId ? style.inputError : ''
                        }
                        disabled={!redoxFound}
                        hideValidationAdornment={false}
                      />
                    </div>
                  </>
                )}
                {app.id === COMPASS &&
                  Object.keys(compassCredential).length !== 0 && (
                    <>
                      <div className={style.apiTitle}>API Key Management</div>
                      <div className={style.apiKeyContainer}>
                        <div className={style.apiName}>
                          {compassCredential.name}
                          {enabled ? (
                            <>
                              <span
                                className={`${style.status} ${style.active}`}
                              >
                                ACTIVE
                              </span>
                              <span className={style.apiButton}>
                                <RedOutlinedButtonLink
                                  className={style.apiButton}
                                  buttonText="Deactivate"
                                  handleClick={() => setEnabledStatus(!enabled)}
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className={`${style.status} ${style.inactive}`}
                              >
                                INACTIVE
                              </span>
                              <span className={style.apiButton}>
                                <GreenOutlinedButtonLink
                                  buttonText="Activate"
                                  handleClick={() => setEnabledStatus(!enabled)}
                                />
                              </span>
                            </>
                          )}
                        </div>
                        <div className={style.apiDescription}>
                          {compassCredential.description}
                        </div>
                        <div className={style.apiCreated}>
                          Created on{' '}
                          {FormatISO(
                            compassCredential.createdDate,
                            MONTH_DATE_YEAR,
                          )}{' '}
                          by {compassCredential.createdBy}
                        </div>
                        <div>
                          {enabled ? (
                            <>
                              <Link
                                className={style.greenLink}
                                component="button"
                                type="button"
                                onClick={() => setDisplayStatus()}
                              >
                                Resend API Key
                              </Link>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </>
                  )}
                {app.id === COMPASS &&
                  Object.keys(compassCredential).length === 0 && (
                    <>
                      <div className={style.apiTitle}>API Key Management</div>
                      <div className={style.warningContainer}>
                        <WarningIcon className={style.apiWarningIcon} />
                        <span className={style.apiWarning}>
                          Please ensure all details are correct.
                        </span>
                        <div className={style.apiWarning2}>
                          This information cannot be changed at a later date.
                        </div>
                      </div>

                      <div className={style.textInput}>
                        <BaselineTextInput
                          externalLabel={'Display Name'}
                          isRequiredErrorMessage={false}
                          id="compassKeyName"
                          name="compassKeyName"
                          margin="none"
                          size="small"
                          value={app.compassKeyName}
                          onChange={handleKeyChange}
                          onBlur={verifyCredential}
                          hideValidationAdornment={false}
                          className={
                            errors.compassKeyName ? style.inputError : ''
                          }
                        />
                      </div>
                      <div className={style.textInput}>
                        <BaselineTextInput
                          externalLabel={'Description'}
                          isRequiredErrorMessage={false}
                          id="compassKeyDescription"
                          name="compassKeyDescription"
                          margin="none"
                          size="small"
                          value={app.compassKeyDescription}
                          onChange={handleDescriptionChange}
                          onBlur={verifyCredential}
                          hideValidationAdornment={false}
                        />
                      </div>
                    </>
                  )}
              </Fragment>
            );
          })}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ModuleSelection;

function updateCredential(
  credential,
  accessToken,
  setEnabled,
  showNotification,
  forEnable = false,
  forDisplay = false,
) {
  return async () => {
    const { data, response } = await updateCredentialStatus(
      credential,
      accessToken,
    );

    let status = '';

    if (response.ok) {
      if (forEnable) {
        setEnabled(data.enabled);
        status = credential.enabled ? 'reactivated' : 'deactivated';
      }
      if (forDisplay) {
        status = 'resent';
      }

      showNotification(
        `API Key ${credential.name} has been ${status}.`,
        false,
        10000,
        Date.now(),
      );
    } else {
      if (forEnable) {
        status = credential.enabled ? 'reactivate' : 'deactivate';
      }
      if (forDisplay) {
        status = 'resend';
      }
      showNotification(
        `Unable to ${status} API key. Please try again or contact your Healthwise development team if the issue persists.`,
        true,
        10000,
        Date.now(),
      );
    }
  };
}
