import React from 'react';
import { Link as MaterialLink } from '@mui/material';
import { Link } from 'react-router-dom';
import style from './pageLink.module.scss';

export const PageLink = ({
  icon,
  text,
  to,
  addMargin = true,
  sx,
  handleClick,
}) => {
  const margin = addMargin ? style.margin : '';

  return (
    <div className={`${style.container} ${margin}`}>
      <MaterialLink
        className={style.page}
        component={Link}
        to={to}
        sx={sx}
        onClick={handleClick}
      >
        {icon}
        <span>{text}</span>
      </MaterialLink>
    </div>
  );
};
