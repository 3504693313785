import * as Yup from 'yup';
import { addDays, startOfToday, max } from 'date-fns';

// An empty user schema for the various useEffects.
export const emptyUser = {
  firstName: ``,
  lastName: ``,
  email: ``,
  id: ``,
  lastLogin: ``,
  status: ``,
  organizationId: ``,
  organizationName: ``,
  organizationAdmins: [],
  identityProviderId: ``,

  // The subscriptions and applications for this user for this organization
  selectedSubscriptions: [],
  selectedApplications: [],

  // The roles being retrieved _and_ sent to the system
  // TODO: Double check, I think it's not longer in use...
  roles: [],
  // The selected roles while modifying things
  selectedRoles: [],
  startDate: null,
  endDate: null,
  logService: false,
  contentFeedback: false
};

// Generic method to convert object to a Date
const getObjectAsDate = date => {
  // startDate or endDate could be a string instead of a Date, so try to cast it to Date if it's not already one
  const dateFormat = date instanceof Date ? date : new Date(Date.parse(date));
  const dateValue =
    dateFormat instanceof Date && !isNaN(dateFormat) ? dateFormat : null;
  return dateValue;
};

export const getMinimumEndDate = startDate => {
  const todayValue = startOfToday();
  let startDateValue =
    getObjectAsDate(startDate) != null
      ? getObjectAsDate(startDate)
      : todayValue;

  const maxDate = max([startDateValue, todayValue]);

  return {
    dateName: maxDate > todayValue ? 'Start Date' : "today's date or later",
    date: addDays(maxDate, 1),
  };
};

export const UserValidationSchemaDefinition = {
  firstName: Yup.string()
    .max(40, 'Must be 40 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(40, 'Must be 40 character or less')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  selectedRoles: Yup.array().min(1, 'Must select at least 1 role'),
  startDate: Yup.date()
    .label('Start Date')
    .required('Start date is required and must have a value')
    .nullable()
    .default(undefined)
    .typeError('Invalid Date'),
  endDate: Yup.date()
    .label('End Date')
    .when('startDate', (startDate, schema) => {
      const startDateValue = getMinimumEndDate(startDate);
      return schema.min(
        startDateValue.date,
        `End Date must be after ${startDateValue.dateName}`,
      );
    })
    .nullable()
    .default(undefined)
    .typeError('Invalid Date'),
};

export const getCustomUserValidationSchema = schemaObject => {
  return Yup.object().shape(schemaObject);
};

export const UserValidationSchema = getCustomUserValidationSchema(
  UserValidationSchemaDefinition,
);

/**
 * Iterate over the subscriptions in the state `selectedRoles` and flatten the
 * objects into the UserDto format needed for creating the user.
 *
 * Note that the role string in the 'name' is the
 * "<orgId>::<subId>::<appId>::rolename" format. It will be converted to the
 * okta group id on the appserver.
 *
 */
export const generateFlattenedRoles = (organizationId, roles) => {
  return roles.map(role => {
    return {
      id: `${organizationId}::${role.id}`,
      name: `${organizationId}::${role.id}`,
      referenceId: `${organizationId}::${role.id}`,
    };
  });
};

export const deduplicateArray = duplicated => {
  let uniques = [];
  duplicated.forEach(possible => {
    let exists = uniques.some(unique => {
      return unique.id === possible.id;
    });
    if (!exists) {
      uniques.push(possible);
    }
  });
  return uniques;
};
