import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { format } from 'date-fns';
import { Button } from '@mui/material';

import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { AuthContext } from 'contexts/authContext';
import Notification from 'components/notificationV2/notification';
import { resetPassword } from 'utils/api/users';
import { resetPasswordOrganizationAdministrator } from 'utils/api/organizations';
import { DEFAULT_BTN_COLOR_CONFIRM_GREEN } from 'utils/dictionary/overview';
import style from './resetPasswordWorkflow.module.scss';

export const ResetPasswordWorkflow = forwardRef((props, ref) => {
  const { forOrgAdmin, userInfo, organization } = props;

  const authContext = useContext(AuthContext);

  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [resetChanged, setResetChanged] = useState(false);
  const [error, setError] = useState(false);

  const handleResetModalOpen = () => setResetModalOpen(true);
  const handleResetModalClose = () => setResetModalOpen(false);

  const allowReset = ['ACTIVE'].includes(userInfo?.status);

  useImperativeHandle(ref, () => ({
    handleResetModalOpen() {
      handleResetModalOpen();
    },
  }));

  const handleResetPasswordReset = () => {
    const executePasswordReset = async (userId, accessToken) => {
      setResetModalOpen(false);

      const { response, error } = forOrgAdmin
        ? await resetPasswordOrganizationAdministrator(
            organization.organizationId,
            userId,
            accessToken,
          )
        : await resetPassword(userId, accessToken);

      if (response.ok) {
        setResetChanged(true);
      } else if (error) {
        setError(true);
      }
    };

    const userId = userInfo.id ?? userInfo.userId;
    const accessToken = authContext?.accessToken;
    if (userId && accessToken) {
      executePasswordReset(userId, accessToken);
    }
  };

  return (
    allowReset && (
      <>
        <div>
          {allowReset && !forOrgAdmin ? (
            <Button
              className={style.restPasswordButton}
              onClick={handleResetModalOpen}
            >
              Reset Password
            </Button>
          ) : null}
        </div>
        {resetModalOpen ? (
          <ConfirmationModal
            open={resetModalOpen}
            title="Reset password?"
            personFullName={`${userInfo.firstName} ${userInfo.lastName}`}
            email={` at ${userInfo.email}`}
            text=" will be emailed instructions to reset their password."
            confirmText="Reset Password"
            handleClose={handleResetModalClose}
            handleCancel={handleResetModalClose}
            handleConfirm={handleResetPasswordReset}
            color={DEFAULT_BTN_COLOR_CONFIRM_GREEN}
          />
        ) : resetChanged ? (
          <Notification
            message={`Password reset instructions emailed to ${
              userInfo.firstName
            } ${userInfo.lastName} at ${format(
              new Date(),
              "h:mm aaaa 'on' MMMM d, yyyy",
            )}. This user has 7 days to reset their password.`}
          />
        ) : error ? (
          <Notification
            error
            message="Error sending password reset instructions to user. Please try again or contact your Healthwise administrator."
          />
        ) : null}
      </>
    )
  );
});

export default ResetPasswordWorkflow;
