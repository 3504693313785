import React, { Fragment } from 'react';

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import style from './subscriptionDetailPanel.module.scss';
import { getRedoxIdV2 } from 'containers/organizationManagement/editSubscriptionConfigureProducts/utils';
import { ADVISE, COMPASS } from 'utils/dictionary';
import { Divider } from 'components/dialog/dialog';
import { ADVISEINTERNAL, SUBSCRIPTION } from 'utils/configuration/settings';
import { FEATURE_TOGGLE_ADDITIONAL_SETTING } from 'utils/dictionary/featureToggles';
import { FeatureToggle } from 'react-feature-toggles/lib';
import { FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG } from 'utils/dictionary/featureToggles';

const optFeatures = {};

const GetApplicationNames = packages => {
  return packages.reduce((appNamesObj, packageItem) => {
    const { modules } = packageItem;
    modules.forEach(moduleItem => {
      const { applications } = moduleItem;

      applications.forEach(app => {
        appNamesObj[app.id] = app.name;
      });
    });
    return appNamesObj;
  }, {});
};

const GetModulesNames = packages => {
  return packages.reduce((modulesNamesObj, packageItem) => {
    const { modules } = packageItem;
    modules.forEach(moduleItem => {
      const { id, name, applications } = moduleItem;
      modulesNamesObj[id] = name;

      applications.forEach(app => {
        optFeatures[app.id] = app.features.optional.length;
      });
    });
    return modulesNamesObj;
  }, {});
};

const GetListOfContentNames = assets => {
  const results = assets?.map(item => {
    return item.assetName;
  });

  const content = results && results.length >= 1 ? results.join(', ') : '';
  return content;
};

const GetModulesAndApplications = (
  title,
  applications = [],
  name,
  optional,
  id,
  content,
) => {
  return {
    title,
    applications: [
      ...applications,
      {
        application: name,
        optionalFeatures: !Array.isArray(optional)
          ? optional
          : optional?.length === 0
          ? 'None selected'
          : optional.map(item => item.featureName).join(', '),
        id: id,
        content,
      },
    ],
  };
};

const GetConfigureProductAndContents = (
  entitlementPacket,
  subscriptionLicense,
  licensedApplications,
) => {
  const { licensing = {} } = entitlementPacket ?? {};
  const { packages = [] } = licensing;

  const applications = Object.values(licensedApplications).flatMap(x => x);

  const foundRedoxId = getRedoxIdV2(applications);

  const modulesNames = GetModulesNames(packages);

  const appName = GetApplicationNames(packages);

  const modulesAndApplications = {};

  applications.forEach((app, index) => {
    const { features, content, sourceId, module } = app;

    if (!modulesAndApplications[module]) {
      modulesAndApplications[module] = GetModulesAndApplications(
        modulesNames[module],
        [],
        appName[sourceId],
        features,
        sourceId,
        content,
      );
    } else {
      const { title, applications } = modulesAndApplications[module];

      modulesAndApplications[module] = GetModulesAndApplications(
        title,
        applications,
        appName[sourceId],
        features,
        sourceId,
        content,
      );
    }
  });

  return {
    applicationsData: Object.values(modulesAndApplications),
    foundRedoxId: foundRedoxId,
  };
};

const GetSubscriptionConfigurationValues = rowData => {
  const values = [];

  if (rowData.friendlyId) {
    values.push({
      name: 'Content Identifier',
      value: rowData.friendlyId,
    });
  }

  return values;
};

const SubscriptionDetailPanelV2 = props => {
  const {
    renderSubscriptionInlineEditNotes,
    renderSubscriptionEditConfigureProducts,
    entitlementPacketData,
    rowData,
    organizationId,
    settingList,
    readOnly = false,
  } = props;

  const displayfilteredSettings =
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG) === true;

  const {
    entitlementPacket,
    subscriptionLicense,
    licensedApplicationAssets,
    licensedApplications,
    compassCredential,
  } = entitlementPacketData ?? {};

  const subSettings = settingList?.filter(x => x.type === SUBSCRIPTION);
  const adviseInternalSettings = settingList?.filter(
    x => x.type === ADVISEINTERNAL,
  );

  function getValue(value) {
    if (value.toLowerCase() === 'true') {
      return 'On';
    } else if (value.toLowerCase() === 'false') {
      return 'Off';
    } else {
      return value;
    }
  }

  const NoProductsConfigured = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={8}>
          <div className={style.noProduct}>
            No products configured.
            {!readOnly && ' Select Modify Subscription to add products.'}
          </div>
        </Grid>
        <Grid item xs={4}>
          {!readOnly && (
            <div style={{ float: 'right' }}>
              {renderSubscriptionEditConfigureProducts({
                rowData,
                organizationId,
              })}
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const ProductsAndContentsList = props => {
    const {
      entitlementPacket,
      subscriptionLicense,
      licensedApplications,
      compassCredential,
    } = props;

    const { applicationsData, foundRedoxId } = GetConfigureProductAndContents(
      entitlementPacket,
      subscriptionLicense,
      licensedApplications,
    );

    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs container direction="column">
            {applicationsData.map(app => {
              const { title, applications } = app;
              const appCount = applications.filter(
                x => x.application !== undefined,
              ).length;
              return (
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  key={app.title}
                  className={style.sectionContainer}
                >
                  <Grid item xs={12}>
                    <div className={style.title}>
                      {title ??
                        (!readOnly && (
                          <span className={style.warning}>
                            This module has been removed from the entitlement
                            packet
                          </span>
                        ))}
                    </div>
                  </Grid>

                  {applications.map((element, index2) => {
                    let contentNames = GetListOfContentNames(element.content);
                    return (
                      <Fragment key={element.id}>
                        {index2 > 0 && appCount > 1 ? (
                          <div className={style.row}>
                            <div
                              className={`${style.colTitle} ${style.alignRight} `}
                            ></div>
                            <div
                              className={`${style.data} ${style.column} ${style.dividerContainer}`}
                            >
                              <Divider className={style.divider} />
                            </div>
                          </div>
                        ) : null}

                        {element.application !== undefined ? (
                          <>
                            <div className={style.row}>
                              <div
                                className={`${style.colTitle} ${style.alignRight} `}
                              >
                                Application
                              </div>
                              <div className={`${style.data} ${style.column} `}>
                                {element.application}
                              </div>
                            </div>
                            {!displayfilteredSettings && contentNames !== '' ? (
                              <div className={style.row}>
                                <div
                                  className={`${style.colTitle}  ${style.alignRight}`}
                                >
                                  Content
                                </div>
                                <div
                                  className={`${style.data} ${style.column} `}
                                >
                                  {contentNames}
                                </div>
                              </div>
                            ) : null}

                            {!displayfilteredSettings &&
                            optFeatures[element.id] > 0 ? (
                              <div className={style.row}>
                                <div
                                  className={`${style.colTitle}  ${style.alignRight}`}
                                >
                                  Optional Features
                                </div>

                                <div
                                  className={`${style.data} ${style.column} `}
                                >
                                  {element.optionalFeatures}
                                </div>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                        {element.id === COMPASS &&
                          !readOnly &&
                          compassCredential !== undefined && (
                            <>
                              <div
                                className={`${style.colTitle}  ${style.alignRight}`}
                              >
                                API Key
                              </div>
                              <div className={`${style.data} ${style.column} `}>
                                {compassCredential?.enabled
                                  ? 'Activated'
                                  : 'Deactivated'}
                              </div>
                            </>
                          )}

                        {element.id === ADVISE &&
                          foundRedoxId.found &&
                          foundRedoxId.redoxId && (
                            <>
                              <div
                                className={`${style.colTitle}  ${style.alignRight}`}
                              >
                                Redox ID
                              </div>
                              <div className={`${style.data} ${style.column} `}>
                                {foundRedoxId.redoxId}
                              </div>
                            </>
                          )}
                        <FeatureToggle
                          featureName={FEATURE_TOGGLE_ADDITIONAL_SETTING}
                        >
                          {element.id === ADVISE &&
                            !readOnly &&
                            adviseInternalSettings.length > 0 &&
                            adviseInternalSettings?.map(item => (
                              <div
                                className={style.row}
                                key={item.option.settingId}
                              >
                                <div
                                  className={`${style.colTitle}  ${style.alignRight}`}
                                >
                                  {item.name}
                                </div>
                                <div
                                  className={`${style.data} ${style.column} `}
                                >
                                  {getValue(item.option.optionValue)}
                                </div>
                              </div>
                            ))}
                        </FeatureToggle>
                      </Fragment>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
          {readOnly ? (
            <></>
          ) : (
            <>
              {renderSubscriptionEditConfigureProducts({
                rowData,
                organizationId,
              })}
            </>
          )}
        </Grid>
      </div>
    );
  };

  const NoteAndTagSection = () => {
    return (
      <div>
        <div className={style.title}>Internal Notes</div>
        <div className={style.subTitle}>
          These notes are NOT visible to the client.
        </div>
        {renderSubscriptionInlineEditNotes({
          rowData,
          organizationId,
        })}
      </div>
    );
  };

  const subscriptionConfiguration = GetSubscriptionConfigurationValues(rowData);

  return (
    <div className={style.subscriptionContainer}>
      {Object.keys(entitlementPacketData).length ? (
        <>
          {subscriptionLicense ? (
            <ProductsAndContentsList
              entitlementPacket={entitlementPacket}
              subscriptionLicense={subscriptionLicense}
              licensedApplicationAssets={licensedApplicationAssets}
              licensedApplications={licensedApplications}
              compassCredential={compassCredential}
            />
          ) : (
            <NoProductsConfigured />
          )}
          {!readOnly &
          (!!subscriptionConfiguration.length || !!subSettings?.length) ? (
            <Grid
              item
              xs
              container
              direction="row"
              className={style.sectionContainer}
            >
              <div className={style.title}>Subscription Configuration</div>

              {subscriptionConfiguration.map(item => (
                <div className={style.row} key={item.value}>
                  <div className={`${style.colTitle}  ${style.alignRight}`}>
                    {item.name}
                  </div>
                  <div className={`${style.data} ${style.column} `}>
                    {item.value}
                  </div>
                </div>
              ))}

              {subSettings?.map(item => {
                if (
                  item.name === 'Content Feedback' &&
                  rowData.subscriptionType !== 'Management'
                ) {
                  return <div key={item.settingId}></div>;
                } else {
                  return (
                    <div className={style.row} key={item.settingId}>
                      <div className={`${style.colTitle}  ${style.alignRight}`}>
                        {item.name}
                      </div>
                      <div className={`${style.data} ${style.column} `}>
                        {getValue(item.option.optionValue)}
                      </div>
                    </div>
                  );
                }
              })}
            </Grid>
          ) : null}
          {readOnly ? null : NoteAndTagSection()}
        </>
      ) : (
        <Skeleton variant="rect" height={250} />
      )}
    </div>
  );
};

SubscriptionDetailPanelV2.propTypes = {
  renderSubscriptionInlineEditNotes: PropTypes.func.isRequired,
  renderSubscriptionEditConfigureProducts: PropTypes.func.isRequired,
  entitlementPacketData: PropTypes.shape({}).isRequired,
  rowData: PropTypes.shape({}).isRequired,
};

export default SubscriptionDetailPanelV2;
