import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { Button } from '@mui/material';
import { Form } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/dialog';
import { GeneralCancelButton } from 'components/buttons/button';

import { BaselineTextInput } from 'components/textInputV2/textInput';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import style from './subscriptionSwitchModal.module.scss';
import buttonStyle from 'components/buttons/button.module.scss';

const SwitchModal = props => {
  const {
    active,
    open,
    handleClose,
    isSubmitting,
    isValid,
    dirty,

    subscriptionName,
  } = props;

  const buttonColor = active ? buttonStyle.buttonRed : buttonStyle.greenButton;

  const dialogText = active
    ? 'Deactivate this subscription?'
    : 'Activate this subscription?';

  const confirmationButtonText = active
    ? 'Deactivate Subscription'
    : 'Activate Subscription';

  const inputRef = useRef(null);
  useAddFocus(inputRef, open);

  return (
    <>
      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-warning dialog-description"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle id="dialog-title">{dialogText}</DialogTitle>
          {active ? (
            <DialogContent className={style.dialogContent}>
              <p className={style.highlightedText} id="dialog-warning">
                Deactivating a subscription will immediately remove access to
                any {'\n'}
                applications and content within that subscription.
              </p>
              <p className={style.modalText} id="dialog-description">
                Are you sure you want to deactivate{' '}
                <strong>{subscriptionName}</strong>?{'\n'}
                Type 'confirm' below.
              </p>
              <div className={style.textFieldContainer}>
                <BaselineTextInput
                  id="subscriptionSwitch"
                  name="subscriptionSwitch"
                  fullWidth
                  externalLabel="Confirm"
                  inputRef={inputRef}
                  errorlabel="Confirm"
                />
              </div>
            </DialogContent>
          ) : (
            <DialogContent className={style.dialogContent}>
              <p className={style.modalText} id="dialog-description">
                Activating <strong>{subscriptionName}</strong> will provide
                immediate access to any {'\n'}applications and content within
                that subscription.
              </p>
            </DialogContent>
          )}

          <DialogActions className={style.modalBtnsContainer}>
            <GeneralCancelButton
              onClick={handleClose}
              disabled={isSubmitting}
              disableRipple
            >
              Cancel
            </GeneralCancelButton>
            {active ? (
              <Button
                type="submit"
                className={`${buttonStyle.button} ${buttonColor}`}
                disabled={!(isValid && dirty)}
              >
                {confirmationButtonText}
              </Button>
            ) : (
              <Button
                type="submit"
                className={`${buttonStyle.button} ${buttonColor}`}
              >
                {confirmationButtonText}
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
const SubscriptionSwitchModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { closeModal, handleReset, closeMenu } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) handleReset();
    setOpen(false);
    closeMenu();
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal?.current?.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      handleOpen();
    },
  }));

  return (
    <SwitchModal
      {...props}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
});

export default SubscriptionSwitchModal;
