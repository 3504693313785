import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Container, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { RoundedToggleButtonGroup } from 'components/buttons/button';

import OrganizationsListPanel from 'pages/organizations/components/organizationListPanel/organizationListPanel';
import { ListSkeleton } from 'components/skeletons/skeletons';
import PageHeader from 'components/pageHeader/pageHeader';
import { useUpdateDocumentTitle } from 'utils/hooks/common/useUpdateDocumentTitle';
import style from './organizationManagementList.module.scss';
import { DOC_TITLE_ORG_DASHBOARD } from 'utils/dictionary/overview';

// AJC TODO: Need pagination (or display all orgs for now)
const OrganizationManagementList = ({
  bannerButton,
  listOfOrganizations,
  organizationsRetrieved,
  organizationCategories,
}) => {
  // TODO:
  // A) Insert Text Field into the header to reflect UI design
  // Z) Implement pagination and on the fly filtering

  const [toggleCategories, setToggleCategories] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);

  const handleCategoryChange = (event, newCategories) => {
    setToggleCategories(newCategories);
    const filteredOrgsArray = listOfOrganizations.filter(org => {
      for (let category of newCategories) {
        if (org.organizationCategory === category) {
          return true;
        }
      }
      return false;
    });
    setFilteredOrganizations(filteredOrgsArray);
  };

  useUpdateDocumentTitle(DOC_TITLE_ORG_DASHBOARD);

  return (
    <>
      <PageHeader
        title="Organization Dashboard"
        secondary={
          'Choose an existing organization to manage or create a new organization.'
        }
      />
      <div className={style.filterContainer}>
        <Typography className={style.filterLabel}>
          Filter by category:
        </Typography>

        <RoundedToggleButtonGroup
          value={toggleCategories}
          onChange={handleCategoryChange}
          aria-label="Filter organizations by category"
        >
          {organizationCategories.map(category => (
            <ToggleButton
              className={style.orgCategoryButton}
              key={category.organizationCategoryId}
              value={category.name}
              aria-label={category.name}
            >
              {category.name}
            </ToggleButton>
          ))}
        </RoundedToggleButtonGroup>
        {bannerButton}
      </div>

      {organizationsRetrieved ? (
        <OrganizationsListPanel
          organizations={
            toggleCategories.length === 0
              ? listOfOrganizations
              : filteredOrganizations
          }
        />
      ) : (
        <Container>
          <ListSkeleton />
          <ListSkeleton />
          <ListSkeleton />
        </Container>
      )}
    </>
  );
};

OrganizationManagementList.propTypes = {
  bannerButton: PropTypes.node.isRequired,
  listOfOrganizations: PropTypes.array.isRequired,
};

export default OrganizationManagementList;
