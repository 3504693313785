import React from 'react';
import ToolTip from 'components/tooltipV2/tooltip';
import { SubscriptionSelector } from 'components/subscriptionSelectorV2/subscriptionSelector';

import style from './roleAssignment.module.scss';

// Pass in `readOnly` to disable the adding/removing of values
const RoleAssignment = props => {
  const {
    headline = '2. Assign Subscriptions and Roles',
    subheader = 'These permissions control access to applications and should be granted with caution.',
    tooltipText,
  } = props;

  return (
    <>
      <p className={style.cardHeadline}>
        {headline}
        <ToolTip title={tooltipText} />
      </p>
      <p className={style.cardDescription}>{subheader}</p>
      <SubscriptionSelector
        className={style.subscriptionSelector}
        compact={false}
        allowMultiple={true}
        {...props}
      />
    </>
  );
};

export default RoleAssignment;
