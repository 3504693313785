import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Chip } from '@mui/material';

import DebugValueWithClipboard from 'components/debugValueWithClipboard/debugValueWithClipboard';
import { GreenGeneralButton } from 'components/buttons/button';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import style from './organization.module.scss';

const chipColors = {
  client: '#CCEEE7',
  demo: '#FFF1D1',
  internal: '#F9F9F9',
};

const borderColors = {
  client: '#CCEEE7',
  demo: '#FFF1D1',
  internal: '#BCBCBC',
};

const OrgCategoryPill = props => {
  return (
    <Chip
      className={style.chip}
      style={{
        border: `1px solid ${borderColors[props.pillcolor]}`,
        background: chipColors[props.pillcolor],
      }}
      {...props}
    />
  );
};

const handleViewOrganizationClick = (history, props) => {
  history.push({
    pathname: EDIT_ORGANIZATION_PATH,
    search: window.location.search,
    state: {
      organizationId: props.organizationId,
    },
  });
};

const Organization = props => {
  let {
    organizationName,
    organizationCategory,
    organizationId,
    salesforceReferences,
  } = props;

  const sfAccountId = salesforceReferences[0].accountId;
  const history = useHistory();

  return (
    <>
      <div className={style.orgContainer}>
        <div className={style.orgInformation}>
          <p className={style.orgNameText}>{organizationName}</p>
          <OrgCategoryPill
            pillcolor={organizationCategory.toLowerCase()}
            label={organizationCategory}
            size="small"
            variant="outlined"
          />
        </div>
        <div className={style.orgActions}>
          <GreenGeneralButton
            buttonText="Manage"
            aria-label={`Manage ${organizationName}`}
            handleClick={() => handleViewOrganizationClick(history, props)}
          />
        </div>
      </div>
      <DebugValueWithClipboard
        className={style.orgInformation}
        value={organizationId}
        title="Organization ID"
      />
      <DebugValueWithClipboard
        className={style.orgInformation}
        value={sfAccountId}
        title="SF Account ID"
      />
    </>
  );
};

Organization.defaultProps = {
  orgName: 'ERROR NAME',
};

Organization.propTypes = {
  orgName: PropTypes.string.isRequired,
};

export default Organization;
