import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'contexts/authContext';
import { OrganizationContext } from 'contexts/organizationContext';
import { ClickableCard } from 'components/card/card';
import { Grid } from '@mui/material';
import {
  UserIcon,
  DiagramColorIcon,
  DelegateIcon,
  UserCogColorIcon,
} from 'components/icons/icons';
import { GreenToLink } from 'components/buttons/button';
import style from './overview.module.scss';
import { useRetrieveOrganizationAdministrators } from 'utils/hooks/useUserOrganizationAdministrators';
import { useReferenceSubscriptions } from 'contexts/referenceSubscriptionsContext';
import { Redirect } from 'react-router-dom';

import * as OVERVIEW from 'utils/dictionary/overview';
import * as LINKS from 'utils/configuration/links';
import { ORGANIZATION_OVERVIEW_PATH } from 'utils/configuration/links';
import { useUpdateDocumentTitle } from 'utils/hooks/common/useUpdateDocumentTitle';

const Overview = () => {
  const { organization } = useContext(OrganizationContext);
  const { managementRoles, organizationAlias } = useContext(AuthContext);
  const [managementApplications, setManagementApplications] = useState([]);
  const [didMount, setDidMount] = useState(false);
  const { referenceSubscriptions } = useReferenceSubscriptions();
  const { data: orgAdmins } = useRetrieveOrganizationAdministrators(0, 4);
  const [path, setPath] = useState(null);

  useUpdateDocumentTitle(OVERVIEW.DOC_TITLE_HOME_PAGE);

  useEffect(() => {
    setDidMount(true);
    const parseManagementRoles = () => {
      const applications = [];
      if (managementRoles.includes(OVERVIEW.ORGANIZATION_ADMINISTRATOR_ROLE)) {
        applications.push({
          title: OVERVIEW.USER_MANAGEMENT_SECTION_TITLE,
          description: OVERVIEW.USER_MANAGEMENT_SECTION_USERS_DESCRIPTION,
          link: `/${organizationAlias}/${LINKS.USER_MANAGEMENT_PATH}`,
          icon: <UserIcon className={style.cardIcon} />,
        });

        applications.push({
          title: OVERVIEW.ORGANIZATION_OVERVIEW_CARD_TITLE,
          description: OVERVIEW.ORGANIZATION_OVERVIEW_CARD_DESCRIPTION,
          link: `/${organizationAlias}/${LINKS.ORGANIZATION_OVERVIEW_PATH}`,
          icon: <DiagramColorIcon className={style.cardIcon} />,
        });
        if (
          Object.keys(organization).length !== 0 &&
          organization?.organizationName !== OVERVIEW.HEALTHWISE_ORG
        ) {
          applications.push({
            title: OVERVIEW.DELEGATED_ACCESS_CARD_TITLE,
            description: OVERVIEW.DELEGATED_ACCESS_CARD_DESCRIPTION,
            link: `/${organizationAlias}/${LINKS.DELEGATED_ACCESS_PATH}`,
            icon: <DelegateIcon className={style.cardIcon} />,
          });
        }
      }

      if (managementRoles.includes(OVERVIEW.PROVISIONING_MANAGER_ROLE)) {
        applications.push({
          title: OVERVIEW.ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_TITLE,
          description:
            OVERVIEW.ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_DESCRIPTION,
          link: `/${organizationAlias}/${LINKS.ORGANIZATION_LIST}`,
          icon: <DiagramColorIcon className={style.cardIcon} />,
        });
      }

      if (managementRoles.includes(OVERVIEW.APPLICATION_ADMINISTRATOR_ROLE)) {
        applications.push({
          title: OVERVIEW.APPLICATION_MANAGEMENT_SECTION_TITLE,
          description: OVERVIEW.APPLICATION_MANAGEMENT_SECTION_DESCRIPTION,
          link: `/${organizationAlias}/${LINKS.APPLICATIONS_PATH}`,
          icon: <DiagramColorIcon className={style.cardIcon} />,
        });
      }

      setManagementApplications(applications);
    };
    if (managementRoles.includes(OVERVIEW.ORGANIZATION_ADMINISTRATOR_ROLE)) {
      parseManagementRoles();
    }

    if (
      !managementRoles.includes(OVERVIEW.ORGANIZATION_ADMINISTRATOR_ROLE) &&
      (managementRoles.includes(OVERVIEW.PROVISIONING_MANAGER_ROLE) ||
        managementRoles.includes(OVERVIEW.APPLICATION_ADMINISTRATOR_ROLE))
    ) {
      setPath(
        managementRoles.includes(OVERVIEW.PROVISIONING_MANAGER_ROLE)
          ? '/organizations'
          : '/applications',
      );
    }

    return () => setDidMount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementRoles, organization]);

  if (!didMount) {
    return null;
  }

  return (
    <>
      {path != null ? (
        <Redirect
          push
          to={{
            pathname: path,
          }}
        />
      ) : (
        <section>
          <div className={style.content}>
            <div className={style.row}>
              <aside className={style.column}>
                <div className={style.column}>
                  <h2 className={style.title}>Subscriptions</h2>
                  {referenceSubscriptions &&
                    referenceSubscriptions.slice(0, 3).map((sub, index) => {
                      return (
                        <div className={style.item} key={index}>
                          {sub.name}
                        </div>
                      );
                    })}
                  {referenceSubscriptions.length > 3 && (
                    <GreenToLink
                      to={`/${organizationAlias}/${ORGANIZATION_OVERVIEW_PATH}${window.location.search}#sub`}
                      ariaLabel="View all subscriptions"
                      text={'View more'}
                      hasUnderline={true}
                    ></GreenToLink>
                  )}
                </div>
                <div className={style.column}>
                  <h2 className={style.title}>Administrators</h2>
                  {orgAdmins &&
                    orgAdmins.slice(0, 3).map((admin, index) => {
                      return (
                        <div className={style.admin} key={index}>
                          <UserCogColorIcon className={style.adminIcon} />
                          <span className={style.item}>
                            {admin.firstName} {admin.lastName}
                          </span>
                        </div>
                      );
                    })}
                  {orgAdmins.length > 3 && (
                    <GreenToLink
                      to={`/${organizationAlias}/${ORGANIZATION_OVERVIEW_PATH}${window.location.search}#admin`}
                      ariaLabel="View all administrators"
                      text={'View more'}
                      hasUnderline={true}
                    ></GreenToLink>
                  )}
                </div>
              </aside>
              <section className={style.right}>
                <h2 className={`${style.title} ${style.manage}`}>
                  Manage Your Organization
                </h2>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                >
                  {managementApplications.map((item, index) => (
                    <Grid key={index} item>
                      <ClickableCard {...item} />
                    </Grid>
                  ))}
                </Grid>
              </section>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Overview;
