import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import style from './contentAndFeatureSelection.module.scss';

const FeatureSelection = props => {
  const {
    selectedFeatures = [],
    onChange,
    onBlur,
    module,
    feature,
    application,
    name,
    reset,
  } = props;

  const [optFeatureChecked, setOptFeatureChecked] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [once, setOnce] = useState(true);

  const selectFeatures = useCallback(
    () =>
      selectedFeatures?.forEach(item => {
        setOptFeatureChecked(currentState => {
          return {
            ...currentState,
            [item.featureId]: { checked: true },
          };
        });
      }),
    [selectedFeatures],
  );

  useEffect(() => {
    let modules = props.values.modules[module.id];

    if (!modules || Object.keys(modules).length === 0) {
      setDisabled(true);
      setOptFeatureChecked({});
    } else {
      setDisabled(false);
    }
  }, [module.id, props.values.modules]);

  useEffect(() => {
    if (selectedFeatures.length > 0 && once) {
      selectFeatures();
      setOnce(false);
    }
  }, [selectedFeatures, selectFeatures, once]);

  useEffect(() => {
    if (reset) {
      selectFeatures();
    }
  }, [reset, selectFeatures]);

  const handleChange = e => {
    const checked = e.target.checked;

    setOptFeatureChecked(currentState => {
      return {
        ...currentState,
        [feature.id]: { checked: checked },
      };
    });

    let copy = JSON.parse(JSON.stringify(props.values.modules[module.id]));

    const appIndex = copy.findIndex(x => x.sourceId === application.id);
    if (checked) {
      const feat = { featureId: feature.id, featureName: feature.name };

      copy[appIndex]?.features.push(feat);
    } else {
      let index = copy[appIndex].features.findIndex(x => x.id === feature.id);
      copy[appIndex]?.features.splice(index, 1);
    }

    let modules = Object.assign({}, props.values.modules);
    modules[module.id] = copy;

    onBlur(`modules[${module.id}].[${appIndex}].features`);
    onChange('modules', modules);
  };

  return (
    <FormControlLabel
      className={`${style.checkboxChip} ${
        optFeatureChecked[feature.id]?.checked ? style.checkboxChipSelected : ''
      }`}
      control={
        <Checkbox
          className={
            !optFeatureChecked[feature.id]?.checked ? style.uncheck : ''
          }
          color="primary"
          onChange={handleChange}
          aria-label={`${name} for ${application.name}`}
          checked={optFeatureChecked[feature.id]?.checked || false}
          checkedIcon={<CheckIcon className={style.check} />}
          disabled={disabled}
          id="featureCheckbox"
          sx={{
            padding: '0 6px 0 0',
            display: disabled ? 'none' : '',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        />
      }
      label={name}
    />
  );
};

export default FeatureSelection;
