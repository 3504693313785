import * as configuration from 'utils/configuration/applications';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const applicationsUrl = baseUrl + configuration.APPLICATIONS_ROOT;

export async function createReport(payload, accessToken) {
  let createUrl = `${applicationsUrl}/reports`;
  return await utils.createResource(createUrl, payload, accessToken);
}

export async function deleteReport(applicationReportId, accessToken) {
    let deleteUrl = `${applicationsUrl}/reports/${applicationReportId}`;
    return await utils.deleteResource(deleteUrl, {}, accessToken);
  }

export async function getReports(applicationId, accessToken) {
  let getUrl = `${applicationsUrl}/${applicationId}/reports`;
  return await utils.getResource(getUrl, accessToken);
}

export async function getUnassignedReports(applicationId, accessToken) {
  let getUrl = `${applicationsUrl}/${applicationId}/reports/unassigned`;
  return await utils.getResource(getUrl, accessToken);
}
