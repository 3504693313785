import { useState, useEffect, Fragment } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  GreenGeneralButton,
  TrashButton,
  GreenSwitch,
  GreenRadio,
} from 'components/buttons/button';
import PropTypes from 'prop-types';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import {
  INPUT_TYPE_SWITCH,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_RADIOGROUP,
  INPUT_TYPE_TEXTBOX,
  INPUT_TYPE_CHECKBOXSWITCH,
} from 'utils/configuration/settings';
import { settingState } from 'utils/atoms/settings';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import style from './settingSelect.module.scss';

import { AddIcon } from 'components/icons/icons';

function updateSettings(settingIndex, settings, optItem, allSettings, opt) {
  if (settingIndex > -1) {
    const currentSetting = { ...settings[settingIndex] };

    currentSetting.option = {
      settingOptionId: optItem.settingOptionId,
      optionValue: optItem.customValue ?? optItem.optionValue,
      customValue: optItem.customValue ?? null,
    };
    allSettings[settingIndex] = currentSetting;
  } else {
    const newOption = {
      settingId: opt.settingId,
      name: opt.name,
      option: {
        settingOptionId: optItem.settingOptionId,
        optionValue: optItem.customValue ?? optItem.optionValue,
        customValue: optItem.customValue ?? null,
      },
    };

    allSettings.push(newOption);
  }
}

const SettingSelect = props => {
  const {
    setFieldValue,
    field,
    options,
    settingList,
    errors,
    setFieldTouched,
    subscriptionTypeId,
    displayfilteredSettings,
  } = props;
  const setSettings = useSetRecoilState(settingState(props.id));
  const settings = useRecoilValue(settingState(props.id));
  const [inputOptions, setInputOptions] = useState({});

  function showHideInput() {
    const inputList = options?.filter(x => x.inputType === INPUT_TYPE_TEXTBOX);

    const currentOptions = settingList
      .filter(x => inputList?.some(s => s.settingId === x.settingId))
      .map(x => x.option.settingOptionId);

    if (inputList?.length > 0) {
      let dict = {};
      inputList?.forEach(x => {
        const list = x.optionList.map((element, i) => ({
          option: {
            id: element.settingOptionId,
            show:
              currentOptions.length > 0 &&
              currentOptions.includes(element.settingOptionId)
                ? true
                : currentOptions.length === 0 && i === 0
                ? true
                : false,
          },
        }));

        dict[x.settingId] = list.sort((a, b) => b.option.show - a.option.show);
      });

      setInputOptions(dict);
    }
  }

  function getDefaults(optionsData, settingsData) {
    if (optionsData?.length > 0) {
      let defaultOptions = optionsData.filter(
        o => !settingsData?.some(s => s.settingId === o.settingId),
      );

      const withDefault = defaultOptions.filter(i =>
        i.optionList.some(x => x.isDefault === true),
      );

      if (withDefault.length > 0) {
        let defaults = withDefault.map(o => ({
          settingId: o.settingId,
          name: o.name,
          option: {
            settingOptionId: o.optionList.filter(x => x.isDefault === true)[0]
              .settingOptionId,
            optionValue: o.optionList.filter(x => x.isDefault === true)[0]
              .optionValue,
          },
        }));
        settingsData = [...settingsData, ...defaults];
        setSettings(settingsData);
        setFieldValue(field, settingsData);
      }
    }
  }

  useEffect(() => {
    setFieldValue(field, settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (settingList?.length > 0) {
      setSettings(settingList);
    }
    getDefaults(options, settingList);

    showHideInput();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingList, options]);

  useEffect(() => {
    Object.keys(errors).forEach(key => setFieldTouched(key));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  function getBoolValue(settingId) {
    const val = settings?.filter(x => x.settingId === settingId)[0]?.option
      ?.optionValue;
    return val?.toLowerCase() === 'true' ? true : false;
  }

  function getChecked(settingOptionId, optionValue) {
    const val = settings?.filter(
      x => x.option.settingOptionId === settingOptionId,
    )[0]?.option?.optionValue;
    return optionValue === val ? true : false;
  }

  function getValue(settingId) {
    const val = settings?.filter(x => x.settingId === settingId)[0]?.option
      ?.optionValue;
    return !val ? '' : val;
  }

  function getInputValue(settingOptionId) {
    const val = settings?.filter(
      x => x.option.settingOptionId === settingOptionId,
    )[0]?.option?.optionValue;
    return !val ? '' : val;
  }

  const handleSingleSelection = (id, value) => {
    const val = value.toString().toLowerCase();
    const opt = options.filter(x => x.settingId === id)[0];

    const optItem = opt?.optionList.filter(
      y => y.optionValue.toLowerCase() === val,
    )[0];

    let allSettings = [...settings];
    const settingIndex = settings.findIndex(x => x.settingId === id);

    updateSettings(settingIndex, settings, optItem, allSettings, opt);

    setSettings(allSettings);

    setFieldValue(field, allSettings);
  };

  const handleCheckboxSelection = (id, evt) => {
    const checked = evt.target.checked;

    const val = evt.target.value.toString().toLowerCase();
    const opt = options.filter(x => x.settingId === id)[0];

    const optItem = opt?.optionList.filter(
      y => y.optionValue.toLowerCase() === val,
    )[0];

    let allSettings = [...settings];
    const settingIndex = settings.findIndex(
      x => x.option.optionValue === optItem.optionValue,
    );

    if (!checked && settingIndex > -1) {
      allSettings.splice(settingIndex, 1);
    }

    if (checked) {
      updateSettings(settingIndex, settings, optItem, allSettings, opt);
    }

    setSettings(allSettings);
    setFieldValue(field, allSettings);
  };

  const handleInput = (id, optItemId, value) => {
    const val = value.toString();
    const opt = options.filter(x => x.settingId === id)[0];

    let optItem = opt?.optionList.filter(
      y => y.settingOptionId === optItemId,
    )[0];
    optItem.optionValue = val;
    optItem.customValue = val;

    let allSettings = [...settings];

    const settingIndex = settings.findIndex(
      x => x.option.settingOptionId === optItemId,
    );

    if (val === '' && settingIndex > -1) {
      allSettings.splice(settingIndex, 1);
    } else {
      updateSettings(settingIndex, settings, optItem, allSettings, opt);
    }

    setSettings(allSettings);
    setFieldValue(field, allSettings);
  };

  const handleShowChange = settingId => {
    const index = inputOptions[settingId].findIndex(
      item => item.option.show === false,
    );

    if (index > -1) {
      inputOptions[settingId][index].option.show = true;
      setInputOptions({ ...inputOptions });
    }
  };

  const handleRemoved = (settingId, optionId) => {
    const index = inputOptions[settingId].findIndex(
      item => item.option.id === optionId,
    );

    if (index > -1) {
      inputOptions[settingId][index].option.show = false;
      setInputOptions({ ...inputOptions });
      handleInput(settingId, optionId, '');
    }
  };

  return (
    <>
      {options?.length > 0 && (
        <div>
          {options?.map((opt, index) => {
            if (!displayfilteredSettings) {
              if (opt.name === 'Content Feedback' && subscriptionTypeId !== 5) {
                return <div key={opt.settingId}></div>;
              } else {
                return (
                  <div
                    className={index > 0 ? style.addPadding : ''}
                    key={opt.settingId}
                  >
                    {opt.inputType === INPUT_TYPE_SWITCH ? (
                      <Fragment>
                        <FormControlLabel
                          control={
                            <GreenSwitch
                              checked={getBoolValue(opt.settingId)}
                              onChange={e =>
                                handleSingleSelection(
                                  opt.settingId,
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          aria-label={opt.name}
                          label={
                            <Typography className={style.switchLabel}>
                              {opt.name}
                            </Typography>
                          }
                          className={style.switch}
                        />

                        <br />
                        <span className={style.description}>
                          {opt.description}
                        </span>
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_CHECKBOX ? (
                      <Fragment>
                        <div>{opt.name}</div>
                        {opt?.optionList?.map(item => {
                          return (
                            <FormControlLabel
                              key={item.settingOptionId}
                              label={item.optionValue}
                              className={style.checkboxLabel}
                              aria-label={`${opt.name} option ${item.optionValue}`}
                              control={
                                <Checkbox
                                  onChange={e =>
                                    handleCheckboxSelection(opt.settingId, e)
                                  }
                                  checked={getChecked(
                                    item.settingOptionId,
                                    item.optionValue,
                                  )}
                                  value={item.optionValue}
                                  className={style.checkbox}
                                  color="primary"
                                />
                              }
                            />
                          );
                        })}
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_CHECKBOXSWITCH ? (
                      <Fragment>
                        <FormControlLabel
                          aria-label={opt.name}
                          label={
                            <Typography className={style.switchLabel}>
                              {opt.name}
                            </Typography>
                          }
                          className={style.switch}
                          control={
                            <Checkbox
                              checked={getBoolValue(opt.settingId)}
                              onChange={e =>
                                handleSingleSelection(
                                  opt.settingId,
                                  e.target.checked,
                                )
                              }
                              className={style.checkbox}
                              color="primary"
                            />
                          }
                        />
                        <br />
                        <span className={style.checkboxDescription}>
                          {opt.helperText}
                        </span>
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_RADIOGROUP ? (
                      <Fragment>
                        <FormLabel className={style.radioGroupLabel}>
                          {opt.name}
                        </FormLabel>
                        <RadioGroup
                          value={getValue(opt.settingId)}
                          onChange={e =>
                            handleSingleSelection(opt.settingId, e.target.value)
                          }
                          className={style.radioGroup}
                        >
                          {opt?.optionList?.map(item => (
                            <FormControlLabel
                              key={item.settingOptionId}
                              value={item.optionValue}
                              label={item.optionValue}
                              control={<GreenRadio />}
                              aria-label={`${opt.name} option ${item.optionValue}`}
                            />
                          ))}
                        </RadioGroup>
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_TEXTBOX ? (
                      <>
                        {inputOptions[opt.settingId]?.map((item, i) => {
                          return (
                            <div
                              key={item.option.id}
                              className={`${style.input} ${
                                item?.option?.show.toString() === 'true'
                                  ? ''
                                  : style.hide
                              }`}
                            >
                              <div className={style.flexContainer}>
                                <div className={style.inputField}>
                                  <BaselineTextInput
                                    externalLabel={i === 0 ? opt.name : ''}
                                    isRequiredErrorMessage={false}
                                    id={opt.settingId.toString()}
                                    name={opt.settingId.toString()}
                                    margin="none"
                                    size="small"
                                    value={getInputValue(item.option.id)}
                                    onChange={e =>
                                      handleInput(
                                        opt.settingId,
                                        item.option.id,
                                        e.target.value,
                                      )
                                    }
                                    hideValidationAdornment={false}
                                    className={`${
                                      errors[opt.settingId] ? style.error : ''
                                    }${
                                      errors[opt.settingId] &&
                                      opt.description === undefined
                                        ? style.noSwap
                                        : ''
                                    }`}
                                    error={errors[opt.settingId] ? true : false}
                                  />
                                </div>

                                {i > 0 ? (
                                  <div className={style.flexChild}>
                                    <TrashButton
                                      className={style.trash}
                                      onClick={() =>
                                        handleRemoved(
                                          opt.settingId,
                                          item.option.id,
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                              {i === 0 ? (
                                <div
                                  className={`${style.helperText} ${
                                    errors[opt.settingId] &&
                                    opt.description !== undefined
                                      ? style.swap
                                      : ''
                                  }`}
                                >
                                  {opt.helperText}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                        {inputOptions[opt.settingId]?.some(
                          item => item.option.show === false,
                        ) === true ? (
                          <>
                            <GreenGeneralButton
                              className={`${style.addAnotherButton} ${
                                props.compact ? 'compact' : ''
                              }`}
                              onClick={() => {
                                handleShowChange(opt.settingId);
                              }}
                              buttonText={`Add Another ${opt.name}`}
                              startIcon={<AddIcon />}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                );
              }
            } else {
              if (subscriptionTypeId !== 5) {
                return <div key={opt.settingId}></div>;
              } else if (
                subscriptionTypeId === 5 &&
                opt.name === 'Content Feedback'
              ) {
                return (
                  <div
                    className={index > 0 ? style.addPadding : ''}
                    key={opt.settingId}
                  >
                    {opt.inputType === INPUT_TYPE_SWITCH ? (
                      <Fragment>
                        <FormControlLabel
                          control={
                            <GreenSwitch
                              checked={getBoolValue(opt.settingId)}
                              onChange={e =>
                                handleSingleSelection(
                                  opt.settingId,
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          aria-label={opt.name}
                          label={
                            <Typography className={style.switchLabel}>
                              {opt.name}
                            </Typography>
                          }
                          className={style.switch}
                        />

                        <br />
                        <span className={style.description}>
                          {opt.description}
                        </span>
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_CHECKBOX ? (
                      <Fragment>
                        <div>{opt.name}</div>
                        {opt?.optionList?.map(item => {
                          return (
                            <FormControlLabel
                              key={item.settingOptionId}
                              label={item.optionValue}
                              className={style.checkboxLabel}
                              aria-label={`${opt.name} option ${item.optionValue}`}
                              control={
                                <Checkbox
                                  onChange={e =>
                                    handleCheckboxSelection(opt.settingId, e)
                                  }
                                  checked={getChecked(
                                    item.settingOptionId,
                                    item.optionValue,
                                  )}
                                  value={item.optionValue}
                                  className={style.checkbox}
                                  color="primary"
                                />
                              }
                            />
                          );
                        })}
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_CHECKBOXSWITCH ? (
                      <Fragment>
                        <FormControlLabel
                          aria-label={opt.name}
                          label={
                            <Typography className={style.switchLabel}>
                              {opt.name}
                            </Typography>
                          }
                          className={style.switch}
                          control={
                            <Checkbox
                              checked={getBoolValue(opt.settingId)}
                              onChange={e =>
                                handleSingleSelection(
                                  opt.settingId,
                                  e.target.checked,
                                )
                              }
                              className={style.checkbox}
                              color="primary"
                            />
                          }
                        />
                        <br />
                        <span className={style.checkboxDescription}>
                          {opt.helperText}
                        </span>
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_RADIOGROUP ? (
                      <Fragment>
                        <FormLabel className={style.radioGroupLabel}>
                          {opt.name}
                        </FormLabel>
                        <RadioGroup
                          value={getValue(opt.settingId)}
                          onChange={e =>
                            handleSingleSelection(opt.settingId, e.target.value)
                          }
                          className={style.radioGroup}
                        >
                          {opt?.optionList?.map(item => (
                            <FormControlLabel
                              key={item.settingOptionId}
                              value={item.optionValue}
                              label={item.optionValue}
                              control={<GreenRadio />}
                              aria-label={`${opt.name} option ${item.optionValue}`}
                            />
                          ))}
                        </RadioGroup>
                      </Fragment>
                    ) : null}
                    {opt.inputType === INPUT_TYPE_TEXTBOX ? (
                      <>
                        {inputOptions[opt.settingId]?.map((item, i) => {
                          return (
                            <div
                              key={item.option.id}
                              className={`${style.input} ${
                                item?.option?.show.toString() === 'true'
                                  ? ''
                                  : style.hide
                              }`}
                            >
                              <div className={style.flexContainer}>
                                <div className={style.inputField}>
                                  <BaselineTextInput
                                    externalLabel={i === 0 ? opt.name : ''}
                                    isRequiredErrorMessage={false}
                                    id={opt.settingId.toString()}
                                    name={opt.settingId.toString()}
                                    margin="none"
                                    size="small"
                                    value={getInputValue(item.option.id)}
                                    onChange={e =>
                                      handleInput(
                                        opt.settingId,
                                        item.option.id,
                                        e.target.value,
                                      )
                                    }
                                    hideValidationAdornment={false}
                                    className={`${
                                      errors[opt.settingId] ? style.error : ''
                                    }${
                                      errors[opt.settingId] &&
                                      opt.description === undefined
                                        ? style.noSwap
                                        : ''
                                    }`}
                                    error={errors[opt.settingId] ? true : false}
                                  />
                                </div>

                                {i > 0 ? (
                                  <div className={style.flexChild}>
                                    <TrashButton
                                      className={style.trash}
                                      onClick={() =>
                                        handleRemoved(
                                          opt.settingId,
                                          item.option.id,
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                              {i === 0 ? (
                                <div
                                  className={`${style.helperText} ${
                                    errors[opt.settingId] &&
                                    opt.description !== undefined
                                      ? style.swap
                                      : ''
                                  }`}
                                >
                                  {opt.helperText}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                        {inputOptions[opt.settingId]?.some(
                          item => item.option.show === false,
                        ) === true ? (
                          <>
                            <GreenGeneralButton
                              className={`${style.addAnotherButton} ${
                                props.compact ? 'compact' : ''
                              }`}
                              onClick={() => {
                                handleShowChange(opt.settingId);
                              }}
                              buttonText={`Add Another ${opt.name}`}
                              startIcon={<AddIcon />}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                );
              }
            }
          })}
        </div>
      )}
    </>
  );
};

SettingSelect.prototype = {
  options: PropTypes.array.isRequired,
  settingList: PropTypes.array.isRequired,
  field: PropTypes.string,
};

export default SettingSelect;
