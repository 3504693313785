import React, { useContext, useState, useEffect } from 'react';
import SubscriptionInlineEditNotes from 'containers/organizationManagement/editOrganization/subscriptionInlineEditNotes/subscriptionInlineEditNotes';
import { useGetSubscription } from 'utils/hooks/useToCallApiEndpoints';
import { AuthContext } from 'contexts/authContext';
import { useRetrieveSubscriptionEntitlementPacket } from 'utils/hooks/useToCallApiEndpoints';
import SubscriptionDetailPanelV2 from 'components/organizationManagement/organizationManagementEditOrganization/subscriptionDetailPanel/subscriptionDetailPanelV2';
import { getSubscriptionSettings } from 'utils/api/settings';
import ModifySubscriptionProducts from 'containers/organizationManagement/modifySubscriptionProducts/modifySubscriptionProducts';

const OrganizationSubscriptionDetailPanel = props => {
  const { readOnly = false, rowData, organization, current = false } = props;
  const { accessToken } = useContext(AuthContext);

  const organizationId = current
    ? 'current'
    : organization?.organizationId ?? '';
  const { subscriptionId } = rowData ?? {};

  const { data /*, errorMessage */ } = useRetrieveSubscriptionEntitlementPacket(
    organizationId,
    subscriptionId,
  );

  //endpoint doesn't exist for 'current' at the time
  const { etag: subscriptionEtag, data: subscriptionData } = useGetSubscription(
    organization?.organizationId,
    subscriptionId,
  );

  const [settingList, setSettingList] = useState([]);

  async function getData() {
    const settingsResult = await getSubscriptionSettings(
      subscriptionId,
      accessToken,
    );
    setSettingList(settingsResult?.data);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, accessToken]);

  return (
    <>
      <SubscriptionDetailPanelV2
        readOnly={readOnly}
        entitlementPacketData={data ?? {}}
        rowData={rowData}
        organizationId={organizationId}
        settingList={settingList}
        renderSubscriptionInlineEditNotes={props => {
          return (
            <SubscriptionInlineEditNotes
              organizationToEdit={organization}
              subscriptionEtag={subscriptionEtag}
              subscriptionData={subscriptionData}
              {...props}
            />
          );
        }}
        renderSubscriptionEditConfigureProducts={props => {
          return (
            <ModifySubscriptionProducts
              {...props}
              entitlementPacketData={data ?? {}}
              settingList={settingList}
            />
          );
        }}
      />
    </>
  );
};

export default OrganizationSubscriptionDetailPanel;
