import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DelegatedAccessList from 'pages/delegates/components/delegatedAccessList/delegatedAccessList';
import AddNewDelegatedUser from 'containers/delegateManagement/addNewDelegatedUser';

import { DelegatedAccessProvider } from '../../contexts/delegatedAccessContext';
import { ReferenceSubscriptionsProvider } from 'contexts/referenceSubscriptionsContext';

const DelegatedAccessPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <DelegatedAccessProvider>
        <ReferenceSubscriptionsProvider>
          <DelegatedAccessList />
          <Switch>
            <Route
              exact
              path={`${path}/create`}
              component={AddNewDelegatedUser}
            />
            <Route
              exact
              path={`${path}/:userId`}
              component={AddNewDelegatedUser}
            />
          </Switch>
        </ReferenceSubscriptionsProvider>
      </DelegatedAccessProvider>
    </>
  );
};

export default DelegatedAccessPage;
