import React from 'react';
import { FieldArray } from 'formik';
import { List, ListItem } from '@mui/material';

import { AddItemButton, TrashButton } from 'components/buttons/button';
import { BaselineTextInput } from 'components/textInputV2/textInput';

/*
 * Display a list of logout url destinations's that the application will accept.
 */
const EditLogoutUrls = props => {
  const { setFieldValue, values } = props;

  // Before adding, we should probably validate that the value is a valid URL.
  const addLogoutUrl = (event, logoutUrl, arrayHelpers) => {
    arrayHelpers.push(logoutUrl);
    setFieldValue('newLogoutUrl', '');
  };

  return (
    <FieldArray
      name="logoutUrls"
      render={arrayHelpers => (
        <List>
          {values.logoutUrls.map((logoutUrl, index) => (
            <ListItem key={'listLU' + index}>
              <BaselineTextInput
                key={'inputLU' + index}
                name={logoutUrl}
                value={logoutUrl}
              />
              <TrashButton
                key={'removeLU' + index}
                onClick={() => arrayHelpers.remove(index)}
                aria-label="Remove Logout URI"
              />
            </ListItem>
          ))}
          <ListItem key="newLogoutUrl">
            <BaselineTextInput
              key="newLogoutItem"
              name="newLogoutUrl"
              value={values.newLogoutUrl}
            />
            <AddItemButton
              title="Add Logout Url"
              onClick={event => {
                addLogoutUrl(event, values.newLogoutUrl, arrayHelpers);
              }}
            />
          </ListItem>
        </List>
      )}
    />
  );
};

export default EditLogoutUrls;
