import React from 'react';
import { Card, CardContent } from '@mui/material';
import Organization from 'pages/organizations/components/organization/organization';
import style from './organizationListPanel.module.scss';

const OrganizationsListPanel = ({ organizations }) => {
  return (
    <ul className={style.ul}>
      {organizations.map(organization => {
        return (
          <li key={`li-${organization.organizationId}`}>
            <Card className={style.card}>
              <CardContent className={style.cardContent}>
                <Organization
                  key={organization.organizationId}
                  {...organization}
                />
              </CardContent>
            </Card>
          </li>
        );
      })}
    </ul>
  );
};

export default OrganizationsListPanel;
