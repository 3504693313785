import React, { useState, useContext } from 'react';
import { format } from 'date-fns';

import { DEFAULT_BTN_COLOR_CONFIRM_RED } from 'utils/dictionary/overview';
import { TrashButton } from 'components/buttons/button';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { Redirect } from 'react-router-dom';
import { updateOrganization } from 'utils/api/organizations';
import { AuthContext } from 'contexts/authContext';
import { DeleteIcon } from 'components/icons/icons';
import { NotificationContext } from 'contexts/notificationContext';

const DeleteOrganizationNotes = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const [open, setOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);

  const { showNotification } = React.useContext(NotificationContext);

  const { organization, noteIndex, organizationEtag, currentNote } = props;
  const { organizationId, notes } = organization;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }
  const deleteOrganizationNotes = async values => {
    const organizationNotes = {
      notes: notes.filter((note, index) => index !== noteIndex),
    };
    const { response } = await updateOrganization(
      organizationId,
      organizationNotes,
      organizationEtag,
      accessToken,
    );

    if (response?.status === 200) {
      handleClose();
      showNotification('Note deleted successfully.', false, 5000);
      setRedirect({
        isRedirect: true,
        data: { organizationId },
      });
    } else {
      showNotification(
        'Error deleting note. Please try again or contact your Healthwise developers.',
        true,
        5000,
      );
    }
  };

  const text = (
    <>
      This note from <b>{currentNote.updatedBy}</b> posted on
      {format(
        new Date(currentNote.updatedDate),
        " MMM d, yyyy 'at' h:mmaaa",
      )}{' '}
      will be removed. This action cannot be undone.
    </>
  );

  return (
    <>
      <TrashButton onClick={handleOpen} aria-label="Delete note">
        <DeleteIcon />
      </TrashButton>
      <ConfirmationModal
        open={open}
        title="Delete this note?"
        text={text}
        confirmText="Delete Note"
        handleClose={handleClose}
        handleCancel={handleClose}
        handleConfirm={deleteOrganizationNotes}
        color={DEFAULT_BTN_COLOR_CONFIRM_RED}
      />
    </>
  );
};

export default DeleteOrganizationNotes;
