import React from 'react';
import { useField } from 'formik';
import { InputAdornment, TextField, InputLabel } from '@mui/material';

import { ErrorIcon, SuccessIcon } from 'components/icons/icons';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';

import style from './textInput.module.scss';
// TODO Implement: https://healthwise.atlassian.net/wiki/spaces/DP/pages/1513095584/Text+Field

// Non-formik controlled component
export const BaselineTextField = React.forwardRef((props, ref) => {
  // Set 'our' defaults so that they can be overriden
  const {
    fullWidth = true,
    variant = 'filled',
    label,
    className,
    externalLabel,
    placeholder,
    arialabel,
    arialabelledby,
    ariadescribedby,
    tabIndex = 0,
    ...otherProps
  } = props;

  let inputProps = {
    tabIndex: tabIndex,
    'aria-label': arialabel,
    'aria-labelledby': arialabelledby,
    'aria-describedby': ariadescribedby,
  };

  //behind the scenes mui is creating a helperTextId for aria-describedby
  if (props.helperText) {
    delete inputProps['aria-describedby'];
  }

  //inputProps may exist in otherProps and needs to add it here
  if (otherProps?.inputProps) {
    let other = Object.assign(otherProps?.inputProps, inputProps);
    otherProps['inputProps'] = other;
  }

  return (
    <>
      {externalLabel ? (
        <InputLabel className={style.inputLabel} htmlFor={otherProps.id}>
          {externalLabel}
        </InputLabel>
      ) : null}
      <TextField
        inputRef={ref}
        className={`${style.muiTextField} ${className} ${style.muiWrapper}`}
        label={label}
        placeholder={placeholder}
        fullWidth={fullWidth}
        variant={variant}
        inputProps={inputProps}
        {...otherProps}
      />
    </>
  );
});

const determineEndStatusIcon = (isSubmitting, meta) => {
  if (meta.touched) {
    if (isSubmitting) {
      return (
        <InputAdornment position="start">
          <ProgressIndicator />
        </InputAdornment>
      );
    } else if (meta.error) {
      return (
        <InputAdornment position="start">
          <ErrorIcon fontSize="small" />
        </InputAdornment>
      );
    } else {
      // default success
      return (
        <InputAdornment position="start">
          <SuccessIcon fontSize="small" />
        </InputAdornment>
      );
    }
  } else {
    return null;
  }
};

const getHelperText = (meta, isRequiredErrorMessage, errorlabel) => {
  if (meta.touched && meta.error) {
    if (isRequiredErrorMessage) {
      return `${meta.error}: ${errorlabel} is a required field and must have a value`;
    } else {
      return meta.error;
    }
  }

  return null;
};

export const BaselineTextInput = props => {
  const {
    formikprops,
    className,
    errorlabel,
    isRequiredErrorMessage = true,
    hideValidationAdornment = false,
    InputProps: { endAdornment: originalEndAdornment, startAdornment } = {},
    inputProps,
    readOnly,
    ...otherProps
  } = props;

  const [field, meta] = useField(props);

  const isSubmitting = formikprops?.isSubmitting ?? false;
  const endAdornment = !hideValidationAdornment
    ? determineEndStatusIcon(isSubmitting, meta)
    : null;

  return (
    <BaselineTextField
      {...field}
      {...formikprops}
      errorlabel={errorlabel}
      inputProps={{ ...inputProps, readOnly }}
      {...otherProps}
      className={`${style.baselineTextField} ${className}`}
      error={meta.touched && meta.error ? true : false}
      helperText={getHelperText(meta, isRequiredErrorMessage, errorlabel)}
      InputProps={{
        startAdornment,
        endAdornment: (
          <>
            {endAdornment || null}
            {originalEndAdornment || null}
          </>
        ),
      }}
    />
  );
};
