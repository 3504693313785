import React from 'react';
import { Grid } from '@mui/material';

import { BaselineTextInput } from 'components/textInputV2/textInput';
import AccountLifespanFields from 'components/users/accountLifespanFields/accountLifespanFields';

import style from './userDetails.module.scss';

/**
 * The user fields provide a common arrangement of the requested user details
 * with some logic baked in. UserFields is just the raw arrangement of the
 * fields without styling or containering.
 */
export const UserFields = props => {
  const {
    startDate,
    endDate,
    minimumEndDate,
    maximumStartDate,
    setStartDate,
    setEndDate,
    createUser,
    status,
    initialValues,
  } = props;

  return (
    <div>
      <Grid container className={style.fieldRow}>
        <Grid item xs>
          <BaselineTextInput
            id="firstName"
            externalLabel="First Name"
            errorlabel="First Name"
            name="firstName"
            variant="standard"
          />
        </Grid>
        <Grid item xs>
          <BaselineTextInput
            id="lastName"
            externalLabel="Last Name"
            errorlabel="Last Name"
            name="lastName"
            variant="standard"
          />
        </Grid>
      </Grid>
      {createUser ? (
        <Grid container className={style.fieldRow}>
          <Grid item xs>
            <BaselineTextInput
              id="email"
              externalLabel="Email Address"
              errorlabel="Email Address"
              name="email"
              variant="standard"
            />
          </Grid>
        </Grid>
      ) : null}
      <AccountLifespanFields
        className={style.fieldRow}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        minimumEndDate={minimumEndDate}
        maximumStartDate={maximumStartDate}
        status={status}
        initialValues={initialValues}
        textProps={{
          variant: 'standard',
        }}
      />
    </div>
  );
};

/**
 * UserDetails provides the common arrangement of the requested user details
 *  wrapped in a card for ease of use and styling.
 */
export const UserDetails = props => {
  return (
    <>
      {props.creating ? (
        <>
          <p className={style.cardHeadline}>1. User Details</p>
          <p className={style.cardDescription}>
            In order to create a new user, you need to provide their email
            address and assign user roles. The user will receive an invitation
            to log in.
          </p>
        </>
      ) : null}
      <UserFields {...props} />
    </>
  );
};

export default UserDetails;
