import { ADVISE, COMPASS } from 'utils/dictionary';

export const getAssetsToProvision = assets => {
  return Object.entries(assets)
    .map(asset => {
      const [key, value] = asset;
      const { add } = value;
      return {
        [key]: {
          add: add?.map(item => {
            const { concepts } = item;
            if (concepts) {
              return { ...item, concepts: [] };
            } else {
              return item;
            }
          }),
        },
      };
    })
    .reduce((assetsObj, asset) => {
      const [key, value] = Object.entries(asset)[0];
      assetsObj[key] = value;
      return assetsObj;
    }, {});
};

export const getApplicationsToProvision = (module, licenseApplications) => {
  const { applications, solutionLevel, id: moduleId } = module;
  return applications?.map(({ id, version, name, features }) => {
    const { base } = features;
    let optional = [];

    let app = licenseApplications.find(x => x.id === id);

    if (app) {
      optional = features?.optional.filter(opt =>
        app?.features?.optional.some(x => x.id === opt.id),
      );
    }
    const applicationCredentialId = app?.applicationCredentialId ?? '';
    return buildApplication(
      id,
      version,
      name,
      solutionLevel,
      moduleId,
      optional,
      base,
      applicationCredentialId,
    );
  });
};

export const getSelectedApplications = applications => {
  return Object.values(applications ?? {})?.filter(app => app !== null) ?? [];
};

export const getSelectedAssets = assets => {
  return Object.entries(assets ?? {}).reduce((assetsObj, asset) => {
    const [key, value] = asset;
    if (value !== null) {
      assetsObj[key] = value;
    }
    return assetsObj;
  }, {});
};

export const getSelectedApplicationsIds = selectedApplications =>
  selectedApplications?.map(app => app.id);

export const getApplicationsToProvisionWithoutSelectedApplication = (
  applicationsToProvision,
  selectedApplicationsIds,
) => {
  return applicationsToProvision.filter(
    app => !selectedApplicationsIds.includes(app.id),
  );
};

export const getSelectedModuleApplications = modules => {
  return Object.values(modules ?? {}).reduce((appArray, app) => {
    return appArray.concat(Object.values(app));
  }, []);
};

export const getSelectedAvailableModules = (
  licensedApplications,
  availableModules,
) => {
  let appModules = licensedApplications.flatMap(x => x.modules[0].id);

  return availableModules
    .filter(x => appModules.includes(x.id))
    .reduce((moduleObj, module) => {
      const { id: moduleId } = module;

      const availApps = getApplicationsToProvision(
        module,
        licensedApplications,
      );
      if (availApps.length > 0) {
        moduleObj = buildModule(availApps, moduleObj, moduleId, module);
        return moduleObj;
      }
      return {};
    }, {});
};

export const getSelectedAvailableAssets = (assets, availableAssets) => {
  return Object.entries(assets ?? {}).reduce((assetsObj, asset) => {
    const [key, value] = asset;
    const availValue = availableAssets[key];

    if (value !== null && availValue) {
      value.add[0].concepts = availValue.add[0].concepts.filter(function (o1) {
        return value.add[0].concepts.some(function (o2) {
          return o1.conceptId === o2.conceptId;
        });
      });
      assetsObj[key] = value;
    }
    return assetsObj;
  }, {});
};

export const getPreSelectedAvailableModules = availableModules => {
  return availableModules.reduce((moduleObj, module) => {
    const { applications, id: moduleId, solutionLevel } = module;

    let availApps = applications?.map(app => {
      const { id, version, name, features, applicationCredentialId } = app;
      const { base, optional } = features;

      return buildApplication(
        id,
        version,
        name,
        solutionLevel,
        moduleId,
        optional,
        base,
        applicationCredentialId,
      );
    });

    return buildModule(availApps, moduleObj, moduleId, module);
  }, {});
};

function buildApplication(
  id,
  version,
  name,
  solutionLevel,
  moduleId,
  optional,
  base,
  applicationCredentialId,
) {
  return {
    id,
    version: version ?? '1',
    name,
    solutionLevel,
    ...(id === ADVISE && {
      applicationCredentialId: applicationCredentialId ?? '',
    }),
    modules: [
      {
        id: moduleId,
      },
    ],
    features: {
      optional,
      base,
    },
  };
}

function buildModule(availApps, moduleObj, moduleId, module) {
  let apps = availApps.map(app => {
    const { id } = app;
    return {
      [id]: {
        ...app,
      },
    };
  });

  const combineApps = Object.assign({}, ...apps);

  if (apps) {
    moduleObj[moduleId] = {
      ...module[moduleId],
      ...combineApps,
    };
  }

  return moduleObj;
}

export const getRedoxIdForSelectedModules = modules => {
  const apps = getSelectedModuleApplications(modules);

  const found = apps.find(app => app.id === ADVISE);

  return {
    found: found ?? false,
    redoxId: found?.applicationCredentialId ?? '',
  };
};

export const getRedoxId = licenseApplications => {
  const found = licenseApplications?.find(app => app.id === ADVISE);
  return {
    found: found ?? false,
    redoxId: found?.applicationCredentialId ?? '',
  };
};

//V2 -------------------------------

export const getRedoxIdForSelectedModulesV2 = modules => {
  const apps = getSelectedModuleApplications(modules);

  const found = apps.find(app => app.sourceId === ADVISE);

  return {
    found: found ?? false,
    redoxId: found?.applicationCredentialId ?? '',
  };
};

export const getRedoxIdV2 = licensedApplications => {
  const found = licensedApplications?.find(app => app.sourceId === ADVISE);
  return {
    found: found ?? false,
    redoxId: found?.applicationCredentialId ?? '',
  };
};

export const getPreSelectedAvailableModulesV2 = (
  availableModules,
  availableApplicationAssets,
) => {
  return availableModules.reduce((moduleObj, module) => {
    const { applications, id: moduleId } = module;

    let availApps = applications?.map(app => {
      const { id, name, features, applicationCredentialId } = app;
      const { optional } = features;

      const content = availableApplicationAssets[id];

      return buildApplicationV2(
        id,
        name,
        moduleId,
        optional,
        applicationCredentialId,
        content,
      );
    });

    return buildModuleV2(availApps, moduleObj, moduleId, module);
  }, {});
};

function buildApplicationV2(
  id,
  name,
  moduleId,
  optional,
  applicationCredentialId,
  content,
) {
  return {
    sourceId: id,
    name,
    ...(id === ADVISE && {
      applicationCredentialId: applicationCredentialId ?? '',
    }),
    module: moduleId,
    features: buildFeatures(optional),
    content: buildContent(content),
  };
}

function buildModuleV2(availApps, moduleObj, moduleId) {
  moduleObj[moduleId] = availApps;

  return moduleObj;
}

function buildFeatures(features) {
  let featureArray = [];
  features?.forEach(element => {
    featureArray.push({
      featureId: element.id,
      featureName: element.name,
    });
  });

  return featureArray;
}

function buildContent(content) {
  let contentArray = [];
  content?.forEach(element => {
    contentArray.push({
      assetId: element.conceptId,
      localizations: element.localizations,
    });
  });

  return contentArray;
}

export const isCompassSelected = modules => {
  const apps = getSelectedModuleApplications(modules);
  const found = apps.find(app => app.sourceId === COMPASS);
  return {
    found: found ?? false,
  };
};
