import * as Yup from 'yup';

export const editSubscriptionSchema = Yup.object().shape({
  subscriptionName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
});

export const editSubscriptionFormInitialValues = (subscriptionName = '') => {
  return {
    subscriptionName,
  };
};

export const OrganizationSubscriptionSchemaDefinition = {
  subscriptionName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  selectEnvironment: Yup.object().required('Select an Environment').nullable(),
  friendlyId: Yup.string()
    .strict()
    .required()
    .min(1)
    .max(15)
    .matches(
      /^[a-z]/,
      'Unique Content Identifier must start with a lowercase letter',
    )
    .matches(
      /^[a-z0-9_]+$/,
      'Unique Content Identifier must only contain lowercase letters, numbers, and underscores',
    )
    .matches(
      /^[a-z][a-z0-9_]{0,14}$/g,
      'Unique Content Identifier is not in the correct format',
    )
    .label('Unique Content Identifier'),
};

export const getCustomValidationSchema = schemaObject => {
  return Yup.object().shape(schemaObject);
};

export const OrganizationSubscriptionSchema = getCustomValidationSchema(
  OrganizationSubscriptionSchemaDefinition,
);

export const FormInitialValues = (subscriptionName = '', selectEnvironment) => {
  return {
    subscriptionName,
    selectEnvironment,
    friendlyId: '',
    alias: '',
  };
};
