import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'contexts/authContext';

import { getOrganizationAdministrators } from 'utils/api/organizations';

/**
 *  @property {Object} data Container for the organization administrators retrieved
 *  @property {Object} error populated with any errors seen during the query
 */

/**
 * Retrieve the organization administrators for the user calling this function. *
 * @param {number} [skip=0] - How many organization adminstrators to skip before starting to retrieve them
 * @param {number} [top=3] - How many organization administrators to return

 */
export const useRetrieveOrganizationAdministrators = (skip = 0, top = 3) => {
  const { accessToken, organizationId, loading } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const response = async () => {
      const { data, error } = await getOrganizationAdministrators(
        organizationId,
        accessToken,
        skip,
        top,
      );
      setData(data);
      setErrorMessage(error);
    };

    if (!loading) {
      response();
    }
  }, [top, skip, accessToken, organizationId, loading]);

  return { data, errorMessage };
};
