import React, { useRef, useContext, useState, useEffect } from 'react';

import { Formik } from 'formik';
import {
  FormInitialValues,
  ModifySubscriptionConfigureProductsSchema,
} from 'components/organizationManagement/modifySubscriptionProductsModal/formSchemaAndInitialValues';
import { provision, validateCredential } from 'utils/api/provisioning';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import {
  getPreSelectedAvailableModulesV2,
  getRedoxIdForSelectedModulesV2,
  isCompassSelected,
} from 'containers/organizationManagement/editSubscriptionConfigureProducts/utils';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { NotificationContext } from 'contexts/notificationContext';
import ModifySubscriptionProductsModal from 'components/organizationManagement/modifySubscriptionProductsModal/modifySubscriptionProductsModal';
import { SUBSCRIPTION, ADVISEINTERNAL } from 'utils/configuration/settings';
import { getSubscriptionOptions } from 'utils/api/settings';
import { PageLink } from 'components/pageLink/pageLink';
import { ArrowBackIcon } from 'components/icons/icons';
import { FeatureToggle } from 'react-feature-toggles/lib';
import { FEATURE_TOGGLE_ADDITIONAL_SETTING } from 'utils/dictionary/featureToggles';
import ModifySubscription from 'components/organizationManagement/modifySubscriptionProductsModal/modifySubscription';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { useHistory } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

const ModifySubscriptionProducts = props => {
  const closeModal = useRef({ close: false });
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const { showNotification } = React.useContext(NotificationContext);
  const [status, setStatus] = useState(null);
  const [validate, setValidate] = useState(false);
  const [redoxError, setRedoxError] = useState({});

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [adviseOptions, setAdviseOptions] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const formRef = useRef();

  const {
    organizationId,
    rowData = {},
    entitlementPacketData,
    settingList,
  } = props ?? {};
  const { subscriptionId, subscriptionName } = rowData;

  const history = useHistory();

  const appSettingsFlag =
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_ADDITIONAL_SETTING) === true;

  const {
    entitlementPacket = {},
    subscriptionLicense = {},
    licensedApplications = {},
    availableApplicationAssets = {},
    compassCredential = {},
  } = entitlementPacketData ?? {};

  //Entitlement Packet
  const { licensing = {} } = entitlementPacket;
  const { packages = [] } = licensing;

  //Subscription License
  const { licenseKeyId } = subscriptionLicense;

  const moduleFilter =
    rowData?.subscriptionTypeId === 5 // If it's a management subscription
      ? module => module.isPlatform ?? true // Then only Platform Module
      : module => !(module.isPlatform ?? false); // Otherwise, other Modules

  const availableModules = packages
    ?.flatMap(item => item?.modules)
    .filter(a => a.applications.length !== 0)
    .filter(moduleFilter);

  const availableModuleIds = availableModules.map(x => x.id);

  let selectedModules = Object.fromEntries(
    Object.entries(licensedApplications).filter(([key, val]) =>
      availableModuleIds.includes(key),
    ),
  );

  if (Object.keys(selectedModules).length === 0) {
    selectedModules = getPreSelectedAvailableModulesV2(
      availableModules,
      availableApplicationAssets,
      compassCredential,
    );
  }
  const handleReset = () => {
    setRedirect({ isRedirect: true, data: { organizationId } });
  };
  const { success, message = null, date } = status ?? {};

  async function getData() {
    const optionsResult = await getSubscriptionOptions(
      SUBSCRIPTION,
      accessToken,
    );

    setSubscriptionOptions(optionsResult.data);

    if (rowData?.subscriptionTypeId !== 5) {
      const adviseResult = await getSubscriptionOptions(
        ADVISEINTERNAL,
        accessToken,
      );
      setAdviseOptions(adviseResult.data);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message) {
      showNotification(message, !success, 5000, date);
      status.message = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, message, date]);

  const validateAppSettings = valSettings => {
    if (appSettingsFlag) {
      const adviseSettings = adviseOptions.map(x => x.settingId);
      const currentSettings = settingList
        .filter(x => adviseSettings.includes(x.settingId))
        .map(x => x.settingId);
      const changedSettings = valSettings.map(x => x.settingId);

      let settings = changedSettings;
      if (currentSettings.length > 0) {
        settings = currentSettings.filter(item =>
          changedSettings.includes(item),
        );
      }

      if (
        adviseSettings.length === currentSettings.length &&
        settings.length === 0
      )
        return {};

      const missing = adviseOptions
        .filter(x => !settings.includes(x.settingId))
        .reduce(
          (obj, cur) => ({
            ...obj,
            [cur.settingId]: `${cur.name} is required and must have a value`,
          }),
          {},
        );

      return missing;
    }
    return {};
  };

  const verifyCredential = async (modules, setFieldError) => {
    const found = getRedoxIdForSelectedModulesV2(modules);
    if (found && found.redoxId !== '') {
      const { error } = await validateCredential(
        subscriptionId,
        found.redoxId,
        accessToken,
      );

      if (error) {
        setRedoxError({ applicationCredentialId: error.details.error.message });
        setFieldError({ applicationCredentialId: error.details.error.message });
        return false;
      }
      setRedoxError({});
      return true;
    }
    setRedoxError({});
    return true;
  };

  const handleClose = () => {
    setConfirmOpen(false);
    history.push({
      pathname: EDIT_ORGANIZATION_PATH,
      search: window.location.search,
      state: {
        organizationId: organizationId,
      },
    });
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const editConfigureSubscriptionProducts = async (
    values,
    { resetForm, setFieldError },
  ) => {
    const valid = await verifyCredential(values.modules, setFieldError);

    if (valid) {
      const { validFrom, validTo } = entitlementPacket;

      const applications = Object.values(values?.modules).flatMap(x => x);

      const ProvisioningDto = {
        licenseKeyId: licenseKeyId ?? null,
        organizationId: organizationId,
        subscriptionId: subscriptionId,
        validFrom: validFrom,
        validTo: validTo,
        applications: applications,
        subscriptionSettings: values.settings,
        compassCredential: {
          name: values?.compassKeyName,
          description: values?.compassKeyDescription,
        },
      };

      const { response, error } = await provision(
        organizationId,
        subscriptionId,
        ProvisioningDto,
        accessToken,
      );

      switch (response?.status) {
        case 201:
          setStatus({
            success: true,
            message: `Successfully modified ${subscriptionName}`,
            date: Date.now(),
          });
          closeModal.current = { close: true }; //Conditional to close modal in the component
          resetForm(FormInitialValues(selectedModules));
          setRedirect({ isRedirect: true, data: { organizationId } });
          break;
        case 400:
          if (
            error.details.error.code === 'MalformedProvisionRequestException'
          ) {
            setStatus({
              success: false,
              message:
                'Error modifying subscription. Please try again or contact your Healthwise developers.',
              date: Date.now(),
            });
          } else if (error.details.error.code === 'CompositeError') {
            //TODO: Display specific error to the user
            setStatus({
              success: false,
              message: `${error.details.error.message}. Please refresh the page.`,
              date: Date.now(),
            });
          } else {
            setStatus({
              success: false,
              message: 'Bad request. Please try again.',
              date: Date.now(),
            });
          }
          break;
        case 404:
          setStatus({
            success: false,
            message: 'Not found.',
            date: Date.now(),
          });
          break;
        case 500:
          setStatus({
            success: false,
            message:
              'Error modifying subscription. Please try again or contact your Healthwise developers.',
            date: Date.now(),
          });
          break;
        default:
          if (!response) {
            setStatus({
              success: false,
              message: 'Network error.',
              date: Date.now(),
            });
          } else {
            setStatus({
              success: false,
              message: 'Unknown error.',
              date: Date.now(),
            });
          }
          break;
      }
    }
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={FormInitialValues(selectedModules, [])}
      validateOnBlur={false}
      validateOnChange={validate}
      validationSchema={ModifySubscriptionConfigureProductsSchema(
        rowData?.subscriptionTypeId,
      )}
      onSubmit={editConfigureSubscriptionProducts}
      validate={values => {
        setValidate(true);
        const required = validateAppSettings(values.settings);
        const found = getRedoxIdForSelectedModulesV2(values.modules);
        let redoxRequired = {};
        if (found.found && found.redoxId === '') {
          redoxRequired = {
            applicationCredentialId: 'A Redox ID is required to continue.',
          };
        }

        if (Object.keys(redoxError).length > 0) {
          redoxRequired = redoxError;
        }
        Object.assign(required, redoxRequired);

        if (Object.keys(required).length > 0) {
          setConfirmOpen(false);
        }

        let compassRequired = {};
        if (isCompassSelected(values.modules).found) {
          if (values?.compassKeyName === undefined) {
            compassRequired = {
              compassKeyName: 'An API Key name is required.',
            };
            Object.assign(required, compassRequired);
          }
        }

        return required;
      }}
      children={props => {
        return (
          <>
            <FeatureToggle featureName={FEATURE_TOGGLE_ADDITIONAL_SETTING}>
              <PageLink
                icon={<ArrowBackIcon />}
                text="Back to Previous Page"
                to={{
                  pathname: EDIT_ORGANIZATION_PATH,
                  search: window.location.search,
                  state: {
                    organizationId,
                  },
                }}
                handleClick={e => {
                  if (
                    Object.keys(props.touched).length > 0 ||
                    props.values.settings.length > 0
                  ) {
                    e.preventDefault();
                    setConfirmOpen(true);
                  }
                }}
              />
              {confirmOpen && props.dirty ? (
                <ConfirmationModal
                  open={confirmOpen}
                  title="Save Changes?"
                  text="You have unsaved changes that will be lost. Do you want to save changes before leaving this page?"
                  confirmText="Save"
                  handleClose={handleClose}
                  handleCancel={handleClose}
                  handleConfirm={handleSubmit}
                  cancelText="Discard Changes"
                />
              ) : null}
              <RecoilRoot>
                <ModifySubscription
                  {...props}
                  selectedModules={selectedModules}
                  licensedApplications={licensedApplications}
                  availableAssets={availableApplicationAssets}
                  availableModules={availableModules}
                  subscription={rowData}
                  subscriptionOptions={subscriptionOptions}
                  settingList={settingList}
                  adviseOptions={adviseOptions}
                  setRedoxError={setRedoxError}
                />
              </RecoilRoot>
            </FeatureToggle>
            <FeatureToggle
              featureName={FEATURE_TOGGLE_ADDITIONAL_SETTING}
              showOnlyWhenDisabled={true}
            >
              <RecoilRoot>
                <ModifySubscriptionProductsModal
                  {...props}
                  modalButtonText="Modify Subscription"
                  closeModal={closeModal}
                  selectedModules={selectedModules}
                  licensedApplications={licensedApplications}
                  availableAssets={availableApplicationAssets}
                  availableModules={availableModules}
                  subscription={rowData}
                  resetFormOnClose={handleReset}
                  subscriptionOptions={subscriptionOptions}
                  settingList={settingList}
                  compassCredential={compassCredential}
                />
              </RecoilRoot>
            </FeatureToggle>
          </>
        );
      }}
    />
  );
};

export default ModifySubscriptionProducts;
