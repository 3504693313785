import React from 'react';
import {
  Chip,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Field } from 'formik';
import { BaselineTextField } from 'components/textInputV2/textInput';
import { CloseIcon } from 'components/icons/icons';
import style from './multipleSelectInput.module.scss';

// TODO Rename 'selectedValue' to pluralize it.
export const MultipleSelectInput = props => {
  const {
    name,
    optionsList,
    touched,
    onChange,
    selectedValue,
    onBlur,
    error,
    errorMsg,
    label,
    helpMsg,
    disabled = false,
    fieldHelperText = () => {
      return <></>;
    },
    filterSelectedOptions = true,
    fullWidth = true,
    disableClearable = false,
    disableCloseOnSelect = false,
    size = 'small',
  } = props;

  const handleChange = (event, value, eventName) => {
    onChange(name, value);
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  return (
    <Field
      name={name}
      value={selectedValue}
      component={Autocomplete}
      multiple
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      options={optionsList}
      getOptionLabel={option => option.value}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id;
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      size={size}
      filterSelectedOptions={filterSelectedOptions}
      helpertext={fieldHelperText}
      disabled={disabled}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            label={option.value}
            {...getTagProps({ index })}
            deleteIcon={<CloseIcon fontSize="small" />}
          />
        ));
      }}
      renderInput={params => {
        return (
          <BaselineTextField
            {...params}
            label={label}
            fullWidth
            helperText={touched && !!error ? errorMsg : helpMsg}
            error={!!errorMsg && touched}
            onBlur={handleBlur}
          />
        );
      }}
    />
  );
};

// TODO: Convert to use MultipleSelectInput instead.
//        Note: The way this input is used, value is required to be that name.
export const OrganizationManagementMultipleSelectInput = props => {
  const {
    handleChange,
    handleBlur,
    name,
    options,
    touched,
    errorMsg,
    label,
    defaultValue,
    disabled = false,
    wrapperObject,
    getOptionSelected,
    value,
    key,
    filterSelectedOptions = true,
  } = props;

  const wrapChange = (event, value) => {
    if (wrapperObject) {
      let obj = null;
      if (value?.length >= 1) {
        obj = wrapperObject(value);
      }
      handleChange(name, obj);
    } else {
      handleChange(name, value);
    }
  };

  const wrapBlur = () => {
    handleBlur(name, true);
  };

  return (
    options.length > 0 && (
      <>
        <Autocomplete
          key={key}
          multiple
          id={name}
          size="small"
          disabled={disabled}
          options={options ?? []}
          getOptionLabel={option => option.name}
          defaultValue={defaultValue ?? []}
          disableCloseOnSelect
          onChange={wrapChange}
          isOptionEqualToValue={getOptionSelected}
          filterSelectedOptions={filterSelectedOptions}
          value={value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                deleteIcon={<CloseIcon fontSize="small" />}
              />
            ))
          }
          renderInput={params => (
            <BaselineTextField
              {...params}
              label={label}
              fullWidth
              helperText={touched && !!errorMsg ? errorMsg : null}
              error={!!errorMsg && touched ? true : false}
              onBlur={wrapBlur}
            />
          )}
        />
      </>
    )
  );
};

export const ApplicationRoleSelectorSelectInput = props => {
  const {
    optionsList,
    value = [],
    subscriptionId,
    applicationId,
    handleRoleChanged,
    externalLabel,
    row,
  } = props;

  const rowRoles = document.getElementsByName(row.id);
  const error = !Array.from(rowRoles).some(role => role.checked);

  if (!error) {
    rowRoles.forEach(ele => ele.removeAttribute('required'));
  }

  const handleCheckboxChange = event => {
    const checkedNodeList = document.querySelectorAll(
      'input[type="checkbox"]:checked',
    );
    const checkedElements = {};
    checkedNodeList.forEach(item => (checkedElements[item.value] = true));
    let newChecked = optionsList.filter(option => checkedElements[option.id]);

    handleRoleChanged(newChecked, subscriptionId, applicationId);
  };

  return (
    <FormControl
      required
      className={style.formControl}
      error={error}
      component="fieldset"
    >
      <legend
        className={style.rolesLegend}
        aria-label={
          error ? 'please select at least one application role' : null
        }
      >
        {externalLabel}
      </legend>
      {optionsList.length ? (
        <div className={style.formGroup}>
          {optionsList.map((option, i) => {
            //adding a space in btw camel cased names
            let name = option.name.split(/([A-Z][a-z]+)/g).join(' ');
            return (
              <FormControlLabel
                key={i}
                label={name}
                className={style.checkboxLabel}
                control={
                  <Checkbox
                    onChange={handleCheckboxChange}
                    name={row.id}
                    checked={value.some(v => v.id === option.id)}
                    value={option.id}
                    className={style.checkbox}
                    color="primary"
                  />
                }
              />
            );
          })}
        </div>
      ) : (
        <div className={style.noRoles}>
          <p>Add a role</p>
        </div>
      )}
    </FormControl>
  );
};
