import React, { useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'formik';
import {
  Divider,
  Dialog,
  DialogContent,
  DialogSectionTitle,
  DialogSubSectionText,
  DialogTitle,
  DialogActions,
} from 'components/dialog/dialog';
import {
  GeneralCancelButton,
  GreenGeneralButton,
  CloseIconButton,
} from 'components/buttons/button';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import ToolTip from 'components/tooltipV2/tooltip';
import { SubscriptionSelector } from 'components/subscriptionSelectorV2/subscriptionSelector';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';
import { Typography } from 'components/typography/typography';
import style from './delegatedAccessUserModal.module.scss';
import { AuthContext } from 'contexts/authContext';

const DelegatedAccessAddNewUserModal = props => {
  const {
    isValid,
    dirty,
    isSubmitting,
    setFieldValue,
    touched,
    errors,
    initialValues,
    values,
    setFieldTouched,
    delegatedUserOptions,
  } = props;
  let history = useHistory();

  const { organizationAlias } = useContext(AuthContext);

  const handleClose = () => {
    // if the form is dirty, lets prompt the user that they will lose changes if they click discard changes
    history.push({
      pathname: `/${organizationAlias}/delegates`,
      search: window.location.search
    });
  };

  const closeRef = useRef(null);
  useAddFocus(closeRef, true);

  const modifyingUser = initialValues?.selectUser?.id ?? false;
  const modifyingUserText = 'You are currently editing permissions for ';
  const addUserText =
    'By completing this form, you are granting a Healthwise employee access to your organization’s data. You can revoke this user’s access at any time.';

  return (
    <Dialog
      role="dialog"
      aria-labelledby="dialog-title"
      open
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      transitionDuration={500}
      scroll={'body'}
    >
      <CloseIconButton
        onClick={handleClose}
        className={style.closeButton}
        ref={closeRef}
      />
      <Form noValidate>
        {!modifyingUser ? (
          <>
            <DialogTitle id="dialog-title">Add New Delegate</DialogTitle>
            <div className={style.warningBanner}>
              <Typography className={style.warningTextTypography}>
                {addUserText}
              </Typography>
            </div>
            <DialogContent>
              <DialogSectionTitle>
                1. Select a Healthwise Employee
              </DialogSectionTitle>

              <div
                className={`${style.itemGrid} ${style.rowField} ${
                  values.selectUser?.id ? style.itemGridRowFieldPopulated : ''
                }`}
              >
                <SingleSelectInput
                  name="selectUser"
                  externalLabel={
                    values.selectUser?.id ? '' : 'Healthwise Employees'
                  }
                  placeholder={'Add Healthwise Employee'}
                  variant="outlined"
                  optionsList={delegatedUserOptions}
                  disabled={!delegatedUserOptions || !delegatedUserOptions.length}
                  touched={touched['selectUser']}
                  handleChange={setFieldValue}
                  handleBlur={setFieldTouched}
                  error={errors['selectUser']}
                  errorMsg={errors['selectUser']?.id}
                  helpMsg=""
                  selectedValue={values.selectUser}
                  disableCloseOnSelect={false}
                  disableClearable={true}
                />
              </div>

              <Divider />
              <DialogSectionTitle>
                2. Assign Subscriptions and Roles{' '}
                <ToolTip
                  title="To grant a Healthwise employee access to your products, you need to assign them to a subscription and application. Subscriptions are containers used to manage and isolate your Healthwise resources in different environments like production or test."
                  buttonSize="medium"
                />
              </DialogSectionTitle>
              <DialogSubSectionText>
                At least one Subscription, Application, and Role must be
                assigned to grant delegated access.
              </DialogSubSectionText>
              <div className={style.itemGrid}>
                <SubscriptionSelector
                  compact={true}
                  allowMultiple={true}
                  {...props}
                />
              </div>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle id="dialog-title">Edit Delegate Access</DialogTitle>
            <div className={style.warningBanner}>
              <Typography className={style.warningTextTypography}>
                {modifyingUserText}
                <strong>{initialValues?.selectUser?.name}</strong>.
              </Typography>
            </div>
            <div className={style.message}>
              <p>
                At least one Subscription, Application, and Role must be
                assigned to enable delegated access.
              </p>
            </div>
            <DialogContent>
              <div className={style.itemGrid}>
                <SubscriptionSelector
                  compact={true}
                  allowMultiple={true}
                  {...props}
                />
              </div>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <GeneralCancelButton onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </GeneralCancelButton>
          <GreenGeneralButton
            variant="contained"
            type="submit"
            disabled={!(isValid && dirty) || isSubmitting}
            buttonText={modifyingUser ? 'Save Changes' : 'Grant Access'}
          ></GreenGeneralButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default DelegatedAccessAddNewUserModal;
