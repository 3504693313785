import React from 'react';
import { Form, useField } from 'formik';
import Notification from 'components/notificationV2/notification';
import { GreenGeneralButton } from 'components/buttons/button';
import { BaselineTextField } from 'components/textInputV2/textInput';
import style from './organizationNotes.module.scss';

const NoteTextField = props => {
  const [field, meta] = useField(props);
  return <BaselineTextField {...props} {...field} error={!!meta.error} />;
};

const OrganizationNotes = props => {
  const {
    isSubmitting,
    status,
    values: { note },
  } = props;
  const { success, message = '' } = status ?? {};

  let charsLeft = (2000 - note.length).toString();
  if (charsLeft > 0 && charsLeft.length === 4) {
    charsLeft = `${charsLeft.substring(0, 1)},${charsLeft.substring(1)}`;
  } else if (charsLeft < 0 && charsLeft.length === 5) {
    charsLeft = `${charsLeft.substring(0, 2)},${charsLeft.substring(2)}`;
  }

  return (
    <>
      <Form className={style.orgNotesform}>
        <NoteTextField
          name="note"
          id="note"
          multiline
          minRows="7"
          fullWidth
          placeholder="Add your note here…"
          aria-label="Add your note here…"
          className={style.addNoteTextField}
        />
        <div className={style.addNoteFooter}>
          <span>Max 2,000 Characters</span>
          <div className={style.saveNoteBtn}>
            <span
              className={`${style.charCount} ${
                charsLeft < 0 ? style.redText : null
              }`}
            >
              {charsLeft}
            </span>
            <GreenGeneralButton
              type="submit"
              disabled={isSubmitting || charsLeft < 0}
              buttonText="Add Note"
            />
          </div>
        </div>
      </Form>
      {success === false ? (
        <Notification error duration={3000} message={message} />
      ) : null}
    </>
  );
};

export default OrganizationNotes;
