import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogSubSectionText,
  DialogSectionTitle,
} from 'components/dialog/dialog';
import {
  GeneralCancelButton,
  GreenGeneralButton,
  CloseIconButton,
} from 'components/buttons/button';
import { SettingsIcon } from 'components/icons/icons';
import ModuleSelection from 'components/organizationManagement/modifySubscriptionProductsModal/moduleSelection';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import style from './modifySubscriptionProductsModal.module.scss';
import SettingSelect from '../../settingSelect/settingSelect';
import { FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG } from 'utils/dictionary/featureToggles';

const ModuleSection = props => {
  const [checked, setChecked] = useState({});

  const { selectedModules, filteredModules, setFieldValue, setFieldTouched } =
    props;

  useEffect(() => {
    Object.keys(selectedModules).forEach(moduleId => {
      setChecked(currentState => {
        return {
          ...currentState,
          [moduleId]: { checked: true },
        };
      });
    });
  }, [setChecked, selectedModules]);

  return (
    <>
      {filteredModules.map((module, index) => {
        return (
          <Fragment key={index}>
            <ModuleSelection
              {...props}
              selectedModules={selectedModules}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              module={module}
              setChecked={setChecked}
              checked={checked}
              name="modules"
            />
          </Fragment>
        );
      })}
    </>
  );
};

const ModifySubscriptionProductsModal = props => {
  const {
    modalButtonText,
    closeModal,
    isValid,
    isSubmitting,
    subscription,
    resetFormOnClose,
    errors,
    touched,
    subscriptionOptions,
    settingList,
  } = props;

  const displayfilteredSettings =
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG) === true;

  const filteredSubscriptionOptions = subscriptionOptions
    .map(sub => {
      return {
        ...sub,
      };
    })
    .filter(sub => {
      return sub.restricted === true; //&& subscription?.subscriptionTypeId === 5; //"Content Feedback setting"
    });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetFormOnClose();
  };

  const moduleFilter =
    subscription?.subscriptionTypeId === 5 // If it's a management subscription
      ? module => module.isPlatform ?? true // Then show only Platform Modules
      : module => !(module.isPlatform ?? false); // Otherwise, hide only Platform Modules

  if (closeModal.current.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  const closeRef = useRef(null);
  useAddFocus(closeRef, open);

  return (
    <>
      <Button onClick={handleOpen}>
        <SettingsIcon style={{ fill: '#0D8484' }} />
        <Typography className={style.modalButtonText}>
          {modalButtonText}
        </Typography>
      </Button>

      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <CloseIconButton
          onClick={handleClose}
          className={style.closeButton}
          ref={closeRef}
        />
        <Form noValidate>
          <DialogTitle id="dialog-title">Modify Subscription</DialogTitle>
          <DialogContent>
            {!displayfilteredSettings ? (
              <>
                <DialogSectionTitle>Subscription Settings</DialogSectionTitle>
                <DialogSubSectionText>
                  These settings apply to all applications within the
                  subscription
                </DialogSubSectionText>
                <SettingSelect
                  {...props}
                  subscriptionId={subscription.subscriptionId}
                  subscriptionTypeId={subscription.subscriptionTypeId}
                  field="settings"
                  options={subscriptionOptions}
                  settingList={settingList}
                  id="tenantModal"
                ></SettingSelect>
                <Divider className={style.divider}></Divider>
              </>
            ) : (
              <>
                {subscription.subscriptionTypeId === 5 ? ( //Management Subscription
                  <>
                    <DialogSectionTitle>
                      Subscription Settings
                    </DialogSectionTitle>
                    <DialogSubSectionText>
                      These settings apply to all applications within the
                      subscription
                    </DialogSubSectionText>
                    <SettingSelect
                      {...props}
                      subscriptionId={subscription.subscriptionId}
                      subscriptionTypeId={subscription.subscriptionTypeId}
                      field="settings"
                      options={filteredSubscriptionOptions}
                      settingList={settingList}
                      id="tenantModal"
                    ></SettingSelect>
                    <Divider className={style.divider}></Divider>
                  </>
                ) : null}
              </>
            )}
            <DialogSectionTitle>Application Settings</DialogSectionTitle>

            <DialogSubSectionText>
              Each subscription must include at least 1 module. Expand a module
              to make changes to an application's available content and optional
              features.
            </DialogSubSectionText>
            <ModuleSection
              {...props}
              filteredModules={props.availableModules?.filter(moduleFilter)}
            />
          </DialogContent>
          <DialogActions className={style.overrideActions}>
            {errors.applicationCredentialId ? (
              <div className={style.errorMessage}>
                Please enter Redox ID to continue.
              </div>
            ) : null}
            {errors.compassKeyName ? (
              <div className={style.errorMessage}>
                Please enter an API Key name to continue.
              </div>
            ) : null}
            {errors.modules && touched.modules ? (
              <Typography className={style.errorMessage}>
                {errors.modules.module}
              </Typography>
            ) : null}

            <GeneralCancelButton onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GeneralCancelButton>
            <GreenGeneralButton
              variant="contained"
              type="submit"
              disabled={!isValid || isSubmitting}
              buttonText="Save Changes"
            ></GreenGeneralButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

ModifySubscriptionProductsModal.propTypes = {
  modalButtonText: PropTypes.string.isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ModifySubscriptionProductsModal;
