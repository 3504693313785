import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Container } from '@mui/material';

import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { GreenButtonLink } from 'components/buttons/button';
import Notification from 'components/notificationV2/notification';
import { PageHeader } from 'components/pageHeader/pageHeader';
import DelegatedUserCard from './delegatedUserCard';
import { useDelegatedAccess } from 'contexts/delegatedAccessContext';
import { CardSkeleton } from 'components/skeletons/skeletons';
import { ReactComponent as Engineering } from 'assets/icons/engineering.svg';
import {
  DEFAULT_BTN_COLOR_CONFIRM_RED,
  REVOKE_ACCESS_BTN_TEXT,
  REVOKE_ACCESS_MODAL_TITLE,
  REVOKE_ACCESS_MODAL_TEXT,
  DOC_TITLE_DELEGATED_ACCESS,
} from 'utils/dictionary/overview';
import { useUpdateDocumentTitle } from 'utils/hooks/common/useUpdateDocumentTitle';
import style from './delegatedAccessList.module.scss';

const returnAllCounts = subscriptions => {
  let totalCounts = {
    subs: 0,
    apps: 0,
    roles: 0,
  };

  if (subscriptions.length) {
    totalCounts.subs = subscriptions.length;

    subscriptions.forEach(sub => {
      if (sub.applications.length) {
        totalCounts.apps += sub.applications.length;

        sub.applications.map(app => {
          return app.roles.length
            ? (totalCounts.roles += app.roles.length)
            : null;
        });
      }
    });
  }

  return totalCounts;
};

const DelegatedAccessList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [userToRemove, setUserToRemove] = useState(null);

  let { path } = useRouteMatch();

  let {
    accessToken,
    listIsLoading,
    currentDelegates,
    setReloadList,
    putDelegatesCurrent,
    availableDelegates
  } = useDelegatedAccess();

  const handleModalOpen = function (rowData) {
    setUserToRemove(rowData);
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);

  const handleRemoveAccess = async () => {
    try {
      const { response, error } = await putDelegatesCurrent(
        userToRemove.userId,
        [],
        accessToken,
      );
      if (response?.ok) {
        setSuccess('User’s access revoked.');
        setReloadList(true);
      } else {
        setError(error.message);
      }
    } catch (error) {
      setError(error.message);
    }
    setModalOpen(false);
  };

  const HeaderTitle = () => {
    return <>Delegate Access</>;
  };

  const Secondary = () => {
    return (
      <>
        <div className={style.secondaryHeaderWrapper}>
          <p className={style.secondaryHeaderText}>
            Provide a Healthwise Employee access to your organization’s
            application(s) for technical assistance.
          </p>
          <GreenButtonLink
            disabled={!availableDelegates || !availableDelegates.length}
            to={{
              pathname: `${path}/create`,
              search: window.location.search
            }}
            disableRipple>
            <Engineering />
            <span className={style.btnText}>Add New Delegate</span>
          </GreenButtonLink>
        </div>
      </>
    );
  };

  useUpdateDocumentTitle(DOC_TITLE_DELEGATED_ACCESS);

  return (
    <section>
      {error ? <Notification error message={error} messageKey={error} /> : null}
      {success ? <Notification message={success} messageKey={success} /> : null}
      <PageHeader title={<HeaderTitle />} secondary={<Secondary />} />
      {modalOpen ? (
        <ConfirmationModal
          open={modalOpen}
          title={REVOKE_ACCESS_MODAL_TITLE}
          personFullName={`${userToRemove.fullName}`}
          text={REVOKE_ACCESS_MODAL_TEXT}
          confirmText={REVOKE_ACCESS_BTN_TEXT}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          handleConfirm={handleRemoveAccess}
          color={DEFAULT_BTN_COLOR_CONFIRM_RED}
        />
      ) : null}
      {listIsLoading ? (
        <Container>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </Container>
      ) : currentDelegates?.length ? (
        currentDelegates.map(delegate => (
          <DelegatedUserCard
            handleModalOpen={handleModalOpen}
            returnAllCounts={returnAllCounts}
            delegate={delegate}
            path={path}
            key={delegate.userId}
          />
        ))
      ) : (
        <p className={style.noDelegatesText}>
          There are no Healthwise employees with delegated access. Add a new
          delegate to provide access.
        </p>
      )}
    </section>
  );
};

export default DelegatedAccessList;
