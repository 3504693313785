/* Commonly used text strings */
export const DATE_ONLY = 'MM/dd/yyyy';
export const MONTH_DATE_YEAR = 'MMM dd, yyyy';

/* INFORMATIONAL POPOVER TEXT */
export const INFORMATIONAL_POPOVER_CLOSE_BUTTON = 'Close';

/* SERVICE CALL ERROR MESSAGE */
export const ERROR_MESSAGE_BAD_REQUEST = 'Bad Request';
export const ERROR_MESSAGE_UNAUTHORIZED = 'Unauthorized';
export const ERROR_MESSAGE_INTERNAL_SERVER_ERROR = 'Internal Server Error';
export const ERROR_MESSAGE_NETWORK_ERROR = 'Network Error';
export const ERROR_MESSAGE_UNKNOWN_ERROR = 'Unknown Error';
export const ERROR_MESSAGE_NOT_FOUND = 'Not Found';
export const ERROR_MESSAGE_USER_ALREADY_EXISTS = 'User already exists';

export const PRODUCTION_SUB_TYPE_ID = 1;
export const MANAGEMENT_SUB_TYPE_ID = 5;

export const ADVISE = 'aCR1T000000k9hoWAA';
export const COMPASS = 'aCRHs000000br2XOAQ';
