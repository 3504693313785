import React, { useState, useEffect, Fragment } from 'react';
import { Divider, Typography } from '@mui/material';
import { Form } from 'formik';
import { GreenGeneralButton } from 'components/buttons/button';
import ModuleSelection from 'components/organizationManagement/modifySubscriptionProductsModal/moduleSelection';
import style from './modifySubscription.module.scss';
import SettingSelect from '../../settingSelect/settingSelect';
import {
  StyledCardV2,
  StyledCardContentV2,
  StyledCardHeader,
} from 'components/card/card';
import {
  FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG,
  FEATURE_TOGGLE_ADDITIONAL_SETTING,
} from 'utils/dictionary/featureToggles';

const ModuleSection = props => {
  const [checked, setChecked] = useState({});

  const {
    selectedModules,
    filteredModules,
    setFieldValue,
    setFieldTouched,
    setRedoxError,
  } = props;

  useEffect(() => {
    Object.keys(selectedModules).forEach(moduleId => {
      setChecked(currentState => {
        return {
          ...currentState,
          [moduleId]: { checked: true },
        };
      });
    });
  }, [setChecked, selectedModules]);

  return (
    <>
      {filteredModules.map((module, index) => {
        return (
          <Fragment key={index}>
            <ModuleSelection
              {...props}
              selectedModules={selectedModules}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              module={module}
              setChecked={setChecked}
              checked={checked}
              name="modules"
              setRedoxError={setRedoxError}
            />
          </Fragment>
        );
      })}
    </>
  );
};

const ModifySubscription = props => {
  const {
    isValid,
    isSubmitting,
    subscription,
    errors,
    touched,
    subscriptionOptions,
    settingList,
    setRedoxError,
  } = props;

  const displayfilteredSettings =
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_ADDITIONAL_SETTING) === true &&
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_DISPLAY_SETTINGS_CONFIG) === true;

  const moduleFilter =
    subscription?.subscriptionTypeId === 5 // If it's a management subscription
      ? module => module.isPlatform ?? true // Then show only Platform Modules
      : module => !(module.isPlatform ?? false); // Otherwise, hide only Platform Modules

  return (
    <>
      <Form noValidate>
        <StyledCardV2>
          <StyledCardHeader title={'Modify Subscription'}></StyledCardHeader>
          <StyledCardContentV2>
            <div>
              <div className={style.subscriptionTitle}>
                Subscription Name:
                <span className={style.subName}>
                  {subscription.subscriptionName}
                </span>
              </div>
              {!displayfilteredSettings ? (
                <>
                  <div className={style.sectionTitle}>
                    Subscription Settings
                  </div>
                  <div className={style.subSectionText}>
                    These settings apply to all applications within the
                    subscription
                  </div>
                </>
              ) : (
                <>
                  {subscription.subscriptionTypeId === 5 ? (
                    <>
                      <div className={style.sectionTitle}>
                        Subscription Settings
                      </div>
                      <div className={style.subSectionText}>
                        These settings apply to all applications within the
                        subscription
                      </div>
                    </>
                  ) : null}
                </>
              )}
              <SettingSelect
                {...props}
                subscriptionId={subscription.subscriptionId}
                subscriptionTypeId={subscription.subscriptionTypeId}
                field="settings"
                options={subscriptionOptions}
                settingList={settingList}
                id="tenant"
                displayfilteredSettings={displayfilteredSettings}
              ></SettingSelect>{' '}
              {displayfilteredSettings &&
              subscription.subscriptionTypeId === 5 ? (
                <Divider className={style.divider}></Divider>
              ) : null}
              <div className={style.sectionTitle}>Application Settings</div>
              <div className={style.subSectionText}>
                Each subscription must include at least 1 module. All fields are
                required unless marked optional. Expand a module to make changes
                to an application's available content and optional features.
              </div>
              <ModuleSection
                {...props}
                setRedoxError={setRedoxError}
                filteredModules={props.availableModules?.filter(moduleFilter)}
              />
            </div>
            <div className={style.overrideActions}>
              {errors.applicationCredentialId ? (
                <div className={style.errorMessage}>
                  Please enter Redox ID to continue.
                </div>
              ) : null}
              {errors.modules && touched.modules ? (
                <Typography className={style.errorMessage}>
                  {errors.modules.module}
                </Typography>
              ) : null}
              <GreenGeneralButton
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting}
                buttonText="Save Changes"
              ></GreenGeneralButton>
            </div>
          </StyledCardContentV2>
        </StyledCardV2>
      </Form>
    </>
  );
};

export default ModifySubscription;
