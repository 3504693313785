import React, { useState, useContext, useRef } from 'react';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { AuthContext } from 'contexts/authContext';
import CreateOrganizationModal from 'components/organizationManagement/createOrganizationModal/createOrganizationModal';

import { createOrganization } from 'utils/api/organizations';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { ORGANIZATION_CATEGORY_CLIENT_VALUE } from 'utils/configuration/organizations';
import { useGetContractSearch } from 'utils/hooks/useToCallApiEndpoints';

import {
  CreateOrganizationSchema,
  FormInitialValues,
} from './formSchemaAndInitialValues';

const CreateOrganization = props => {
  const { modalButtonText, organizationCategories } = props;

  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time
  const { accessToken } = useContext(AuthContext);

  const [searchQuery, setSearchQuery] = useState('');
  const { data, errorMessage, searchSuccess, notificationKey } =
    useGetContractSearch(searchQuery);

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const addOrganization = async (values, bundle) => {
    const { setStatus, resetForm, setFieldError } = bundle;

    // TODO: https://healthwise.atlassian.net/browse/P40-670 - unhardcode the
    //  values that are hardcoded. Also determine and implement the minimum
    //  viable payload to create an organization.
    const salesforceReferenceDto = {
      accountName: values.primarySalesforceAccount.name,
      accountId: values.primarySalesforceAccount.id,
      accountManager: values.primarySalesforceAccount.accountManager,
      accountNumber: 'unknown',
      salesforceReferenceChannelId: 1,
    };
    // TODO: Remove once P40-670 is resolved, this is making sure that all keys have values.
    Object.keys(salesforceReferenceDto).forEach(key =>
      salesforceReferenceDto[key] === undefined
        ? delete salesforceReferenceDto[key]
        : {},
    );

    const organizationDto = {
      organizationName: values.organizationName,
      organizationId: uuidv4(),
      organizationTypeId: values.primarySalesforceAccount.organizationTypeId,
      archetypeId: values.primarySalesforceAccount.archetypeId,
      salesforceReferences: [salesforceReferenceDto],
      organizationCategoryId: values.organizationCategoryId,
      friendlyId: values.friendlyId ? values.friendlyId : null,
      alias: values.alias ? values.alias : null,
    };

    const { response, data, error } = await createOrganization(
      organizationDto,
      accessToken,
    );

    switch (response?.status) {
      case 201:
        //TODO: Think about if we will want to store both the id and name of both organization type and organization archetype in context
        setStatus({
          success: true,
          message: '{organizationDto.organizationName} successfully created ',
          messageKey: uuidv4(),
        });
        closeModal.current = { close: true }; //Conditional to close modal in the component
        resetForm(FormInitialValues);
        setRedirect({
          isRedirect: true,
          data: { organizationId: data?.data?.organizationId },
        });
        break;

      case 400:
        //TODO: Display error from API to user, one approach is what I implementated below.
        if (error.details?.error?.code === 'CompositeError') {
          error.details.error.details.forEach(d => {
            if (
              d.message.startsWith('An organization with the name') &&
              d.message.endsWith('already exists.')
            ) {
              setFieldError('organizationName', d.message);
            } else if (/.*FriendlyId \[.*\] already exists\./.test(d.message)) {
              setFieldError(
                'friendlyId',
                'This name is already in use. Please use a different name or add additional values to create a unique name.',
              );
            } else if (d.message.includes('FriendlyId')) {
              setFieldError(
                'friendlyId',
                'There was an error saving this identifier. Please make sure the identifier follows the rules listed.',
              );
            } else if (/.*Alias \[.*\] already exists\./.test(d.message)) {
              setFieldError(
                'alias',
                'This alias is already in use. Please use a different name or add additional values to create a unique alias.',
              );
            } else if (d.message.includes('Alias')) {
              setFieldError(
                'alias',
                'There was an error saving this alias. Please make sure the alias follows the rules listed.',
              );
            } else if (d.code === 'ValidationException') {
              setFieldError('primarySalesforceAccount', { id: d.message });
            } else {
              // TODO: Render specific error for user, error message from error details
              setStatus({
                success: false,
                message: String(error.details),
                messageKey: uuidv4(),
              });
            }
          });
        } else {
          setStatus({
            success: false,
            message: 'Bad Request. Please wait a moment and try again.',
            messageKey: uuidv4(),
          });
        }
        break;

      case 401:
        setStatus({
          success: false,
          message: 'Unauthorized',
          messageKey: uuidv4(),
        });
        break;

      case 500:
        setStatus({
          success: false,
          message:
            'Error creating new organization. Please try again or contact your Healthwise developers.',
          messageKey: uuidv4(),
        });
        break;

      default:
        if (!response) {
          setStatus({
            success: false,
            message: 'Network error',
            messageKey: uuidv4(),
          });
        } else {
          setStatus({
            success: false,
            message: 'Unknown error',
            messageKey: uuidv4(),
          });
        }
        break;
    }
  };

  // TODO: https://healthwise.atlassian.net/browse/P40-671; Remove when return
  //  data is in the right shape.
  const addMissingProperties = searchResult => {
    return searchResult?.map(item => ({
      ...item,
      archetypeId: 1,
      organizationTypeId: 1,
      organizationCategoryId: ORGANIZATION_CATEGORY_CLIENT_VALUE,
    }));
  };

  return (
    <Formik
      initialValues={FormInitialValues}
      validationSchema={CreateOrganizationSchema}
      onSubmit={addOrganization}
      children={props => (
        <CreateOrganizationModal
          {...props}
          modalButtonText={modalButtonText}
          closeModal={closeModal}
          searchResult={addMissingProperties(data) ?? []}
          setSearchQuery={setSearchQuery}
          searchStatus={{
            searchSuccess,
            errorMessage,
            notificationKey,
          }}
          categories={organizationCategories}
        />
      )}
    />
  );
};

export default CreateOrganization;
