import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'contexts/authContext';
import { useGetOrganization } from 'utils/hooks/useToCallApiEndpoints';
import { getSubscriptionSettings } from 'utils/api/settings';

export const useIsContentFeedbackEnabledManagementSubscription = () => {
    const [ subscriptionSettings, setSubscriptionSettings ] = useState({});
    const [ hasManagementSubscription, setHasManagementSubscription ] = useState(false);
    const [ contentFeedbackEnabledForManagementSubscription, setContentFeedbackEnabledForManagementSubscription ] = useState(false);
    const { organizationId, accessToken } = useContext(AuthContext);
    const { data: organization } = useGetOrganization(organizationId);

    async function getData(subscriptionId, accessToken) {
        const settingsResult = await getSubscriptionSettings(
            subscriptionId,
            accessToken,
        );

        if(settingsResult?.data){
            const CfSetting = settingsResult?.data?.filter(setting => {
                return setting.name === 'Content Feedback' ? true : false;
            })
            setSubscriptionSettings(...CfSetting);
        }
    }

    useEffect(()=> {
        organization?.data?.subscriptions?.forEach(sub => {
            if(sub.subscriptionName === 'Management'){
                setHasManagementSubscription(true);
                getData(sub.subscriptionId, accessToken);
            }
        });
    },[ organization?.data?.subscriptions, accessToken ])

    useEffect(()=>{
        if(hasManagementSubscription && subscriptionSettings?.option?.optionValue === 'true') setContentFeedbackEnabledForManagementSubscription(true);
    },[ subscriptionSettings, hasManagementSubscription ])

    return { contentFeedbackEnabledForManagementSubscription };
};