import React from 'react';
import style from './pageHeader.module.scss';

/**
 * @param title The title of the page
 * @param secondary Page description
 */
export const PageHeader = props => {
  const { title, secondary } = props;

  return (
    <div className={style.container}>
      {title ? <h1 className={style.title}>{title}</h1> : null}
      {secondary ? <span className={style.secondary}>{secondary}</span> : null}
    </div>
  );
};

export default PageHeader;
