import React, { useRef, useState, useContext } from 'react';
import CreateOrganizationAdministratorModalV2 from 'components/organizationManagement/createOrganizationAdministratorModalV2/createOrganizationAdministratorModalV2';
import {
  FormInitialValues,
  OrganizationAdministratorValidationSchemaWithDates,
} from 'components/organizationManagement/createOrganizationAdministratorModalV2/formSchemaAndInitialValues';
import { Formik } from 'formik';
import { createOrganizationAdministrator } from 'utils/api/organizations';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import { NotificationContext } from 'contexts/notificationContext';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import {
  ERROR_MESSAGE_BAD_REQUEST,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
  ERROR_MESSAGE_UNKNOWN_ERROR,
} from 'utils/dictionary';

import {
  dateFormatter,
  DateFormatReadable,
  GetFormattedISODateOffsetString,
} from 'utils/timeAndDate';
import { isAfter, isBefore, isEqual } from 'date-fns';

// Given a status, figure out the default message for the response
const getDefaultMessage = response => {
  switch (response?.status) {
    case 400:
      return ERROR_MESSAGE_BAD_REQUEST;
    case 500:
      return ERROR_MESSAGE_INTERNAL_SERVER_ERROR;
    default:
      if (response === null) {
        return ERROR_MESSAGE_NETWORK_ERROR;
      } else {
        return ERROR_MESSAGE_UNKNOWN_ERROR;
      }
  }
};

const CreateOrganizationAdministrator = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time

  const { organization = {} } = props ?? {};
  const { organizationId, identityProviders } = organization;
  const { showNotification } = useContext(NotificationContext);

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const addOrganizationAdministrator = async (values, { resetForm }) => {
    const { email, firstName, lastName, startDate, endDate } = values;
    const { identityProviderId } = identityProviders?.[0] ?? {};
    const userAction = 'createAdmin';

    const administratorDto = {
      email,
      firstName,
      identityProviderId,
      lastName,
      organizationId,
      startDate: GetFormattedISODateOffsetString(new Date(startDate)),
      endDate: endDate
        ? GetFormattedISODateOffsetString(new Date(endDate))
        : endDate,
      userAction,
    };

    const { response, error } = await createOrganizationAdministrator(
      organizationId,
      administratorDto,
      accessToken,
    );

    if (response?.ok) {
      closeModal.current = { close: true }; //Conditional to close modal in the component
      resetForm(FormInitialValues);

      const today = dateFormatter.format(new Date());
      const formattedStartDate = dateFormatter.format(Date.parse(startDate));

      if (isEqual(new Date(formattedStartDate), new Date(today))) {
        showNotification(
          `Invitation emailed to ${firstName} ${lastName}. This user will have 30 days to activate their account.`,
          false,
          10000,
        );
      } else if (isAfter(new Date(formattedStartDate), new Date(today))) {
        showNotification(
          `Invitation will be emailed to ${firstName} ${lastName} on ${DateFormatReadable(
            Date.parse(startDate),
          )}. This user will have 30 days to activate their account.`,
          false,
          10000,
        );
      } else if (isBefore(new Date(formattedStartDate), new Date(today))) {
        showNotification(
          `User permissions for ${firstName} ${lastName} updated successfully.`,
          false,
          10000,
        );
      }

      setRedirect({ isRedirect: true, data: { organizationId } });
    } else {
      if (response?.status === 400) {
        showNotification(
          `Error adding new Administrator. Please try again or contact your Healthwise developers.`,
          true,
          10000,
        );
      } else if (response?.status === 500) {
        showNotification(ERROR_MESSAGE_INTERNAL_SERVER_ERROR, true, 10000);
      } else {
        showNotification(
          error?.details?.error?.message ?? getDefaultMessage(response),
          true,
          10000,
        );
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={FormInitialValues}
        validationSchema={OrganizationAdministratorValidationSchemaWithDates}
        onSubmit={addOrganizationAdministrator}
        children={props => {
          return (
            <CreateOrganizationAdministratorModalV2
              {...props}
              organizationid={organizationId}
              modalbuttontext="Add New Administrator"
              closeModal={closeModal}
            />
          );
        }}
      />
    </>
  );
};

export default CreateOrganizationAdministrator;
