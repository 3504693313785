import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  DialogSubSectionText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/dialog';
import {
  GeneralCancelButton,
  GreenGeneralButton,
} from 'components/buttons/button';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import style from './editOrganizationSubscriptionModal.module.scss';

const EditOrganizationSubscriptionModal = forwardRef((props, ref) => {
  const { closeModal, isValid, dirty, isSubmitting, handleReset, closeMenu } =
    props;

  const [open, setOpen] = useState(false);

  const inputRef = useRef(null);
  useAddFocus(inputRef, open);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      handleOpen();
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) handleReset();
    setOpen(false);
    closeMenu();
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal.current.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  return (
    <>
      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle id="dialog-title">Rename Subscription</DialogTitle>
          <DialogContent>
            <DialogSubSectionText id="dialog-description">
              The subscription name is a unique client-friently name for
              managing and isolating sets {'\n'}
              of resources in a given environment.
            </DialogSubSectionText>
            <div className={style.name}>
              <BaselineTextInput
                id="subscriptionName"
                name="subscriptionName"
                errorlabel="Subscription Name"
                margin="none"
                size="small"
                inputRef={inputRef}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GeneralCancelButton>
            <GreenGeneralButton
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              buttonText="Rename Subscription"
            ></GreenGeneralButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
});

EditOrganizationSubscriptionModal.propTypes = {
  identityProviderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      identityProviderId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  environmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      subscriptionTypeId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditOrganizationSubscriptionModal;
