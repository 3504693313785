import React, { useContext, useEffect, useState } from 'react';
import { formatISO } from 'date-fns';

import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import InlineEditNotes from 'components/organizationManagement/organizationManagementEditOrganization/inlineEditNotes/inlineEditNotes';
import { FormInitialValues } from 'components/organizationManagement/organizationManagementEditOrganization/inlineEditNotes/formSchemaAndInitialValues';
import { updateSubscription } from 'utils/api/subscriptions';
import { AuthContext } from 'contexts/authContext';
import { Redirect } from 'react-router-dom';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';

const SubscriptionInlineEditNotes = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken, user } = useContext(AuthContext);
  const { subscriptionData, organizationId, subscriptionEtag, rowData } = props;
  const { subscriptionId } = rowData;
  const { name } = user ?? {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subEtag, setSubEtag] = useState(subscriptionEtag);
  const [noteText, setNoteText] = useState('');

  useEffect(() => {
    setNoteText(subscriptionData?.data?.notes?.[0]?.text ?? '');
  }, [subscriptionData?.data?.notes]);

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const addNotes = async (values, { setStatus }) => {
    setIsSubmitting(true);
    const noteDto = {
      text: values?.subscriptionNote ?? '',
      updateDate: formatISO(new Date()),
      updatedBy: name,
    };

    const updateSubscriptionDto = {
      notes: [noteDto],
    };

    const { response, etag, data } = await updateSubscription(
      organizationId,
      subscriptionId,
      updateSubscriptionDto,
      subEtag,
      accessToken,
    );

    setNoteText(data?.data?.notes?.[0]?.text);
    setSubEtag(etag);
    setIsSubmitting(false);

    switch (response?.status) {
      case 200:
        setStatus({
          success: true,
          message: 'Internal Notes saved successfully.',
          messageKey: uuidv4(),
        });
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 412:
        setStatus({
          success: false,
          message:
            'Someone is currently editing this subscription, please try again in a few minutes.',
          messageKey: uuidv4(),
        });
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 500:
        setStatus({
          success: false,
          message:
            'Unexpected error occurred. Please wait a moment and try again.',
          messageKey: uuidv4(),
        });
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      default:
        if (!response) {
          setStatus({
            success: false,
            message: 'Network error.',
            messageKey: uuidv4(),
          });
        } else {
          setStatus({
            success: false,
            message: 'Unknown error.',
            messageKey: uuidv4(),
          });
        }
        break;
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues(noteText)}
      onSubmit={addNotes}
      children={props => (
        <InlineEditNotes
          {...props}
          isSubmitting={isSubmitting}
          noteText={noteText}
        />
      )}
    />
  );
};

export default SubscriptionInlineEditNotes;
