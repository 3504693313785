import React, { useRef } from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import {
  DialogContentText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/dialog';
import {
  GeneralCancelButton,
  GreenGeneralButton,
} from 'components/buttons/button';
import AccountLifespanFields from 'components/users/accountLifespanFields/accountLifespanFields';
import { getMinimumEndDate } from 'containers/users/user';
import style from './editOrganizationAdministratorDatesModal.module.scss';

const EditOrganizationAdministratorDatesModal = props => {
  const {
    values: { startDate: startDateForm, endDate: endDateForm },
    open,
    isValid,
    dirty,
    isSubmitting,
    handleClose,
    handleCancel,
    userInfo,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    initialValues,
  } = props;

  const cancelRef = useRef(null);
  useAddFocus(cancelRef, open);

  const setDateField = fieldName => {
    return newValue => {
      if (!newValue || (newValue instanceof Date && !isNaN(newValue))) {
        setFieldValue(fieldName, newValue, true);
      } else {
        setFieldError(fieldName, 'Invalid Date');
      }

      setFieldTouched(fieldName, true, false);
    };
  };
  const setStartDate = setDateField('startDate');
  const setEndDate = setDateField('endDate');

  return (
    <>
      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={open}
        onClose={handleClose}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle id="dialog-title" className={style.title}>
            Edit Start and End Date
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="dialog-description"
              className={style.bodyText}
            >
              Please use the fields below to update the start and end dates for{' '}
              <strong>
                {userInfo.firstName} {userInfo.lastName}
              </strong>
              .
            </DialogContentText>
            <AccountLifespanFields
              className={style.fieldRow}
              startDate={startDateForm}
              setStartDate={setStartDate}
              endDate={endDateForm}
              setEndDate={setEndDate}
              minimumEndDate={
                getMinimumEndDate ? getMinimumEndDate(startDateForm).date : null
              }
              initialValues={initialValues}
              textProps={{
                variant: 'standard',
              }}
            />
          </DialogContent>
          <DialogActions className={style.rightAlignedButtonGroup}>
            <GeneralCancelButton
              onClick={handleCancel}
              disableRipple
              ref={cancelRef}
            ></GeneralCancelButton>
            <GreenGeneralButton
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              buttonText="Save Changes"
            ></GreenGeneralButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

EditOrganizationAdministratorDatesModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditOrganizationAdministratorDatesModal;
