import React from 'react';
import { Form, Field, useFormikContext } from 'formik';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { StyledCard } from 'components/card/card';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import { GreenOutlinedButtonLink } from 'components/buttons/button';

import EditRedirects from './editRedirects';
import EditLogoutUrls from './editLogoutUrls';
import EditWebOriginsUrls from './editWebOriginsUrls';

import styles from '../editapplication.module.scss';

/* Known issue:
 *     When adding a Description, errors will be logged in the console with the message:
 * 'onloadwff.js:71 Assertion failed: Input argument is not an HTMLInputElement' This is due to a
 * bug with LastPass. See https://github.com/mui/material-ui/issues/14860 for more context. The
 * proposed workaround of adding 'data-lpignore' doesn't currently work as inputProps for our
 * BaselineTextInput component is slightly broken as it doesn't pass inputProps to the base
 * Material UI TextField component correctly. (10 NOV 2022)
 */

// Render the 'Save Application' button/link
const SaveApplicationButton = props => {
  const { values, actions, handleSubmit } = props;
  return (
    <div className={styles.saveButtonWraper}>
      <GreenOutlinedButtonLink
        buttonText="Save Application"
        handleClick={() => handleSubmit(values, actions)}
      />
    </div>
  );
};

const ApplicationIconField = props => {
  const { initialValues, setFieldValue } = props;

  const clearIcon = () => {
    setFieldValue('icon', '');
  };

  const resetIcon = () => {
    setFieldValue('icon', initialValues.icon);
  };

  // TODO: Need to handle cancelling the file picker and a non-svg file.
  const uploadIcon = event => {
    handleFileChosen(event.target.files[0]);
  };

  const DisplayIcon = () => {
    const { values } = useFormikContext();
    return (
      <div className={styles.iconOutline}>
        <img
          key={values.applicationId}
          alt="Application Icon"
          src={'data:image/svg+xml;base64,' + values.icon}
        />
      </div>
    );
  };

  let fileReader;

  const handleFileRead = e => {
    const content = fileReader.result;

    // Since our icons are stored w/o the header, we have to remove the header.
    setFieldValue('icon', content.replace('data:image/svg+xml;base64,', ''));
  };

  const handleFileChosen = file => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    // Use the built in method to upload the file as base64.
    fileReader.readAsDataURL(file);
  };

  // TODO add aira-label a11y fields
  const UploadIconButton = props => {
    return (
      <GreenOutlinedButtonLink
        buttonText="Choose Icon"
        component="label"
        handleChange={event => {
          uploadIcon(event);
        }}
      >
        <input type="file" hidden id="icon" accept=".svg,image/svg" />
      </GreenOutlinedButtonLink>
    );
  };

  // TODO add aira-label stuff
  const RemoveIconButton = props => {
    return (
      <GreenOutlinedButtonLink
        buttonText="Remove Icon"
        handleClick={() => {
          clearIcon();
        }}
      />
    );
  };

  // TODO add aira-label stuff
  const ResetIconButton = props => {
    return (
      <GreenOutlinedButtonLink
        buttonText="Reset Icon"
        handleClick={() => {
          resetIcon();
        }}
      />
    );
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <div className={styles.applicationsTitle}>Application Icon</div>
      </Grid>
      <Grid item xs={4} container direction="row">
        <Grid item xs={4}>
          <Field component={DisplayIcon} />
        </Grid>
        <Grid item xs={4}>
          <Grid item>
            <Field component={RemoveIconButton} />
          </Grid>
          <Grid item>
            <Field component={UploadIconButton} />
          </Grid>
          <Grid item>
            <Field component={ResetIconButton} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ApplicationDetailsCard = props => {
  return (
    <Form>
      <StyledCard>
        <Grid container direction="column">
          <Grid item>
            <SaveApplicationButton {...props} />
          </Grid>
          <Grid item>
            <div className={styles.applicationsTitle}>
              Application Information
            </div>
          </Grid>
          <Grid container direction="column">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="applicationId"
                  externalLabel="Application Id"
                  errorlabel="Application Id"
                  name="applicationId"
                  readOnly
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="sourceId"
                  externalLabel="Source Id"
                  errorlabel="Source Id"
                  name="sourceId"
                  readOnly
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="referenceId"
                  externalLabel="Reference Id"
                  errorlabel="Reference Id"
                  name="referenceId"
                  readOnly
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="availableFrom"
                  externalLabel="Available From"
                  errorlabel="Available From"
                  name="availableFrom"
                  readOnly
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="versionNumber"
                  externalLabel="Version"
                  errorlabel="Version"
                  name="versionNumber"
                  readOnly
                />
              </Grid>

              <Grid item xs={4}>
                <BaselineTextInput
                  id="name"
                  externalLabel="Name"
                  errorlabel="Name"
                  name="name"
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="fullyQualifiedName"
                  externalLabel="Fully Qualified Name"
                  errorlabel="Fully Qualified Name"
                  name="fullyQualifiedName"
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="route"
                  externalLabel="Route"
                  errorlabel="Route"
                  name="route"
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="owner"
                  externalLabel="Owner"
                  errorlabel="Owner"
                  name="owner"
                />
              </Grid>
              <Grid item xs={4}>
                <BaselineTextInput
                  id="ownerEmail"
                  externalLabel="Owner Email"
                  errorlabel="Owner Email"
                  name="ownerEmail"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BaselineTextInput
                  id="description"
                  externalLabel="Description"
                  errorlabel="Description"
                  name="description"
                  multiline={true}
                  rows={4}
                  fullWidth={true}
                  inputProps={{
                    'data-lpignore': true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="column">
            <Grid item xs={4}>
              <div className={styles.applicationsTitle}>Application Status</div>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox />}
                checked={props.values.active}
                disabled
              />
              Active
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox />}
                checked={props.values.available}
                disabled
              />
              Available
            </Grid>
          </Grid>

          <Grid item>
            <ApplicationIconField {...props} />
          </Grid>

          <Grid container direction="column" spacing={2}>
            <Grid item xs={4}>
              <div className={styles.applicationsTitle}>Redirect Urls</div>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <EditRedirects {...props} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={2}>
            <Grid item xs={4}>
              <div className={styles.applicationsTitle}>Logout Urls</div>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <EditLogoutUrls {...props} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={2}>
            <Grid item xs={4}>
              <div className={styles.applicationsTitle}>Web Origin Urls</div>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <EditWebOriginsUrls {...props} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <SaveApplicationButton {...props} />
          </Grid>
        </Grid>
      </StyledCard>
    </Form>
  );
};

export default ApplicationDetailsCard;
