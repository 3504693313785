import React from 'react';

import { SubscriptionSelector } from 'components/subscriptionSelectorV2/subscriptionSelector';

import style from './roleAssignment.module.scss';

// Pass in `readOnly` to disable the adding/removing of values
const RoleAssignment = props => {
  const {
    headline = 'At least one Subscription, Application, and Role must be assigned to a user.',
  } = props;

  return (
    <>
      <p className={style.cardHeadline}>{headline}</p>
      <SubscriptionSelector className={style.subscriptionSelector} {...props} />
    </>
  );
};

export default RoleAssignment;
