import React from 'react';
import { FieldArray } from 'formik';
import { List, ListItem } from '@mui/material';

import { AddItemButton, TrashButton } from 'components/buttons/button';
import { BaselineTextInput } from 'components/textInputV2/textInput';

/*
 * Display a list of web origin url's that the application will accept.
 */
const EditWebOriginsUrls = props => {
  const { setFieldValue, values } = props;

  // Before adding, we should probably validate that the value is a valid URL.
  const addRedirect = (event, weborigin, arrayHelpers) => {
    arrayHelpers.push(weborigin);
    setFieldValue('newWebOriginUrl', '');
  };

  return (
    <FieldArray
      name="webOriginsUrls"
      render={arrayHelpers => (
        <List>
          {values.webOriginsUrls.map((webOriginUrl, index) => (
            <ListItem key={'listWO' + index}>
              <BaselineTextInput
                key={'inputWO' + index}
                name={webOriginUrl}
                value={webOriginUrl}
              />
              <TrashButton
                key={'remove' + index}
                onClick={() => arrayHelpers.remove(index)}
                aria-label="Remove Web Origin URI"
              />
            </ListItem>
          ))}
          <ListItem key="newWebOriginUrl">
            <BaselineTextInput
              key="newWebOriginItem"
              name="newWebOriginUrl"
              value={values.newWebOriginUrl}
            />
            <AddItemButton
              title="Add Web Origin URL"
              onClick={event => {
                addRedirect(event, values.newWebOriginUrl, arrayHelpers);
              }}
            />
          </ListItem>
        </List>
      )}
    />
  );
};

export default EditWebOriginsUrls;
