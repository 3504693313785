import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import style from './contentAndFeatureSelection.module.scss';

const ContentSelection = props => {
  const {
    licenseAssets = [],
    availableAppAssets,
    application,
    module,
    reset,
    onChange,
    onBlur,
  } = props;

  const [checked, setChecked] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [once, setOnce] = useState(true);

  const selectAssets = useCallback(
    () =>
      licenseAssets.forEach(item => {
        setChecked(currentState => {
          return {
            ...currentState,
            [item.assetId]: { checked: true },
          };
        });
      }),
    [licenseAssets],
  );

  useEffect(() => {
    let modules = props.values.modules[module.id];

    if (!modules || Object.keys(modules).length === 0) {
      setDisabled(true);
      setChecked({});
    } else {
      setDisabled(false);
    }
  }, [module.id, props.values.modules]);

  useEffect(() => {
    if (licenseAssets.length > 0 && once) {
      selectAssets();
      setOnce(false);
    }
  }, [licenseAssets, once, selectAssets]);

  useEffect(() => {
    if (reset) {
      selectAssets();
    }
  }, [reset, selectAssets]);

  const setAssets = (content, checked) => {
    setChecked(currentState => {
      return {
        ...currentState,
        [content.conceptId]: { checked: checked },
      };
    });

    let copy = JSON.parse(JSON.stringify(props.values.modules[module.id]));

    const appIndex = copy.findIndex(x => x.sourceId === application.id);
    if (checked) {
      copy[appIndex]?.content.push({
        assetId: content.conceptId,
        localizations: content.localizations,
      });
    } else {
      let index = copy[appIndex].content.findIndex(
        x => x.assetId === content.conceptId,
      );
      copy[appIndex]?.content.splice(index, 1);
    }

    let modules = Object.assign({}, props.values.modules);
    modules[module.id] = copy;

    onBlur(`modules[${module.id}].[${appIndex}].concept`);
    onChange('modules', modules);
  };

  return (
    availableAppAssets && (
      <div>
        {availableAppAssets.map((item, index) => {
          return (
            <Fragment key={index}>
              <FormControlLabel
                className={`${style.checkboxChip} ${
                  checked[item.conceptId]?.checked
                    ? style.checkboxChipSelected
                    : ''
                }`}
                control={
                  <Checkbox
                    className={
                      !checked[item.conceptId]?.checked ? style.uncheck : ''
                    }
                    aria-label={`${item.name} for ${application.name}`}
                    name={item.name}
                    checked={checked[item.conceptId]?.checked || false}
                    checkedIcon={<CheckIcon className={style.check} />}
                    disabled={disabled}
                    onChange={event => {
                      setAssets(item, event.target.checked);
                    }}
                    sx={{
                      padding: '0 6px 0 0',
                      display: disabled ? 'none' : '',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  />
                }
                label={item.name}
              />
            </Fragment>
          );
        })}
      </div>
    )
  );
};

export default ContentSelection;
