import React from 'react';

import { NoteSkeleton } from 'components/skeletons/skeletons';
import { SectionHeader } from 'components/sectionHeader/sectionHeader';
import SavedOrganizationNotes from 'components/organizationManagement/savedOrganizationNotes/savedOrganizationNotes';

import style from './internalNotesPanel.module.scss';

const InternalNotesPanel = ({
  organizationToEditLoading,
  organization,
  renderDeleteSaveOrganizationNotes,
  renderOrganizationNotes,
}) => {
  return (
    <div className={style.internalNotesPanelContainer}>
      {organizationToEditLoading ? (
        <div className={style.noteSkeletonContainer}>
          <NoteSkeleton />
        </div>
      ) : (
        <>
          <SectionHeader
            title="Internal notes"
            secondary="These notes are NOT visible to the client."
          />
          <div className={style.notesContainer}>
            {organization?.notes?.map((note, index) => {
              return (
                <SavedOrganizationNotes
                  key={index}
                  index={index}
                  editor={note.updatedBy}
                  date={note.updatedDate}
                  note={note.text}
                  organization={organization}
                  renderDeleteSaveOrganizationNotes={props =>
                    renderDeleteSaveOrganizationNotes({
                      ...props,
                      noteIndex: index,
                      currentNote: note,
                    })
                  }
                />
              );
            })}
            <>
              {renderOrganizationNotes({
                organizationToEdit: organization,
              })}
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default InternalNotesPanel;
