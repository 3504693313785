import * as Yup from 'yup';

const GetSchema = (value, objProp, requiredMessage) => {
  if (Object.keys(value ?? {})?.length === 0) {
    return Yup.object().shape({
      [objProp]: Yup.string().required(requiredMessage),
    });
  } else if (Object.keys(value ?? {})?.length > 0) {
    const isSelected = Object.entries(value).some(item => {
      return Object.keys(item[1] ?? {})?.length > 0;
    });

    if (isSelected) {
      return Yup.object().shape({});
    } else {
      return Yup.object().shape({
        [objProp]: Yup.string().required(requiredMessage),
      });
    }
  }
  return Yup.object().shape({});
};

export const ModifySubscriptionConfigureProductsSchema = (subType = 0) => {
  return Yup.object().shape({
    modules: Yup.lazy(value =>
      GetSchema(value, 'module', 'Select at least one module'),
    ),
  });
};

export const FormInitialValues = (modules = {}, settings = {}) => {
  return { modules, settings };
};
