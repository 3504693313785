import * as configuration from 'utils/configuration/organizations';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const organizationsUrl = baseUrl + configuration.ORGANIZATIONS_ROOT;

export async function getOrganizations(skip = 0, top = 10, accessToken = null) {
  return await utils.getPaginatedResult(
    organizationsUrl,
    skip,
    top,
    accessToken,
  );
}

export async function createOrganization(organization, accessToken) {
  return await utils.createResource(
    organizationsUrl,
    organization,
    accessToken,
  );
}

export async function getOrganization(organizationId, accessToken) {
  return await utils.getResource(
    `${organizationsUrl}/${organizationId}`,
    accessToken,
  );
}

export async function getOrganizationName(organizationId, accessToken) {
  return await utils.getResource(
    `${organizationsUrl}/${organizationId}/name`,
    accessToken,
  );
}

export async function updateOrganization(
  organizationId,
  organization,
  etag,
  accessToken,
) {
  return await utils.updateResource(
    `${organizationsUrl}/${organizationId}`,
    organization,
    etag,
    accessToken,
  );
}

export async function deleteOrganization(organizationId, etag, accessToken) {
  return await utils.deleteResource(
    `${organizationsUrl}/${organizationId}`,
    etag,
    accessToken,
  );
}

export async function disableOrganization(organizationId, etag, accessToken) {
  return await utils.updateResource(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_DISABLE}`,
    {},
    etag,
    accessToken,
  );
}

/**
 * Retrieve organization administrators of a particular organization.
 * @param {guid} organizationId The ID of the organization to lookup
 * @param {string} accessToken The access token of the user attempting the lookup
 * @param {string} skip Number of records to skip
 * @param {string} top Number of records to return
 */

export async function getOrganizationAdministrators(
  organizationId = null,
  accessToken = null,
  skip = 0,
  top = 10,
) {
  return await utils.getPaginatedResult(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}`,
    skip,
    top,
    accessToken,
  );
}

export async function searchUsers(
  organizationId = '',
  skip = 0,
  top = 10,
  accessToken = null,
  search = '',
  orderByField = 'firstName',
  orderByDirection = 'asc',
) {
  return await utils.getPaginatedResult(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_USERS}`,
    skip,
    top,
    accessToken,
    encodeURIComponent(search),
    orderByField,
    orderByDirection,
  );
}

export async function getOrganizationApplications(organizationId, accessToken) {
  return await utils.getResource(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATIONS_AVAILABLE_APPLICATIONS}`,
    accessToken,
  );
}

export async function createOrganizationAdministrator(
  organizationId,
  organizationAdministrator,
  accessToken,
) {
  return await utils.createResource(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}`,
    organizationAdministrator,
    accessToken,
  );
}

export async function updateOrganizationAdministratorDates(
  organizationId,
  userId,
  organizationAdministratoToUpdate,
  accessToken,
) {
  return await utils.postWithoutContent(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}/${userId}/${configuration.ORGANIZATION_ADMINISTRATOR_DATES_UPDATE}`,
    organizationAdministratoToUpdate,
    accessToken,
  );
}

export async function reactivateOrganizationAdministrator(
  organizationId,
  userId,
  payload,
  accessToken,
) {
  return await utils.postWithoutContent(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}/${userId}/${configuration.ORGANIZATION_ADMINISTRATOR_REACTIVATE}`,
    payload,
    accessToken,
  );
}

export async function resendOrganizationAdminInvite(
  organizationId,
  userId,
  payload,
  accessToken,
) {
  return await utils.postWithoutContent(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}/${userId}/${configuration.ORGANIZATION_ADMINISTRATOR_RESEND_INVITE}`,
    {},
    accessToken,
  );
}

export async function removeOrganizationAdministrator(
  organizationId,
  userId,
  accessToken,
) {
  return await utils.deleteResource(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}/${userId}`,
    {},
    accessToken,
  );
}

export async function resetPasswordOrganizationAdministrator(
  organizationId,
  userId,
  accessToken,
) {
  var body = {
    sendEmail: true,
    userId: userId,
  };

  return await utils.postWithContent(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}/${configuration.ORGANIZATION_RESET_PASSWORD}`,
    body,
    accessToken,
  );
}

export async function forceResetPasswordOrganizationAdministrator(
  organizationId,
  userId,
  accessToken,
) {
  var body = {
    tempPassword: true,
    userId: userId,
  };

  return await utils.postWithContent(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_ADMINISTRATOR}/${configuration.ORGANIZATION_EXPIRE_PASSWORD}`,
    body,
    accessToken,
  );
}
