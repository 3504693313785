import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Formik } from 'formik';
import { AuthContext } from 'contexts/authContext';
import MenuItemModalAndFeedbackModal from 'components/organizationManagement/organizationManagementEditOrganization/menuItemModalAndFeedbackModal/menuItemModalAndFeedbackModal';
import { resendOrganizationAdminInvite } from 'utils/api/organizations';

const OrganizationAdministratorResendInvitation = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { organization = {}, rowData = {}, closeMenu } = props ?? {};

  const { firstName, lastName, email, status, userId } = rowData;
  const { organizationId } = organization;

  const content = (
    <>
      <b>
        {firstName} {lastName}
      </b>{' '}
      at {email} will be emailed account setup instructions.
    </>
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = evt => {
    setOpen(false);
    setIsSubmitting(false);
    closeMenu();
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      handleOpen();
    },
  }));

  const resendAdministratorInvitation = async (values, { setStatus }) => {
    setIsSubmitting(true);
    const { response } = await resendOrganizationAdminInvite(
      organizationId,
      userId,
      null,
      accessToken,
    );

    if (response?.status === 204) {
      setStatus({
        isError: false,
        date: Date.now(),
        message:
          'Invitation emailed to  ' +
          firstName +
          ' ' +
          lastName +
          '. This user has 30 days to activate their account.',
      });
    } else {
      setStatus({
        isError: true,
        date: Date.now(),
        message:
          'Error resending invite. Please try again or contact your Healthwise developer.',
      });
    }

    handleClose();
  };

  return status === 'PROVISIONED' ? (
    <>
      <Formik
        initialValues={{}}
        onSubmit={resendAdministratorInvitation}
        children={props => (
          <MenuItemModalAndFeedbackModal
            {...props}
            open={open}
            handleClose={handleClose}
            confirmationButtonText="Send"
            cancelButtonText="Cancel"
            labelledBy="administratorResendInvitationModalTitle"
            describedBy="administratorResendInvitationModalDescription"
            modalTitle="Resend invitation to this user?"
            isSubmitting={isSubmitting}
            isDelete={false}
            content={content}
          />
        )}
      />
    </>
  ) : null;
});

export default OrganizationAdministratorResendInvitation;
