import MaterialTable from '@material-table/core';
import {
  createReport,
  deleteReport,
  getReports,
  getUnassignedReports,
} from 'utils/api/applicationReports';
import { AuthContext } from 'contexts/authContext';
import React, { useContext, useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EditApplicationReports = ({
  applicationId,
  reportsList,
  setReportsMethod,
  setStatus,
}) => {
  const authContext = useContext(AuthContext);

  const [unassignedReports, setUnassignedReports] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (applicationId && authContext && authContext.accessToken) {
      getUnassignedApplicationReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, authContext, authContext.accessToken]);

  function reset() {
    getApplicationReports();
    getUnassignedApplicationReports();
  }

  function handleDeleteReportClick(rowData) {
    if (rowData.applicationReportId) {
      deleteReport(rowData.applicationReportId, authContext.accessToken).then(
        (response) => {
          if (response.error || response.response.status !== 200) {
            setStatus({
              success: false,
              message: 'Failed to delete Report',
            });
          } else {
            setStatus({
              success: true,
              message: 'Report deleted successfully',
            });
            reset();
          }
        },
      );
    }
  }

  async function handleNewReportClick() {
    let reportToAdd = {
      applicationId: applicationId,
      reportId: selected,
    };
    setSelected('');
    return await createReport(reportToAdd, authContext.accessToken);
  }

  function handleError(errorCode, requestType) {
    setStatus({
      success: false,
      message: `Failed to ${requestType} Report.`,
    });
    reset();
  }

  function handleSuccess(request) {
    setStatus({
      success: true,
      message: `Report ${request} successfully`,
    });
    reset();
  }

  function getApplicationReports() {
    getReports(applicationId, authContext.accessToken).then(
      (response, data, error) => {
        if (response.error || response.response.status !== 200) {
          setStatus({
            success: false,
            message: 'Failed to load report',
          });
        }
        setReportsMethod(response.data);
      },
    );
  }

  function getUnassignedApplicationReports() {
    getUnassignedReports(applicationId, authContext.accessToken).then(
      (response) => {
        if (response.error || response.response.status !== 200) {
          setStatus({
            success: false,
            message: 'Failed to load unassigned reports',
          });
        }
        setUnassignedReports(response.data);
      },
    );
  }

  return (
    <>
      <MaterialTable
        columns={[
          {
            title: 'applicationReportsId',
            field: 'applicationReportsId',
            hidden: true,
          },
          { title: 'applicationId', field: 'applicationId', hidden: true },
          {
            title: 'Name',
            field: 'reportName',
            editComponent: props => (
              <Select
                value={selected}
                onChange={event => {
                  setSelected(event.target.value);
                }}
                style={{ width: '50%' }}
              >
                {unassignedReports?.map((obj, index) => (
                  <MenuItem key={index} value={obj.id}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            ),
          },
        ]}
        data={reportsList ?? []}
        title="Reports"
        options={{
          sorting: true,
          paging: true,
          search: false,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
        }}
        editable={{
          onRowAdd: () =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                handleNewReportClick().then(res => {
                  if (res.error || res.response.status !== 201) {
                    handleError(res.response.status, 'create');
                    reject();
                  } else {
                    handleSuccess('created');
                    resolve();
                  }
                });
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                handleDeleteReportClick(oldData);
                resolve();
              }, 1000);
            }),
        }}
      />
    </>
  );
};

export default EditApplicationReports;
