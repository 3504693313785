import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/authContext';
import { getOrganizationApplications } from 'utils/api/organizations';

// TODO: Evaluate if it's safe to remove this "empty" subscription object so we're not accidentally thinking
// we've loaded subscriptions when it's really just this one
const emptyReferenceSubscriptions = [
  {
    id: '',
    name: '',
    value: '',
    applications: [
      {
        id: '',
        name: '',
        value: '',
        roles: [
          {
            id: '',
            name: '',
            value: '',
            description: '',
          },
        ],
      },
    ],
  },
];

const parseOrgSubAppRoles = function (subscriptions, applications) {
  const referenceSubscriptions = subscriptions
    .map(subscription => {
      const refrenceApplications = subscription.applications
        .map(application => {
          const roles = application.roles.map(role => {
            return {
              id: `${subscription.id}::${application.id}::${role.name}`,
              name: role.name,
              value: role.name,
              description: role.description,
            };
          });

          if (roles.length > 0) {
            const referenceApplication = applications.find(rawApp => {
              return rawApp.id === application.id;
            });

            return {
              id: application.id,
              name: referenceApplication.name,
              value: referenceApplication.name,
              sourceId: referenceApplication.sourceId,
              fullyQualifiedName: referenceApplication.fullyQualifiedName,
              roles: roles,
            };
          }
          return undefined;
        })
        .filter(Boolean);
      if (refrenceApplications.length > 0) {
        return {
          id: subscription.id,
          name: subscription.name,
          value: subscription.name,
          applications: refrenceApplications,
        };
      }
      return undefined;
    })
    .filter(Boolean);

  return referenceSubscriptions;
};

export const ReferenceSubscriptionsProvider = ({ children }) => {
  const { accessToken, organizationId, loading } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [didMount, setDidMount] = useState(false);

  // The consolidated reference subscription state
  const [referenceSubscriptions, setReferenceSubscriptions] = useState(
    emptyReferenceSubscriptions,
  );

  const store = {
    referenceSubscriptions,
    errorMessage,
  };

  useEffect(() => {
    setDidMount(true);
    const orgApplications = async () => {
      const { response, data, error } = await getOrganizationApplications(
        organizationId,
        accessToken,
      );

      if (response.ok) {
        const subscriptionList = Object.values(data?.subscriptions);
        const applicationList = Object.values(data?.applications);

        setReferenceSubscriptions(
          parseOrgSubAppRoles(subscriptionList, applicationList),
        );
      }

      setErrorMessage(error);
    };

    if (!loading) {
      orgApplications();
    }
    return () => setDidMount(false);
  }, [accessToken, organizationId, loading]);

  if (!didMount) {
    return null;
  }

  return (
    <ReferenceSubscriptionsContext.Provider value={store}>
      {children}
    </ReferenceSubscriptionsContext.Provider>
  );
};

export const ReferenceSubscriptionsContext = createContext();

/**
 * Helper function so you don't have to import useContext.
 *
 * @return {object} The Reference Subscriptions object and it's state.
 */
export const useReferenceSubscriptions = () => {
  const context = useContext(ReferenceSubscriptionsContext);
  if (context === undefined) {
    throw new Error(
      'Reference Subscription Context must be used within a Provider',
    );
  }
  return context;
};
