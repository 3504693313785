import { useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AuthContext } from 'contexts/authContext';
import {
  ERROR_MESSAGE_UNAUTHORIZED,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
  ERROR_MESSAGE_NOT_FOUND,
} from 'utils/dictionary';
import { getOrganization } from 'utils/api/organizations';
import { getSubscriptions, getSubscription } from 'utils/api/subscriptions';
import {
  getEntitlementPackageForOrganization,
  getEntitlementPackageForOrganizationSubscription,
} from 'utils/api/entitlement';
import {
  getDelegatesCurrent,
  getDelegatesAvailable,
} from 'utils/api/delegates';
import { getSubscriptionTypes } from 'utils/api/catalogs';
import { getUser } from 'utils/api/users';
import { getContractSearch } from 'utils/api/contract';
import { getOrganizationAdministrators } from 'utils/api/organizations';
import { getOrganizationCategories } from 'utils/api/catalogs';

const setDataOrErrorMessage = (
  response,
  data,
  error,
  setData = () => {},
  setErrorMessage = () => {},
) => {
  switch (response?.status) {
    case 200:
      setData(data);
      break;
    case 404:
      setErrorMessage(ERROR_MESSAGE_NOT_FOUND);
      break;
    case 401:
      setErrorMessage(ERROR_MESSAGE_UNAUTHORIZED);
      break;
    case 500:
      setErrorMessage(ERROR_MESSAGE_INTERNAL_SERVER_ERROR);
      break;
    default:
      !response
        ? setErrorMessage(ERROR_MESSAGE_NETWORK_ERROR)
        : setErrorMessage('Unknown error.');
      break;
  }
};

/* TODO: This is a rework for setDataOrErrorMessage. setDataOrErrorMessage will be replace with setApiCallStatus later */
const setApiCallStatus = (
  response,
  data,
  error,
  setStatus = () => {},
  setIsLoading = () => {},
) => {
  switch (response?.status) {
    case 200:
      setStatus({ data, success: !error });
      break;
    case 404:
      setStatus({
        data: null,
        success: !error,
        message: ERROR_MESSAGE_NOT_FOUND,
        key: uuidv4(),
      });
      break;
    case 401:
      setStatus({
        data: null,
        success: !error,
        message: ERROR_MESSAGE_UNAUTHORIZED,
        key: uuidv4(),
      });
      break;
    case 500:
      setStatus({
        data: null,
        success: !error,
        message: ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
        key: uuidv4(),
      });
      break;
    default:
      !response
        ? setStatus({
            data: null,
            success: !error,
            message: ERROR_MESSAGE_NETWORK_ERROR,
            key: uuidv4(),
          })
        : setStatus({
            data: null,
            success: !error,
            message: 'Unknown error.',
            key: uuidv4(),
          });
      break;
  }
  setIsLoading(false);
};

export const useRetrieveEntitlementPacket = organizationId => {
  const { accessToken, loading } = useContext(AuthContext);
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const retrieveEntitlementPacket = async () => {
      const { response, data, error } =
        await getEntitlementPackageForOrganization(organizationId, accessToken);

      setApiCallStatus(response, data, error, setStatus, setIsLoading);
    };
    if (!loading && organizationId) {
      retrieveEntitlementPacket();
    }
  }, [accessToken, loading, organizationId]);

  return {
    data: status?.data ?? {},
    errorMessage: status?.message,
    success: status?.success,
    notificationKey: status?.key,
    isLoading,
  };
};

export const useRetrieveSubscriptionEntitlementPacket = (
  organizationId,
  subscriptionId,
) => {
  const { accessToken, loading } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    const retrieveEntitlementPacket = async () => {
      const { response, data, error } =
        await getEntitlementPackageForOrganizationSubscription(
          organizationId,
          subscriptionId,
          accessToken,
        );

      setDataOrErrorMessage(response, data, error, setData, setErrorMessage);
    };
    if (!loading && organizationId && subscriptionId) {
      retrieveEntitlementPacket();
    }
  }, [accessToken, loading, organizationId, subscriptionId]);

  return { data, errorMessage };
};

export const useGetOrganization = organizationId => {
  const { accessToken, loading } = useContext(AuthContext);
  const [status, setStatus] = useState();
  const [etag, setEtag] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const response = async () => {
      const { response, data, error, etag } = await getOrganization(
        organizationId,
        accessToken,
      );

      setEtag(etag);
      setApiCallStatus(response, data, error, setStatus, setIsLoading);
    };
    if (!loading && organizationId) {
      response();
    }
  }, [accessToken, loading, organizationId]);
  return {
    data: status?.data ?? {},
    errorMessage: status?.message,
    success: status?.success,
    notificationKey: status?.key,
    etag: etag ?? '',
    isLoading,
  };
};

// TODO Tech Debt: getting the top 100 admins
export const useGetOrganizationAdministrators = organizationId => {
  const { accessToken, loading } = useContext(AuthContext);
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getOrganizationAdministrators(
        organizationId,
        accessToken,
        0,
        100,
      );

      setApiCallStatus(response, data, error, setStatus, setIsLoading);
    };
    if (!loading) {
      response();
    }
  }, [accessToken, loading, organizationId]);

  return {
    data: status?.data ?? [],
    errorMessage: status?.message,
    success: status?.success,
    notificationKey: status?.key,
    isLoading,
  };
};

export const useGetPaginatedSubscriptions = (
  skipInitialValue = 0,
  topInitialValue = 10,
  organizationId,
  includeManagementSubscriptions = false,
) => {
  const { accessToken, loading, isProvisioningManager } =
    useContext(AuthContext);
  const [skip, setSkip] = useState(skipInitialValue);
  const [top, setTop] = useState(topInitialValue);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const response = async () => {
      const { response, data, pagination, error } = await getSubscriptions(
        skip,
        top,
        organizationId,
        accessToken,
        includeManagementSubscriptions && isProvisioningManager,
      );

      setPagination(pagination);
      setDataOrErrorMessage(response, data, error, setData, setErrorMessage);
    };
    if (!loading) {
      response();
    }
  }, [
    skip,
    top,
    accessToken,
    loading,
    organizationId,
    isProvisioningManager,
    includeManagementSubscriptions,
  ]);

  return { data, top, setTop, skip, setSkip, pagination, errorMessage };
};

export const useGetDelegatedAccessCurrent = () => {
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { accessToken, loading } = useContext(AuthContext);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getDelegatesCurrent(accessToken);

      setDataOrErrorMessage(response, data, error, setData, setErrorMessage);
    };

    if (!loading) {
      response();
    }
  }, [accessToken, loading]);

  return {
    data,
    errorMessage,
  };
};

export const useGetDelegatesAvailable = () => {
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { accessToken, loading } = useContext(AuthContext);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getDelegatesAvailable(
        accessToken,
      );

      setDataOrErrorMessage(response, data, error, setData, setErrorMessage);
    };

    if (!loading) {
      response();
    }
  }, [accessToken, loading]);

  return {
    data,
    errorMessage,
  };
};

export const useGetSubscriptionTypes = () => {
  const { accessToken, loading } = useContext(AuthContext);
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getSubscriptionTypes(accessToken);

      setApiCallStatus(
        response?.response,
        data,
        error,
        setStatus,
        setIsLoading,
      );
    };

    if (!loading) {
      response();
    }
  }, [accessToken, loading]);

  return {
    data: status?.data ?? [],
    errorMessage: status?.message,
    success: status?.success,
    notificationKey: status?.key,
    isLoading,
  };
};

export const useGetContractSearch = searchQuery => {
  const { accessToken, loading } = useContext(AuthContext);
  const [status, setStatus] = useState();

  useEffect(() => {
    const searchResponse = async query => {
      const { response, data, error } = await getContractSearch(
        query,
        accessToken,
      );

      setApiCallStatus(response?.response, data, error, setStatus);
    };

    if (!loading && searchQuery && searchQuery.length > 0) {
      searchResponse(searchQuery);
    }
  }, [searchQuery, accessToken, loading]);

  return {
    data: status?.data,
    errorMessage: status?.message,
    searchSuccess: status?.success,
    notificationKey: status?.key,
  };
};

export const useGetUser = userId => {
  const { accessToken, loading } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getUser(userId, accessToken);

      setDataOrErrorMessage(response, data, error, setData, setErrorMessage);
    };
    if (!loading && userId) {
      response();
    }
  }, [accessToken, loading, userId]);
  return { data, errorMessage };
};

export const useGetSubscription = (organizationId, subscriptionId) => {
  const { accessToken, loading } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [etag, setEtag] = useState(null);

  useEffect(() => {
    const response = async () => {
      const { response, data, error, etag } = await getSubscription(
        organizationId,
        subscriptionId,
        accessToken,
      );

      setEtag(etag);
      setDataOrErrorMessage(response, data, error, setData, setErrorMessage);
    };
    if (!loading && organizationId) {
      response();
    }
  }, [accessToken, loading, organizationId, subscriptionId]);
  return { data, errorMessage, etag: etag ?? '' };
};

export const useGetOrganizationCategories = () => {
  const { accessToken, loading } = useContext(AuthContext);
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getOrganizationCategories(
        accessToken,
      );

      setApiCallStatus(
        response?.response,
        data,
        error,
        setStatus,
        setIsLoading,
      );
    };

    if (!loading) {
      response();
    }
  }, [accessToken, loading]);
  return {
    data: status?.data ?? [],
    errorMessage: status?.message,
    success: status?.success,
    notificationKey: status?.key,
    isLoading,
  };
};
