import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { FormatISO } from 'utils/timeAndDate';
import style from './savedOrganizationNotes.module.scss';

const SavedOrganizationNotes = props => {
  const {
    editor,
    date,
    note,
    renderDeleteSaveOrganizationNotes,
    organization,
    index,
  } = props;

  const [showAllText, setShowAllText] = useState(false);
  const [noteTextHeight, setNoteTextHeight] = useState(0);
  const [cutOff, setCutOff] = useState(false);

  let noteRef = useRef(null);

  useEffect(() => {
    setNoteTextHeight(noteRef.current.clientHeight);
    if (noteTextHeight > 125) {
      setCutOff(true);
    }
  }, [noteTextHeight]);

  const toggleText = () => {
    setShowAllText(!showAllText);
  };

  //TODO: Need to update the organization data model to show who and when the notes modified
  return (
    <div>
      <Card className={style.noteCard}>
        <CardHeader
          className={style.cardHeader}
          title={editor}
          subheader={FormatISO(date, "MMM dd, yyyy 'at' h:mm aaa")}
          action={renderDeleteSaveOrganizationNotes({ organization, index })}
        />
        <CardContent className={style.cardContent}>
          <p
            ref={noteRef}
            className={`${style.noteText} ${cutOff ? style.cutOff : ''} ${
              showAllText ? style.showAll : ''
            }`}
          >
            {note}
          </p>
          {cutOff && (
            <button onClick={toggleText} className={style.textBtn}>
              {showAllText ? 'Show less' : 'Show more'}
            </button>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

SavedOrganizationNotes.prototype = {
  editor: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  note: PropTypes.string,
  renderDeleteSaveOrganizationNotes: PropTypes.func.isRequired,
};

export default SavedOrganizationNotes;
