import React, { useRef, useContext, useState, useEffect } from 'react';
import {
  OrganizationSubscriptionSchema,
  FormInitialValues,
} from 'components/organizationManagement/createOrganizationSubscriptionModal/formSchemaAndInitialValues';
import CreateOrganizationSubscriptionModal from 'components/organizationManagement/createOrganizationSubscriptionModal/createOrganizationSubscriptionModal';
import { Formik } from 'formik';
import { createSubscription } from 'utils/api/subscriptions';
import { AuthContext } from 'contexts/authContext';
import { Redirect } from 'react-router-dom';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { NotificationContext } from 'contexts/notificationContext';

const CreateOrganizationSubscription = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time
  const { showNotification } = React.useContext(NotificationContext);
  const [status, setStatus] = useState(null);
  const {
    modalButtonText = '',
    identityProviderOptions = [],
    environmentOptions = [],
    organizationId,
    productionSubscriptionsLimitInfo,
  } = props;

  const { success, message = null, date } = status ?? {};

  useEffect(() => {
    if (message) {
      showNotification(message, !success, 5000, date);
      status.message = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, message, date]);

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const createNewOrganizationSubscription = async (
    values,
    { resetForm, setFieldError },
  ) => {
    const { selectEnvironment, subscriptionName, friendlyId } = values ?? {};

    const subscriptionDto = {
      identityProviders: [
        {
          identityProviderId: identityProviderOptions?.[0]?.identityProviderId,
          name: identityProviderOptions?.[0]?.name,
        },
      ],
      subscriptionName,
      subscriptionType: selectEnvironment.value,
      subscriptionTypeId: selectEnvironment.id,
      friendlyId: friendlyId ? friendlyId : null,
    };

    const { response, error } = await createSubscription(
      organizationId,
      subscriptionDto,
      accessToken,
    );

    switch (response?.status) {
      case 201:
        //TODO: Think about if we will want to store both the id and name of both organization type and organization archetype in context
        setStatus({
          success: true,
          message: `Successfully added ${subscriptionName}`,
          date: Date.now(),
        });
        closeModal.current = { close: true }; //Conditional to close modal in the component
        resetForm(FormInitialValues());
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 400:
        var errors = error?.details?.error?.details;

        if (!errors) {
          setFieldError(
            'subscriptionName',
            'A subscription with that name already exists.',
          );

          break;
        }

        errors.forEach(e => {
          if (e.message && e.message.includes('name')) {
            setFieldError('subscriptionName', e.message);
          }

          if (/.*FriendlyId \[.*\] already exists\./.test(e.message)) {
            setFieldError(
              'friendlyId',
              'This name is already in use. Please use a different name or add additional values to create a unique name.',
            );
          } else if (e.message && e.message.includes('FriendlyId')) {
            setFieldError(
              'friendlyId',
              'There was an error saving this identifier. Please make sure the identifier follows the rules listed.',
            );
          }
        });
        break;
      case 500:
        setStatus({
          success: false,
          message:
            'Error adding new subscription. Please try again or contact your Healthwise developers.',
          date: Date.now(),
        });
        break;
      default:
        if (!response) {
          setStatus({
            success: false,
            message: 'Network error.',
            date: Date.now(),
          });
        } else {
          setStatus({
            success: false,
            message: 'Unknown error.',
            date: Date.now(),
          });
        }
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues()}
      validationSchema={OrganizationSubscriptionSchema}
      onSubmit={createNewOrganizationSubscription}
      children={props => (
        <CreateOrganizationSubscriptionModal
          {...props}
          modalButtonText={modalButtonText}
          closeModal={closeModal}
          environmentOptions={environmentOptions}
          productionSubscriptionsLimitInfo={
            productionSubscriptionsLimitInfo
          }
        />
      )}
    />
  );
};

export default CreateOrganizationSubscription;
