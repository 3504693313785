import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import VerifyModal from 'components/verifyModal/verifyModal';
import {
  GreenOutlinedButtonLink,
  GreenFormSubmitButton,
  RedOutlinedButtonLink,
  EditIconButton,
  CancelButton,
  GreenGeneralButton,
} from 'components/buttons/button';
import {
  UserCardv2,
  UserCardHeaderv2,
  UserCardContentv2,
} from 'components/card/card';
import { USER_MANAGEMENT_PATH } from 'utils/configuration/links';

import Notification from 'components/notificationV2/notification';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';

import RoleAssignment from 'pages/users/modifyUser/components/roleAssignment/roleAssignment';
import { UserDetails as UserDetailsV2 } from 'pages/users/createUser/components/userDetails/userDetails';

import { MONTH_DATE_YEAR } from 'utils/dictionary';
import { FormatISO } from 'utils/timeAndDate';
import ResetPasswordWorkflow from 'components/passwordWorkflows/resetPasswordWorkflow';
import { BackToPrevPageLink } from 'components/users/backToPrevPageLink';
import StatusChip from 'components/users/statusChip/statusChip';
import Tooltip from 'components/tooltipV2/tooltip';
import FeatureToggleHealthwiseOnly from 'components/featureToggleHealthwiseOnly/featureToggleHealthwiseOnly';
import { HorizontalRule } from 'components/horizontalRule/horizontalRule';
import { getMinimumEndDate } from 'containers/users/user';
import {
  FEATURE_TOGGLE_DEBUG,
} from 'utils/dictionary/featureToggles';
import { DateFormatReadable, dateFormatter } from 'utils/timeAndDate';

import { getStatusName } from 'utils/statusNames';

import {
  resendInviteUser,
  activateUser,
  deactivateUser,
  deleteUser,
  resync,
} from 'utils/api/users';

import { getDeleteUserProperties } from 'pages/users/modalConfiguration';

import style from './modifyUserForm.module.scss';
import ReactivationModal from 'components/modal/reactivationModal';
import UserPermissionCheckbox from '../createUser/components/userPermissionCheckbox/userPermissionCheckbox';
import { useIsContentFeedbackEnabledManagementSubscription } from 'utils/hooks/common/useIsContentFeedbackEnabledManagementSubscription.js';

const UserActionMenu = ({ isSubmitting, userInfo, handleModalAction }) => {
  const allowResendInvitation = ['Invite Sent', 'Resend Invite'].includes(
    getStatusName(userInfo.status, userInfo.startDate),
  );

  const allowDeactivation = [
    'ACTIVE',
    'RECOVERY',
    'PROVISIONED',
    'LOCKED_OUT',
    'PASSWORD_EXPIRED',
  ].includes(userInfo.status);

  const allowActivation = ['SUSPENDED', 'DEPROVISIONED'].includes(
    userInfo.status,
  );

  const allowUnlock = ['LOCKED_OUT'].includes(userInfo.status);

  const allowDelete = ['STAGED', 'SUSPENDED', 'DEPROVISIONED'].includes(
    userInfo.status,
  );

  const handleAction = action => {
    handleModalAction(action, userInfo);
  };

  return (
    <>
      {isSubmitting ? (
        <ProgressIndicator />
      ) : (
        <>
          {allowDelete ? (
            <RedOutlinedButtonLink
              buttonText="Delete User"
              handleClick={() => handleAction('deleteUser')}
            />
          ) : null}
          {allowDelete && <div className={style.buttonDivider} />}
          {allowDeactivation ? (
            <RedOutlinedButtonLink
              buttonText="Deactivate User"
              handleClick={() => handleAction('deactivateUser')}
            />
          ) : null}
          {allowDeactivation && <div className={style.buttonDivider} />}
          {allowResendInvitation ? (
            <GreenOutlinedButtonLink
              buttonText="Resend Invitation"
              handleClick={() => handleAction('resendInvitation')}
            />
          ) : null}
          {allowResendInvitation && <div className={style.buttonDivider} />}
          {allowUnlock ? (
            <GreenOutlinedButtonLink
              buttonText="Unlock"
              handleClick={() => handleAction('activateUser')}
            />
          ) : null}
          {allowUnlock && <div className={style.buttonDivider} />}
          {allowActivation ? (
            <GreenOutlinedButtonLink
              buttonText="Activate User"
              handleClick={() => handleAction('activateUser')}
            />
          ) : null}
          <FeatureToggleHealthwiseOnly featureName={FEATURE_TOGGLE_DEBUG}>
            <div className={style.buttonDivider} />
            <GreenGeneralButton
              buttonText="Resync User from Okta"
              handleClick={() => handleAction('resync')}
            />
          </FeatureToggleHealthwiseOnly>
        </>
      )}
    </>
  );
};

const resyncUser = async (userInfo, handleUserEvent) => {
  const apiInvocation = async () =>
    await resync(userInfo.id, userInfo.accessToken);

  const successMessage = 'User resync was successful.';

  await handleUserEvent(apiInvocation, successMessage);
};

const resendInvitation = async (userInfo, handleUserEvent) => {
  const apiInvocation = async () =>
    await resendInviteUser(userInfo.id, userInfo.accessToken);
  let successMessage = `Invitation emailed to ${userInfo.firstName} ${userInfo.lastName}. This user has 30 days to activate their account.`;
  await handleUserEvent(apiInvocation, successMessage);
};

const deactivate = async ({ id, accessToken }, handleUserEvent) => {
  const apiInvocation = async () => await deactivateUser(id, accessToken);
  await handleUserEvent(apiInvocation, '');
};

const activate = async (
  { id, firstName, lastName, accessToken },
  { startDate, endDate } = {} || null,
  handleUserEvent,
) => {
  const apiInvocation = async () =>
    await activateUser(id, startDate, endDate, accessToken);

  let successMessage = '';

  const today = dateFormatter.format(new Date());
  const newStartDate = dateFormatter.format(new Date(startDate));

  if (today === newStartDate) {
    successMessage = `Invitation emailed to ${firstName} ${lastName}. This user has 30 days to activate their account.`;
  } else {
    successMessage = `Invitation will be emailed to ${firstName} ${lastName} on ${DateFormatReadable(
      Date.parse(startDate),
    )}. This user will have 30 days to activate their account.`;
  }

  await handleUserEvent(apiInvocation, successMessage);
};

const remove = async ({ id, accessToken }, handleUserEvent) => {
  const apiInvocation = async () => await deleteUser(id, accessToken);
  await handleUserEvent(apiInvocation, 'User has been deleted.');
};

const CardHeaderWithTooltip = ({
  className,
  children,
  tooltipText,
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      className={`${style.roleAssignmentCardHeader} ${className}`}
    >
      {children}
      <Tooltip title={tooltipText} />
    </div>
  );
};

const ModifyUserForm = props => {
  const {
    userInfo,
    changeStatus,
    onSubmitUserFields,
    onSubmitUserRoles,
    validationSchemaDefinition,
    getCustomUserValidationSchema,
  } = props;

  const [modalProperties, setModalProperties] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [deleteModalProperties, setDeleteModalProperties] = useState(null);
  const [editUser, setEditUser] = useState(false);
  const [allRowsComplete, setAllRowsComplete] = useState(true);
  const [isActivateUser, setActivateUser] = useState(false);
  const { contentFeedbackEnabledForManagementSubscription } = useIsContentFeedbackEnabledManagementSubscription();

  let history = useHistory();
  const { lastLogin, status, firstName, lastName, email, startDate, endDate } =
    props.userInfo;
  const activity = lastLogin
    ? FormatISO(lastLogin, MONTH_DATE_YEAR)
    : undefined;
  const formattedStartDate = startDate
    ? FormatISO(startDate, MONTH_DATE_YEAR)
    : undefined;
  const formattedEndDate = endDate
    ? FormatISO(endDate, MONTH_DATE_YEAR)
    : undefined;

  const handleOpenEditUser = () => {
    setEditUser(true);
  };
  const handleCloseEditUser = () => {
    setEditUser(false);
  };

  const EDIT_USER_ROLES_TOOLTIP_TEXT =
    'Roles and permissions are grouped by subscriptions and applications that your company licenses. Subscriptions are containers used to manage and isolate your Healthwise resources in different environments like production or test. If you need further permissions, please reach out to your organization administrator(s). ';

  const RESENT_INVITATION_ERROR_MESSAGE =
    'Error sending invitation to user. Please try again or contact your Healthwise administrator.';

  const handleModalClose = () => {
    setModalOpen(false);
    setDeleteModelOpen(false);
  };

  const allowReset = ['ACTIVE'].includes(userInfo.status);

  const handleUserEvent = async (asyncApiInvocation, successMessage) => {
    const { error } = await asyncApiInvocation();

    const [message, success] = error
      ? [RESENT_INVITATION_ERROR_MESSAGE, false]
      : [successMessage, true];

    success ? setSuccess(message) : setError(message);

    handleModalClose();
  };

  const redirectToTarget = () => {
    setTimeout(() => {
      history.push({
        pathname: USER_MANAGEMENT_PATH,
        search: window.location.search,
      });
    }, 1000);
  };

  const configureConfirmationModal = (action, userInfo) => {
    let modalProperties;

    switch (action) {
      case 'resync':
        modalProperties = {
          title: 'Resync this user from Okta?',
          text: ' will be resynced from Okta.',
          confirmText: 'Resync',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          handleConfirm: async () => {
            await resyncUser(userInfo, handleUserEvent);
            redirectToTarget();
          },
        };
        break;

      case 'resendInvitation':
        modalProperties = {
          title: 'Resend invitation to this user?',
          text: ` will be emailed account set up instructions.`,
          confirmText: 'Resend Invitation',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          handleConfirm: async () =>
            await resendInvitation(userInfo, handleUserEvent),
        };
        setActivateUser(false);
        break;

      case 'deactivateUser':
        modalProperties = {
          title: 'Deactivate this user?',
          text: ` will lose the ability to access all applications.`,
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          confirmText: 'Deactivate User',
          color: 'red',
          handleConfirm: async () => {
            await deactivate(userInfo, handleUserEvent);
            changeStatus();
          },
        };
        setActivateUser(false);
        break;

      case 'activateUser':
        modalProperties = {
          title: 'Activate this user?',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          text: ` will be able to access any applications or permissions assigned to them.`,
          confirmText: 'Activate user',
          handleConfirm: async values => {
            await activate(userInfo, values, handleUserEvent);
            changeStatus();
          },
        };
        setActivateUser(true);
        break;

      case 'deleteUser':
        modalProperties = getDeleteUserProperties(userInfo, async () => {
          await remove(userInfo, handleUserEvent);
          redirectToTarget();
        });
        setDeleteModalProperties(modalProperties);
        setDeleteModelOpen(true);
        setActivateUser(false);
        return;

      default:
        modalProperties = {
          title: 'Default Title',
          text: 'Default Text',
          confirmText: 'Default Confirm',
          handleConfirm: () => null,
        };
        break;
    }
    setModalProperties(modalProperties);
    setModalOpen(true);
  };

  const dateValidationSchema = getCustomUserValidationSchema({
    startDate: validationSchemaDefinition.startDate,
    endDate: validationSchemaDefinition.endDate,
  });

  const namesAndDatesValidationSchema = getCustomUserValidationSchema({
    firstName: validationSchemaDefinition.firstName,
    lastName: validationSchemaDefinition.lastName,
    startDate: validationSchemaDefinition.startDate,
    endDate: validationSchemaDefinition.endDate,
  });

  const roleValidationSchema = getCustomUserValidationSchema({
    selectedRoles: validationSchemaDefinition.selectedRoles,
  });

  const reactivateTextProps = {
    dateValidationSchema,
    contentText: `Account activation instructions will be sent to `,
    altText: ' on the start date selected below.',
    dateText: 'Please select a start date for this user to continue.',
  };

  return (
    <section>
      {error ? <Notification error message={error} duration={8000} /> : null}
      {success ? <Notification message={success} /> : null}
      {modalOpen && isActivateUser ? (
        <ReactivationModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...modalProperties}
          {...reactivateTextProps}
          {...userInfo}
        />
      ) : null}
      {modalOpen && !isActivateUser ? (
        <ConfirmationModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...modalProperties}
        />
      ) : null}
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...deleteModalProperties}
        />
      ) : null}
      <BackToPrevPageLink
        text="Back to User Management"
        path={USER_MANAGEMENT_PATH}
      />
      <UserCardv2 className={style.userDetailContainer}>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            startDate: userInfo.startDate,
            endDate: userInfo.endDate,
          }}
          validationSchema={namesAndDatesValidationSchema}
          onSubmit={async (values, actions) => {
            const success = await onSubmitUserFields(values, actions);
            if (success) {
              handleCloseEditUser();
            }
          }}
          onReset={() => {
            handleCloseEditUser();
          }}
          children={formikProps => {
            const {
              values: { startDate: startDateForm, endDate: endDateForm },
              setFieldValue,
              setFieldError,
              setFieldTouched,
              initialValues,
              handleReset,
            } = formikProps;

            const setDateField = fieldName => {
              return newValue => {
                if (
                  !newValue ||
                  (newValue instanceof Date && !isNaN(newValue))
                ) {
                  setFieldValue(fieldName, newValue, true);
                } else {
                  setFieldError(fieldName, 'Invalid Date');
                }

                setFieldTouched(fieldName, true, false);
              };
            };

            const setStartDate = setDateField('startDate');
            const setEndDate = setDateField('endDate');

            return (
              <>
                <UserCardContentv2 className={style.userDetail}>
                  {!editUser ? (
                    <span className={style.userName}>
                      {`${firstName} ${lastName}`}
                      <EditIconButton
                        className={style.editButton}
                        onClick={handleOpenEditUser}
                      />
                    </span>
                  ) : (
                    <UserCardContentv2 className={style.editUserContainer}>
                      <UserDetailsV2
                        {...userInfo}
                        initialValues={initialValues}
                        createUser={false}
                        startDate={startDateForm}
                        endDate={endDateForm}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        minimumEndDate={
                          getMinimumEndDate
                            ? getMinimumEndDate(startDateForm).date
                            : null
                        }
                      />
                      <div className={style.editUserBtnContainer}>
                        <GreenFormSubmitButton
                          buttonText="Save"
                          {...props}
                          {...formikProps}
                        />
                        <CancelButton
                          buttonText={`Cancel`}
                          handleClick={handleReset}
                          className={style.cancelButtonSmall}
                        />
                      </div>
                    </UserCardContentv2>
                  )}
                  <div>
                    <StatusChip status={status} startDate={startDate} />
                  </div>
                  <div className={style.loginActivity}>
                    {activity ? <span>Last Login: {activity}</span> : null}
                  </div>
                  {!editUser ? (
                    <>
                      <div className={style.startAndEndDate}>
                        {formattedStartDate ? (
                          <span className={style.startDateSpan}>
                            Start Date: {formattedStartDate}
                          </span>
                        ) : null}
                        {formattedEndDate ? (
                          <>
                            <span>|</span>
                            <span className={style.endDateSpan}>
                              End Date: {formattedEndDate}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className={style.userEmail}>
                        <span>{`Email: ${email}`}</span>
                      </div>
                    </>
                  ) : (
                    <div className={style.userEmail}>
                      <span>{`Email: ${email}`}</span>
                    </div>
                  )}

                  {allowReset ? (
                    <ResetPasswordWorkflow
                      forOrgAdmin={false}
                      userInfo={userInfo}
                    ></ResetPasswordWorkflow>
                  ) : null}
                </UserCardContentv2>
                <UserCardContentv2 className={style.userDetailActions}>
                  <UserActionMenu
                    {...props}
                    {...formikProps}
                    handleModalAction={configureConfirmationModal}
                  />
                </UserCardContentv2>
              </>
            );
          }}
        />
      </UserCardv2>

      <UserCardv2 variant="outlined" className={style.roleAssignment}>
        <UserCardHeaderv2
          title="User Roles and Permissions"
          tooltipText={EDIT_USER_ROLES_TOOLTIP_TEXT}
          component={CardHeaderWithTooltip}
        />
        <UserCardContentv2>
          <Formik
            enableReinitialize
            initialValues={{
              selectedRoles: userInfo.selectedRoles,
              organizationId: userInfo.organizationId,
              logService: userInfo.logService,
              contentFeedback: userInfo.contentFeedback,
            }}
            validationSchema={roleValidationSchema}
            onSubmit={onSubmitUserRoles}
            children={formikProps => (
              <>
                <RoleAssignment
                  tooltipText={EDIT_USER_ROLES_TOOLTIP_TEXT}
                  setAllRowsComplete={setAllRowsComplete}
                  {...props}
                  {...formikProps}
                />
                <HorizontalRule />
                {contentFeedbackEnabledForManagementSubscription &&
                  <UserPermissionCheckbox
                    name="contentFeedback"
                    text="This user can submit content feedback on behalf of my organization"
                  /> 
                }
                <UserPermissionCheckbox
                  name="logService"
                  text="This user can log a service request on behalf of my organization"
                />
                {formikProps.dirty || !allRowsComplete ? (
                  <>
                    {!allRowsComplete ? <HorizontalRule /> : null}
                    <div className={style.submitFormButtonContainer}>
                      {formikProps.isSubmitting ? (
                        <ProgressIndicator />
                      ) : (
                        <GreenFormSubmitButton
                          buttonText="Save Changes"
                          {...formikProps}
                          isValid={formikProps.isValid && allRowsComplete}
                        />
                      )}
                    </div>
                  </>
                ) : null}
              </>
            )}
          />
        </UserCardContentv2>
      </UserCardv2>
    </section>
  );
};
export default ModifyUserForm;
