import React from 'react';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { startOfToday } from 'date-fns';
import { getStatusName } from 'utils/statusNames';

import DatepickerField from './datepickerField';

import style from './accountLifespanFields.module.scss';

const AccountLifespanFields = props => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    minimumEndDate,
    maximumStartDate,
    textProps,
    className,
    status,
    initialValues,
    modalType = null,
    focusStartDate = false,
  } = props;

  const [message, setMessage] = React.useState();

  const inputStartDateRef = React.useRef(null);
  const inputEndDateRef = React.useRef(null);

  let statusName;
  if (status) {
    statusName = getStatusName(
      status,
      modalType !== null && modalType === 'orgAdmin'
        ? startDate
        : initialValues.startDate,
    );
  }

  const disableStartDate =
    statusName === 'Active' ||
    (statusName === 'Inactive' && modalType === null) ||
    (statusName === 'Inactive' &&
      modalType !== null &&
      modalType === 'orgAdmin') ||
    statusName === 'Invite Sent' ||
    (statusName === 'Resend Invite' && modalType === null) ||
    (statusName === 'Resend Invite' &&
      modalType !== null &&
      modalType !== 'orgAdmin');

  const disableEndDate =
    (statusName === 'Inactive' && modalType === null) ||
    (statusName === 'Inactive' &&
      modalType !== null &&
      modalType !== 'orgAdmin');

  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        className={style.srOnly}
        tabIndex="-1"
      >
        {message}
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container className={className}>
          <DatepickerField
            fieldName="startDate"
            isRequired={true}
            disabled={disableStartDate}
            disablePast={true}
            disableCloseButton={disableStartDate}
            minDate={startOfToday()}
            maxDate={maximumStartDate}
            value={startDate}
            setValue={setStartDate}
            setMessage={setMessage}
            fieldLabel="Start Date"
            externalLabel="Start Date"
            helperTextId="startDate-helper-text"
            errorLabel="Start Date"
            inputRef={inputStartDateRef}
            textProps={textProps}
            removeAdornments={disableStartDate}
            trim={disableStartDate}
            focusStartDate={focusStartDate}
          />
          <div className={style.dateFieldTo}>
            <p>to</p>
          </div>
          <DatepickerField
            fieldName="endDate"
            isRequired={false}
            disabled={disableEndDate}
            disableCloseButton={disableEndDate}
            removeAdornments={disableEndDate}
            disablePast={false}
            minDate={minimumEndDate}
            value={endDate}
            setValue={setEndDate}
            defaultCalendarMonth={minimumEndDate}
            setMessage={setMessage}
            fieldLabel="End Date"
            externalLabel={
              <>
                End Date <em>(Optional)</em>
              </>
            }
            helperTextId="endDate-helper-text"
            errorLabel="End Date"
            inputRef={inputEndDateRef}
            textProps={textProps}
          />
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default AccountLifespanFields;
