import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  DialogSectionTitle,
  DialogSubSectionText,
  Divider,
} from 'components/dialog/dialog';
import { BaselineTextInput } from 'components/textInputV2/textInput';

import { DoneIcon, CloseIcon } from 'components/icons/icons';

import style from './friendlyIdInput.module.scss';

const rules = [
  {
    description: 'Between 1-15 characters',
    isValid: value => value?.length >= 1 && value?.length <= 15,
  },
  {
    description: 'Starts with a lowercase letter',
    isValid: value => /^[a-z]/.test(value || ''),
  },
  {
    description: 'Only lowercase letters, numbers, and underscores',
    isValid: value => /^[a-z0-9_]+$/.test(value || ''),
  },
  {
    description: 'No invalid symbols or spaces',
    isValid: value => /^[a-z0-9_]+$/.test(value || ''),
  },
];

const evaluateRules = value => {
  const results = [];
  rules.forEach(({ description, isValid }) => {
    results.push({
      description,
      valid: isValid(value),
    });
  });
  return results;
};

const FriendlyIdInput = props => {
  const { inputClassName, value, title, description, onKeyUp } = props;

  const [ruleResults, setRuleResults] = React.useState([]);

  React.useEffect(() => {
    setRuleResults(evaluateRules(value));
  }, [value]);

  return (
    <>
      <Divider />
      <DialogSectionTitle id="dialog-friendlyid-title">
        {title}
      </DialogSectionTitle>
      <span id="dialog-friendlyid-desc">
        <DialogSubSectionText>{description}</DialogSubSectionText>
        <DialogContentText component="span" style={{ display: 'block' }}>
          <ul className={style.rules}>
            {ruleResults.map(item => {
              const itemClass = item.valid ? style.valid : style.invalid;
              return (
                <li
                  key={item.description}
                  className={`${style.ruleItem} ${itemClass}`}
                >
                  {item.valid ? <DoneIcon className={style.valid} /> : <CloseIcon />}
                  {item.description}
                </li>
              );
            })}
          </ul>
        </DialogContentText>
      </span>
      <div className={inputClassName}>
        <BaselineTextInput
          arialabelledby="dialog-friendlyid-title"
          ariadescribedby="dialog-friendlyid-desc"
          name="friendlyId"
          errorlabel="Unique Content Identifier"
          margin="none"
          size="medium"
          value={value}
          isRequiredErrorMessage={false}
          onKeyUp={onKeyUp}
          inputProps={{
            maxLength: 15,
          }}
        />
      </div>
    </>
  );
};

FriendlyIdInput.propTypes = {
  inputClassName: PropTypes.string.isRequired,
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FriendlyIdInput;
