import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip, ClickAwayListener } from '@mui/material';
import { InfoIcon } from 'components/icons/icons';

import style from './tooltip.module.scss';

const ToolTip = ({ title, buttonSize, ...props }) => {
  const [open, setOpen] = React.useState(false);

  let btnSize = buttonSize === 'medium' ? 'medium' : 'small';
  return (
    <ClickAwayListener onClickAway={()=>{setOpen(false)}}>
      <Tooltip
        title={title}
        disableFocusListener
        disableTouchListener  
        disableHoverListener
        open={open}
        PopperProps={{
          className: `${style.subscriptionTooltip} MuiTooltip-popper`,
        }}
        {...props}
      >
        <IconButton
          className={style.iconButton}
          variant="contained"
          color="primary"
          size={btnSize}
          onClick={()=>{setOpen(!open)}}
          aria-label={open ? title : "explanation of Salesforce accounts"}
        >
          <InfoIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

ToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  buttonSize: PropTypes.string,
};

export default ToolTip;
