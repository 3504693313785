import React from 'react';
import { Field } from 'formik';
import { Checkbox } from '@mui/material';
import style from './userPermissionCheckbox.module.scss';

const UserPermissionCheckbox = ({ name, text }) => {

  React.useEffect(()=>{
    document.getElementsByName(name)[0].removeAttribute('required'); 
  })


  return (
    <section className={style.checkboxContainer}>                
    <Field name={name}>
      {({ field }) => (
        <Checkbox 
          {...field}
          required={false}
          className={style.checkbox}
          checked={field.value}
          color="primary"
        />
      )}  
    </Field> 
    <p>{text}</p>
  </section>
  )
}

export default UserPermissionCheckbox