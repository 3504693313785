import { SUBSCRIPTIONS } from 'utils/configuration/subscriptions';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const credentialUrl = baseUrl + 'v1/credential';

export async function getCompassCredential(
  subscriptionId,
  organizationId,
  accessToken,
) {
  return await utils.getResource(
    `${credentialUrl}/organization/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}/compass`,
    accessToken,
  );
}

export async function updateCredentialStatus(
  credentialDto,
  accessToken,
  etag = '',
) {
  return await utils.putResource(
    `${credentialUrl}/status`,
    credentialDto,
    accessToken,
    etag,
  );
}
