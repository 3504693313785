import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  Container,
  Chip,
} from '@mui/material';
import {
  RedOutlinedButtonLink,
  GreenOutlinedButtonLink,
  ExpandButton,
} from 'components/buttons/button';

import style from './delegatedUserCard.module.scss';

const DelegatedUserCard = ({
  delegate,
  returnAllCounts,
  handleModalOpen,
  path,
}) => {
  const [expand, setExpand] = useState(false);
  let history = useHistory();

  const totalCounts = returnAllCounts(delegate.subscriptions);
  const { subs, apps, roles } = totalCounts;

  const handleClick = () => {
    setExpand(!expand);
  };

  let expanded = expand ? style.cardHeaderExpanded : '';
  let ariaLabel = expand
    ? `Collapse permissions information for ${delegate.fullName}`
    : `Expand permissions information for ${delegate.fullName}`;

  const openEditAccess = e => history.push({ pathname: `${path}/${delegate.userId}`, search: window.location.search });
  const openRevokeAccessModal = e => handleModalOpen(delegate);
  const subText = subs === 1 ? 'subscription' : 'subscriptions';
  const appText = apps === 1 ? 'application' : 'applications';
  const roleText = roles === 1 ? 'role' : 'roles';

  return (
    <>
      <Card
        role="region"
        aria-labelledby={`region-heading${delegate.userId}`}
        className={style.userCard}
      >
        <div className={`${style.cardHeaderContainer} ${expanded}`}>
          <CardActions className={style.cardActions}>
            <ExpandButton
              expand={+expand}
              onClick={handleClick}
              aria-label={ariaLabel}
            />
          </CardActions>
          <div>
            <h2
              id={`region-heading${delegate.userId}`}
              className={style.cardHeader}
            >
              {delegate.fullName}
            </h2>
            <div
              className={style.cardSubHeader}
            >{`${subs} ${subText}, ${apps} ${appText}, ${roles} ${roleText}`}</div>
          </div>
          <div className={style.btnContainer}>
            <div className={style.btnWrapper}>
              <GreenOutlinedButtonLink
                handleClick={openEditAccess}
                buttonText={`Edit Access`}
                ariaLabel={`Edit delegated access for ${delegate.fullName}`}
              />
            </div>
            <RedOutlinedButtonLink
              handleClick={openRevokeAccessModal}
              buttonText={`Revoke Access`}
              ariaLabel={`Revoke delegated access for ${delegate.fullName}`}
            />
          </div>
        </div>
        <Collapse in={expand}>
          <CardContent>
            <Container className={style.cardContentContainer}>
              <Grid container>
                <Grid container className={style.cardHeaders}>
                  <Grid item xs={4}>
                    Subscription
                  </Grid>
                  <Grid item xs={4}>
                    Application
                  </Grid>
                  <Grid item xs={4}>
                    Application Roles
                  </Grid>
                </Grid>
                {delegate.subscriptions.map((sub, i) => {
                  let subName = sub.name;
                  let notFirst = i > 0 ? style.notFirst : '';
                  return (
                    sub.applications.length &&
                    sub.applications.map((app, i) => {
                      return (
                        app.roles.length && (
                          <Grid
                            container
                            direction="row"
                            alignItems="baseline"
                            className={`${notFirst} ${style.cardSubSection}`}
                            key={`${delegate.userId}::${app.id}::${sub.id}`}
                          >
                            <Grid item xs={4}>
                              {subName}
                            </Grid>
                            <Grid item xs={4}>
                              {app.name}
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              className={style.chipContainer}
                              alignItems="baseline"
                              item
                              xs={4}
                            >
                              {app.roles.map((role, i) => {
                                return (
                                  <Chip
                                    key={i}
                                    className={style.roleChip}
                                    label={role.name}
                                  />
                                );
                              })}
                            </Grid>
                          </Grid>
                        )
                      );
                    })
                  );
                })}
              </Grid>
            </Container>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default DelegatedUserCard;
