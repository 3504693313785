import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/dialog/dialog';
import { BaselineTextField } from 'components/textInputV2/textInput';
import { GeneralCancelButton, RedButton } from 'components/buttons/button';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { Typography } from 'components/typography/typography';
import { useAddFocus } from 'utils/hooks/useAddFocus';

import style from './verifyModal.module.scss';

const VerifyModal = props => {
  const {
    confirmText,
    handleCancel,
    handleClose,
    handleConfirm,
    open,
    text,
    title,
    warningText,
    hideConfirmInstructions,
    alarmStyle = false,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [confirmInput, setConfirmInput] = useState('');
  const [resetBtnDisabled, setResetBtnDisabled] = useState(true);

  const inputRef = useRef(null);
  useAddFocus(inputRef, true);

  const checkIsValid = evt => {
    setConfirmInput(evt.target.value);
    setResetBtnDisabled(true);
  };

  const internalConfirmationHandler = async eventHandler => {
    setSubmitting(true);
    await eventHandler();
  };

  useEffect(() => {
    if (confirmInput.toLowerCase() === 'confirm') setResetBtnDisabled(false);
    if (confirmInput.toLowerCase() !== 'confirm') setResetBtnDisabled(true);
  }, [confirmInput]);

  return (
    <>
      <Dialog
        role="dialog"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="dialog-title"
        aria-describedby="dialog-warning dialog-description"
      >
        {alarmStyle && <div className={style.alarm}></div>}

        <DialogTitle id="dialog-title" className={style.title}>
          {title}{' '}
        </DialogTitle>

        {warningText && (
          <div className={style.warningBanner}>
            <Typography
              className={style.warningTextTypography}
              id="dialog-warning"
            >
              {warningText}
            </Typography>
          </div>
        )}
        <DialogContent>
          <DialogContentText
            className={style.dialogContentText}
            id="dialog-description"
          >
            {text} {!hideConfirmInstructions && "Type 'confirm' below."}
          </DialogContentText>

          <BaselineTextField
            id="name"
            label=""
            type="string"
            externalLabel="Confirm"
            fullWidth
            onChange={checkIsValid}
            className={style.textField}
            inputRef={inputRef}
          />
        </DialogContent>

        <DialogActions className={style.rightAlignedBtnGroup}>
          <GeneralCancelButton
            onClick={handleCancel}
            disableRipple
          ></GeneralCancelButton>
          {submitting ? (
            <ProgressIndicator />
          ) : (
            <RedButton
              disabled={resetBtnDisabled}
              handleClick={() => {
                internalConfirmationHandler(handleConfirm);
              }}
              buttonText={confirmText}
              disableRipple
              className={style.redBtn}
            >
              {confirmText}
            </RedButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VerifyModal;

VerifyModal.propTypes = {
  confirmText: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  open: PropTypes.bool.isRequired,
  warningText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hideConfirmInstructions: PropTypes.bool,
};

VerifyModal.defaultProps = {
  hideConfirmInstructions: false,
};
