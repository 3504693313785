import React, { useState, useEffect, useRef } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import MaterialTable from '@material-table/core';
import { SectionHeader } from 'components/sectionHeader/sectionHeader';
import OrganizationAdministratorResendInvitation from 'containers/organizationManagement/editOrganization/organizationAdministratorResendInvitation/organizationAdministratorResendInvitation';
import ResetPasswordWorkflow from 'components/passwordWorkflows/resetPasswordWorkflow';
import OrganizationAdministratorEditDates from 'containers/organizationManagement/editOrganizationAdministrator/organizationAdministratorEditDates';
import OrganizationAdministratorActivate from 'containers/organizationManagement/editOrganizationAdministrator/organizationAdministratorActivate/organizationAdministratorActivate';
import CreateOrganizationAdministrator from 'containers/organizationManagement/createOrganizationAdministrator';
import {
  ResetIcon,
  SendIcon,
  ActivateIcon,
  EditDatesIcon,
} from 'components/icons/icons';
import StatusChip from 'components/users/statusChip/statusChip';
import { TableSkeleton } from 'components/skeletons/skeletons';
import Padding from 'components/padding/padding';
import style from './organizationAdministratorPanel.module.scss';
import useSetTableCaption from 'utils/hooks/common/useSetTableCaption';
import { ORG_ADMIN_TABLE_ID } from 'utils/dictionary/overview';
import { getStatusName } from 'utils/statusNames';
import StyledTableCell from 'components/table/tableCell/styledTableCell';

/**
 * Render the Organization Administrator's table with or without action buttons.
 * Note: if `readOnly` is `false`, `organizationToEdit`,
 * `renderOrganizationAdministratorResendInvitation`, and
 * `renderRemoveOrganizationAdministrator` _must_ be defined. Those fields are
 * optional if `readOnly` is `true`.
 */
const AdministratorsTable = props => {
  const { readOnly, administratorsData, organizationToEdit } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [administrators, setAdministrators] = useState([]);

  useEffect(() => {
    //table row needs id to mount the menu correctly
    administratorsData.forEach(element => {
      element.id = element.userId;
    });
    setAdministrators(administratorsData);
  }, [administratorsData]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isResendVisible = row => {
    return ['Invite Sent', 'Resend Invite'].includes(
      getStatusName(row.status, row.startDate),
    );
  };

  const resendRef = useRef();
  const resetRef = useRef();
  const editDatesRef = useRef();
  const activateRef = useRef();

  const menuAndModals = (
    <>
      <Menu
        className={style.menu}
        id="action-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {currentRow && isResendVisible(currentRow) && (
          <MenuItem
            className={style.menuItem}
            onClick={() => resendRef.current.handleOpen()}
          >
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary="Resend Invite" />
          </MenuItem>
        )}
        {currentRow &&
          (currentRow.status === 'STAGED' ||
            currentRow.status === 'DEPROVISIONED') && (
            <MenuItem
              className={style.menuItem}
              onClick={() => editDatesRef.current.handleOpen()}
            >
              <ListItemIcon>
                <EditDatesIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Start and End Date" />
            </MenuItem>
          )}
        {currentRow && currentRow.status === 'ACTIVE' && (
          <MenuItem
            className={style.menuItem}
            onClick={() => resetRef.current.handleResetModalOpen()}
          >
            <ListItemIcon>
              <ResetIcon />
            </ListItemIcon>
            <ListItemText primary="Reset Password" />
          </MenuItem>
        )}
        {currentRow && currentRow.status === 'SUSPENDED' && (
          <MenuItem
            className={style.menuItem}
            onClick={() => activateRef.current.handleOpen()}
          >
            <ListItemIcon>
              <ActivateIcon />
            </ListItemIcon>
            <ListItemText primary="Activate User" />
          </MenuItem>
        )}
      </Menu>
      {currentRow && (
        <>
          <OrganizationAdministratorResendInvitation
            rowData={currentRow}
            organization={organizationToEdit}
            closeMenu={handleClose}
            ref={resendRef}
          />
          <ResetPasswordWorkflow
            forOrgAdmin={true}
            userInfo={currentRow}
            organization={organizationToEdit}
            ref={resetRef}
          />
          <OrganizationAdministratorEditDates
            rowData={currentRow}
            organization={organizationToEdit}
            closeMenu={handleClose}
            ref={editDatesRef}
          />
          <OrganizationAdministratorActivate
            rowData={currentRow}
            organization={organizationToEdit}
            closeMenu={handleClose}
            ref={activateRef}
          />
        </>
      )}
    </>
  );

  // Default columns to show
  const columns = [
    {
      title: 'Last Name',
      defaultSort: 'asc',
      field: 'lastName',
      cellStyle: {
        fontWeight: '500',
      },
      render: ({ lastName }) => <StyledTableCell displayString={lastName} />,
    },
    {
      title: 'First Name',
      field: 'firstName',
      cellStyle: {
        fontWeight: '500',
      },
      render: ({ firstName }) => <StyledTableCell displayString={firstName} />,
    },
    {
      title: 'Email',
      field: 'email',
      render: ({ email }) => <StyledTableCell displayString={email} />,
    },
    {
      title: 'Status',
      field: 'status',
      width: '15%',
      render: ({ status, startDate }) => (
        <StatusChip status={status} startDate={startDate} />
      ),
    },
  ];

  //A11y - Setting caption to table
  useSetTableCaption(ORG_ADMIN_TABLE_ID);

  const actionProps = readOnly
    ? {}
    : {
        actions: [
          {
            icon: 'more_horiz',
            onClick: (event, rowData) => {
              handleClick(event, rowData);
            },
          },
        ],
      };

  return (
    <>
      <MaterialTable
        style={{ boxShadow: '0px 3px 6px #00000029' }}
        columns={columns}
        data={administrators}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5,
          toolbar: readOnly ? false : true,
          search: true,
          sorting: true,
          thirdSortClick: false,
          draggable: false,
          headerStyle: {
            backgroundColor: '#f9f9f9',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#424242',
          },
          rowStyle: {
            fontSize: '1rem',
            color: '#424242',
            fontFamily: 'Roboto',
          },
        }}
        {...actionProps}
        components={{
          Toolbar: props => (
            <div className={style.toolbar}>
              <CreateOrganizationAdministrator
                organization={organizationToEdit}
              />
            </div>
          ),
        }}
      />
      {readOnly ? <></> : menuAndModals}
    </>
  );
};

const DisplayAdministrators = props => {
  const {
    administratorsData = {},
    organizationToEdit = {},
    readOnly = false,
  } = props;

  const { administrators = [] } = administratorsData;
  const { organization = {} } = organizationToEdit;

  const description = readOnly
    ? 'Organization administrators manage users and their roles at your organization. You can assign administrators on the Manage Users page.'
    : "Organization Administrators are provisioned by Healthwise. Administrators manage users and their roles at the client's organization.";

  return (
    <>
      <SectionHeader
        title="Organization Administrators"
        secondary={description}
      />

      <AdministratorsTable
        readOnly={readOnly}
        administratorsData={administrators}
        organizationToEdit={organization}
      />
    </>
  );
};

/**
 * Render the Organization Administrator panel.
 * Note: if `readOnly` is `false`, `organizationToEdit`,
 * `renderOrganizationAdministratorResendInvitation`, and
 * `renderRemoveOrganizationAdministrator` _must_ be defined. Those fields are
 * optional if `readOnly` is `true`.
 */
export const OrganizationAdministratorPanel = props => {
  const { administratorsData = {}, organizationToEdit = {} } = props;

  const { organizationAdministratorsLoading = false } = administratorsData;
  const { organizationToEditLoading = false } = organizationToEdit;

  const Loading = () =>
    organizationToEditLoading || organizationAdministratorsLoading;

  return (
    <section>
      {Loading() ? (
        <Padding>
          <TableSkeleton />
        </Padding>
      ) : (
        <DisplayAdministrators {...props} />
      )}
    </section>
  );
};

export default OrganizationAdministratorPanel;
