import * as Yup from 'yup';
import { getMinimumEndDate } from 'containers/users/user';

export const getCustomUserValidationSchema = schemaObject => {
  return Yup.object().shape(schemaObject);
};

export const CreateOrganizationAdministratorSchema = {
  firstName: Yup.string()
    .max(40, 'Must be 40 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(40, 'Must be 40 character or less')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
};

export const CreateOrganizationAdministratorSchemaWithDates = {
  ...CreateOrganizationAdministratorSchema,
  startDate: Yup.date()
    .label('Start Date')
    .required('Start date is required and must have a value')
    .nullable()
    .default(undefined)
    .typeError('Invalid Date'),
  endDate: Yup.date()
    .label('End Date')
    .when('startDate', (startDate, schema) => {
      const startDateValue = getMinimumEndDate(startDate);
      return schema.min(
        startDateValue.date,
        `End Date must be after ${startDateValue.dateName}`,
      );
    })
    .nullable()
    .default(undefined)
    .typeError('Invalid Date'),
};

export const FormInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  startDate: '',
  endDate: '',
};

export const OrganizationAdminSchemaWithDatesDefinition = {
  startDate: Yup.date()
    .label('Start Date')
    .required('Start date is required and must have a value')
    .nullable()
    .default(undefined)
    .typeError('Invalid Date'),
  endDate: Yup.date()
    .label('End Date')
    .when('startDate', (startDate, schema) => {
      const startDateValue = getMinimumEndDate(startDate);
      return schema.min(
        startDateValue.date,
        `End Date must be after ${startDateValue.dateName}`,
      );
    })
    .nullable()
    .default(undefined)
    .typeError('Invalid Date'),
};

export const OrganizationAdministratorValidationSchemaWithDates =
  getCustomUserValidationSchema(CreateOrganizationAdministratorSchemaWithDates);

export const OrganizationAdministratorValidationSchema =
  getCustomUserValidationSchema(CreateOrganizationAdministratorSchema);
